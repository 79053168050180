<template>
  <a-button
    class="li"
    :class="{
      isSelect: continuedMt,
      disabled: disabled || (checkedDetail.locked && checkedList.length == 0),
    }"
    type="link"
    @click="mtClick"
    @dblclick="mtDbClick"
  >
    <icon-font class="font-size-16 icon-box" type="icon-jifan"></icon-font>
    <p class="li-txt">机翻</p>
  </a-button>
  <Price
    :pricevisible="visible"
    :detail="totalPay"
    @confirm-order="handleOk"
    @update-pricevisible="handleCancel"
    ref="priceRef"
  ></Price>
  <a-modal
    :visible="true"
    title="提示"
    okText="确认机翻"
    cancelText="取消"
    centered
    @ok="confirmOk"
    @cancel="confirmCancel"
    v-if="confirmVisible"
  >
    <div class="modal">
      <div class="title">
        <img src="./images/mt.png" />
      </div>
      <div class="content">
        已勾选
        <span style="color:#00B4FA">{{ checkedList?.length }}</span>
        个句段，确认批量机翻？
      </div>
      <div class="footer">
        根据已选机翻引擎进行翻译，已有译文会被替换。
      </div>
      <!--保存快照模块，本地存储-->
      <saveSnapshotCheck
        v-model:saveSnapshotValue="saveSnapshotValue"
      ></saveSnapshotCheck>
    </div>
  </a-modal>
</template>

<script>
import {computed, reactive, ref, toRefs} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {message, Modal} from 'ant-design-vue'
import {getTwinslatorMtPrice} from '@/http/api'
import Price from '../../Price.vue'
import mitt from '@/plugins/bus'
import saveSnapshotCheck from '@/components/saveSnapshotCheck/index.vue'

export default {
    name: 'index.vue',
    emits: ['opentranslationset'],
    props: ['disabled'],
    components: {
      Price,
      saveSnapshotCheck,
    },
    setup(props, ctx) {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail = computed(() => store.state.taskDetail)
      const totalPay = ref({ isEnough: false })
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })

      const continuedMt = computed({
        get() {
          return store.state.continuedMt && store.state.modal.autoPayChecked
        },
      })

      const checkedList = computed(() => store.state.excelProject.checkedList)
      const state = reactive({
        confirmVisible: false, //确认弹窗
        visible: false, //机翻价格弹窗 如果设置关闭 则不会弹出,
        saveSnapshotValue: false,
      })

      const initCheckedList = () => {
        store.commit('excelProject/changeKey', {
          checkedAll: false,
          checkedList: [],
        })
      }

      let clickTimer = ''

      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex]
      }

      //获取机翻操作 model 为批量操作喝单句操作
      const httpGetMt = () => {
        const item = getTableActiveItem()
        //项目任务
        let model = 'single'
        let sentIds = item.sentId.toString()
        if (checkedList.value.length > 0) {
          model = 'batch'
          sentIds = checkedList.value.map((citem) => {
            return citem.sentId.toString()
          })
          sentIds = sentIds.toString()
        }

        store.commit('changeKey', {
          screenLoading: true,
        })

        //获取存储快照的变量
        let saveSnapshot = false
        if (model == 'batch') {
          saveSnapshot = sessionStorage.getItem('saveSnapshotValue') || false
        }

        //还需要存储保存快照模块的参数 根据type 为 batch 来判断是否为批量
        store.dispatch('twinslatorMt', {
            sentIds: sentIds,
            saveSnapshot: saveSnapshot,
          }).then((res) => {
            // 如果是获取机翻，需要再次请求fullmatch接口，用于更新替换数据
            initCheckedList()
            if (saveSnapshot) {
              //更改快照信息
              store.commit('optionsBar/changeKey', {
                allowSaveSnapshot: true,
              })
            }
          })
          .finally(() => {
            store.commit('changeKey', {
              screenLoading: false,
            })
          })
      }

      const confirmMt = () => {
        if (!store.state.modal.autoPayChecked) {
          let sentIds
          if (checkedList.value.length > 0) {
            sentIds = checkedList.value.map((citem) => {
              return citem.sentId
            })
            sentIds = sentIds.toString()
          } else {
            if (!checkedDetail.value.sentId) {
              message.warning('请选中某一句或者勾选句子')
              return
            }
            const item = getTableActiveItem()
            sentIds = item.sentId
            //获取当前激活项item
          }
          getTwinslatorMtPrice({
            docId: store.state.taskDetail.docId,
            sentIds: sentIds,
          }).then((res) => {
            if (res.data.result == 1) {
              // console.log(res.data.data)
              totalPay.value = { ...res.data.data }
              state.visible = true
            }
          })
          return
        }
        httpGetMt()
      }

      const mtClick = (e) => {
        // console.log(e)
        let focusedElement = document.activeElement
        if (focusedElement) {
          focusedElement.blur()
        }
        if (!store.state.taskDetail.newTwinslatorId || store.state.taskDetail.newTwinslatorId<0) {
          //展示弹窗
          Modal.confirm({
            centered: true,
            title: '提示',
            content: '请先在翻译设置中设置引擎，是否前往设置。',
            okText: '前往设置',
            cancelText: '取消',
            onOk: () => {
              ctx.emit('opentranslationset', 2)
            },
          })
          return
        }
        clearTimeout(clickTimer)
        clickTimer = setTimeout(() => {
          if (store.state.continuedMt) {
            return
          }
          //批量需要弹窗

          if (checkedList.value.length > 0) {
            state.confirmVisible = true
          } else {
            confirmMt()
          }
        }, 200)
      }
      const mtDbClick = () => {
        if (!store.state.taskDetail.newTwinslatorId) {
          return
        }
        clearTimeout(clickTimer)

        //判断是否勾选上自动扣费 直接弹出弹窗 且 自动机翻没有被选中
        if (!store.state.modal.autoPayChecked && !store.state.continuedMt) {
          //展示弹窗
          Modal.confirm({
            centered: true,
            title: '温馨提示',
            content: '您尚未开启孪生译员自动扣费功能，不可连续机翻，请前往设置',
            okText: '前往设置',
            cancelText: '返回',
            onOk: () => {
              mitt.emit('openAutoPaySetting', () => {
                store.commit('changeKey', {
                  continuedMt: true,
                })
              })
            },
          })
        } else {
          store.commit('changeKey', {
            continuedMt: !store.state.continuedMt,
          })
        }
      }
      const handleCancel = () => {
        state.visible = false
      }

      const handleOk = () => {
        if (totalPay.value.isEnough) {
          httpGetMt()
          state.visible = false
        } else {
          //需要判断是什么人 给予不同的弹窗权限。
          store.commit('changeKey', {
            rechargeVisible: true,
            totalPay: { ...totalPay.value },
          })
          state.visible = false
        }
      }

      const confirmOk = () => {
        state.confirmVisible = false
        confirmMt()
      }
      const confirmCancel = () => {
        state.confirmVisible = false
      }

      return {
        continuedMt,
        confirmOk,
        confirmCancel,
        ...toRefs(state),
        store,
        mtClick,
        handleCancel,
        handleOk,
        mtDbClick,
        totalPay,
        checkedList,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
</script>

<style scoped lang="less">
  .modal {
    text-align: center;
    .title {
      img {
        width: 56px;
      }
    }
    .content {
      color: #1c2129;
      line-height: 22px;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    .footer {
      color: #4e5969;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }
</style>
