<template>
  <a-config-provider :autoInsertSpaceInButton="false" :locale="locale">
    <a-spin
      :spinning="store.state.screenLoading"
      wrapperClassName="app-screenLoading"
    >
      <!--计价弹窗-->
      <GPTPrice></GPTPrice>
      <!--这是引擎评分-->
      <enginegrade v-if="store.state.engineGradeVisible"></enginegrade>
      <!--这是议员评分-->
      <interpreterEvaluate
        v-if="store.state.interpreterEvaluateVisible"
      ></interpreterEvaluate>
      <router-view />
      <!--充值全局弹窗-->
      <Recharge v-if="store.state.rechargeVisible"></Recharge>
      <!--术语重译全局弹窗-->
      <TerminologyRetranslation
        v-if="termVisible"
        :termVisible="termVisible"
        @termvisiblechange="termvisiblechange"
      ></TerminologyRetranslation>
      <!--查找替换全局弹窗-->
      <replaceDialog
        v-if="store.state.modal.replaceDialogData.visible"
      ></replaceDialog>
      <!--批量润色全局弹窗-->
      <batchDecoration
        :batchDecorationData="batchDecorationData"
        v-if="batchDecorationData.totalNumber"
        @closeModal="closeModal"
      ></batchDecoration>
      <!--token失效全局弹窗-->
      <TokenErr v-if="store.state.modal.tokenErrdialogVisible"></TokenErr>
      <!--占位符提示全局弹窗-->
      <PlaceholderDialog
        v-if="store.state.modal.placeHolderVisible"
      ></PlaceholderDialog>
      <!--锁定解锁弹窗-->
      <LockDialog v-if="store.state.modal.lockDialogVisible"></LockDialog>

      <!--重刷新token-->
      <refreshErr v-if="store.state.modal.refreshErrdialogVisible"></refreshErr>

      <check-modal v-if="store.state.modal.qaCheckVisible"></check-modal>

      <balanceNoEnough
        v-if="store.state.modal.balanceNoEnoughVisible"
      ></balanceNoEnough>

      <PMLimit v-if="store.state.modal.PMLimitVisible"></PMLimit>

      <saveSnapShotDialog
        v-if="store.state.modal.saveSnapshotVisible"
      ></saveSnapShotDialog>


      <!--二次预处理强提示-->
<!--      <preprocessingMessage></preprocessingMessage>-->

      <syncLockSentence></syncLockSentence>

      <notification></notification>
      <!--//qa分析进度-->
      <QAprogress></QAprogress>
    </a-spin>
    <!--//需保留 ，有其他位置需要此赋值HTML ，获取HTML-->
    <div
      id="create-html-test-node"
      style="display: none;position: fixed;top: 0;z-index: -1;left:0;"
    ></div>
    <!--//需保留 ，有其他位置需要此赋值HTML ，获取HTML-->
    <div
        id="create-html-save-node"
        style="display: none;position: fixed;top: 0;z-index: -2;left:0;"
    ></div>
  </a-config-provider>
</template>

<script>
import {useStore} from 'vuex'
import {computed, createVNode, defineComponent, nextTick, onMounted, ref,} from 'vue'
import batchDecoration from '@/components/dialog/batchDecoration/index.vue'
import enginegrade from '@/components/dialog/enginegrade/enginegrade.vue'
import interpreterEvaluate from '@/components/dialog/enginegrade/interpreterEvaluate.vue'
import refreshErr from '@/components/dialog/refreshErr/refreshErr.vue'
import TokenErr from '@/components/dialog/tokenErr/tokenErr.vue'
import {getQueryString} from '@/plugins/prototype'
import Recharge from '@/components/dialog/Recharge/Recharge.vue'
import TerminologyRetranslation from '@/components/dialog/TerminologyRetranslation/index.vue'
import replaceDialog from '@/components/dialog/replaceDialog/index.vue'
import PlaceholderDialog from '@/components/dialog/Placeholder/index.vue'
import saveSnapShotDialog from '@/components/dialog/saveSnapShotDialog/index.vue'
import syncLockSentence from '@/components/dialog/syncLockSentence/index.vue'
import preprocessingMessage from "@/components/$Message/preprocessingMessage.vue"
import balanceNoEnough from '@/components/dialog/balanceNoEnough/NoEnough.vue'
import PMLimit from '@/components/dialog/balanceNoEnough/PMlimit.vue'
import LockDialog from '@/components/dialog/Lock/index.vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import notification from "@/components/$Notification/index.vue"
import QAprogress from "@/components/dialog/qaProgress/progress.vue";
import {message} from "ant-design-vue";
import {checkReplaceProgress, postTermRetranslateRunning, sentCommit,} from '@/http/api'
import GPTPrice from '@/components/dialog/GPTPrice/GPTPrice.vue'

import updateCompareFunc from '@/plugins/updateCompare.js'

import checkModal from '@/components/dialog/checkQA/check.vue'
import filterStrings from '@/plugins/filterString'
import {isFocusPositionSource} from "@/plugins/comm";

export default defineComponent({
    name: 'app',
    components: {
      notification,
      preprocessingMessage,
      saveSnapShotDialog,
      syncLockSentence,
      PMLimit,
      balanceNoEnough,
      checkModal,
      refreshErr,
      LockDialog,
      batchDecoration,
      PlaceholderDialog,
      GPTPrice,
      replaceDialog,
      TerminologyRetranslation,
      Recharge,
      enginegrade,
      TokenErr,
      interpreterEvaluate,
      QAprogress,
    },
    setup() {
      const store = useStore()
      const termVisible = ref({
        visible: false,
        termData: {},
      })

      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })
      const batchDecorationData = ref({})

      const closeModal = () => {
        store.dispatch('getSentence', { loading: true })
        batchDecorationData.value = {}
      }
      const termvisiblechange = (type) => {
        termVisible.value.termData = {}
        termVisible.value.visible = type
      }

      // 本地浏览器网页内容是否更新
      updateCompareFunc()

      //检查是否有进程
      const checkProgress = () => {
        // this.visible = true;
        //调用接口 progress 进度 如果有打开弹窗
        postTermRetranslateRunning()
          .then((res) => {
            if (res.data.taskType) {
              termvisiblechange(true)
            }
          })
          .catch((rej) => {})
      }

      const httpCheckReplaceProgress = () => {
        checkReplaceProgress().then((res) => {
          let result = res.data.data
          if (res.data.data.progress != 100) {
            store.commit('modal/changeKey', {
              replaceDialogData: {
                visible: true,
                progress: result.progress,
                total: result.total,
                complete: result.complete,
              },
            })
          }
        })
      }

      onMounted(() => {
        window.addEventListener('beforeunload', function(event) {
          //预览模式屏蔽
          if (store.getters.$isPreviewMode) {
            return
          }
          //原文编辑模式屏蔽
          if (store.state.openEditSourceText) {
            return
          }
          let targetDom = document.getElementById(
            checkedDetail.value.targetDomId
          )
          let sentId = checkedDetail.value.sentId
          if (targetDom) {
            let sentItem =
              store.state.excelProject.docData.sentenceDTOList[
                store.state.excelProject.ArrMap.get(sentId)
              ]
            if (targetDom.innerHTML === sentItem.compareTargetHTML) {
              return
            }
            let target = filterStrings.getPureString(targetDom.innerHTML)
            let targetPlaceholder = filterStrings.getString(targetDom.innerHTML)
            sentCommit({
              docId: checkedDetail.value.docId,
              sentId: checkedDetail.value.sentId,
              target: target,
              targetPlaceholder: targetPlaceholder,
              confirm: false,
            }).then((res) => {
              if (!res) {
                return
              }
              //记录
              //重新赋值；
              let result = res.data.data
              store.dispatch('updateTargetOrStatus', {
                type: 'commit',
                sentId: sentId,
                targetHTML: targetPlaceholder,
                assignObjItem: {
                  diffCompare: result.diffCompare,
                  isConfirm: result.isConfirm,
                  targetText: result.targetText,
                  targetType: result.targetType,
                },
              })
            })
            event.preventDefault() // 阻止浏览器关闭或刷新页面
          }
          // 在此处添加需要执行的代码
        })
        document.addEventListener('copy', function(event) {
          // 获取选中的内容
          // const selectionObj = window.getSelection();
          // const selectionText = selectionObj.toString();
          // if (selectionObj.type == 'Range') {
          //   console.log(selectionText)
          //   let rangeObj = selectionObj.getRangeAt(0)
          //   let docFragment = rangeObj.cloneContents()
          //   event.preventDefault()
          //   let textContent = docFragment.textContent
          //   //过滤尖括号
          //   textContent = textContent.replaceAll('<', '&lt;')
          //   textContent = textContent.replaceAll('>', '&gt;')
          //   let clipboardData = event.clipboardData || window.clipboardData
          //   clipboardData.setData('text/plain', textContent)
          // }
        })
        document.addEventListener('paste', (event) => {
          // 阻止默认粘贴行为
          event.stopPropagation();
          event.preventDefault();

          let selection = window.getSelection();
          //如果是选中 需要判断DOM节点是否属于原文
          if (selection.type=='Range' && isFocusPositionSource()) {
            let rangeObj = selection.getRangeAt(0)
            let docFragment = rangeObj.cloneContents();
            // console.dir(docFragment)
            let isHasImg =false;
            for (let i = 0;i<docFragment.children.length;i++) {
              if (docFragment.children[i].nodeName == 'IMG') {
                isHasImg = true
                message.warning('粘贴替换的内容含有占位符，此行为被禁止')
                break
              }
            }
            if (isHasImg) {
              return
            }
          }

          // event.preventDefault() // 阻止默认粘贴行为
          let clipboardData = event.clipboardData || window.clipboardData
          let pastedData = clipboardData.getData('text');
          pastedData = filterStrings.filterEscapeCharacter(pastedData);
          document.execCommand('insertText', false, pastedData);
        })

        document.addEventListener('cut', (event) => {
          let selection = window.getSelection();
          if (store.state.openEditSourceText) {
            if (selection.type=='Range' && isFocusPositionSource()) {
              let rangeObj = selection.getRangeAt(0)
              let docFragment = rangeObj.cloneContents();
              // console.dir(docFragment)
              let isHasImg =false;
              for (let i = 0;i<docFragment.children.length;i++) {
                if (docFragment.children[i].nodeName == 'IMG') {
                  isHasImg = true
                  message.warning('剪切的内容含有占位符，此行为被禁止')
                  break
                }
              }
              if (isHasImg) {
                event.preventDefault()
                return
              }
            }
          } else {
            if (selection.type=='Range' && isFocusPositionSource()) {
              event.preventDefault() // 阻止默认粘贴行为
            }
          }

        })

        checkProgress()
        // 初始化检查 查找替换的过程若一开始为100 则不需要打开
        httpCheckReplaceProgress()

        const eventSource = new EventSource(
          `/api/v1/stream/events?ck=${getQueryString('ck')}`,
          { withCredentials: true }
        )

        window.eventSource = eventSource;

        //QA检查
        eventSource.addEventListener('QA_CHECK_REFRESH', function(e) {
          let result = JSON.parse(e.data)
          if (result.taskId == store.state.taskDetail.taskId) {
            //处于差异对比情况下停止
            if (store.state.excelProject.differentHighLightModel) {
              return
            }
            // store.commit("excelProject/eventSourceQATip", result);
            // 调用接口 判断译文Hash 是否相同 等推送回来的数据 均需要比较
            let qaIds = JSON.parse(result.ids).map((item) => {
              return item
            })

            //要筛选出来当前页存在的qaIds;
            let sentList = [];
            qaIds.forEach((item) => {
              if (store.state.excelProject.ArrMap.has(item)) {
                store.dispatch('getAllSentenceLogs')
                sentList.push(item)
              }
            });
            //sentList 由sentId组成的；

            //推信息，是只有位置和QAId的 ，就不需要调取获取译文的接口了；

            //如果只有一句 做防抖处理 新的做法按节流去做；
            if (sentList?.length == 1) {
              let sentItem =
                store.state.excelProject.docData.sentenceDTOList[
                  store.state.excelProject.ArrMap.get(sentList[0])
                ]
              if (sentItem.qaTimer) {
                clearTimeout(sentItem.qaTimer)
              }
              sentItem.qaTimer = setTimeout(() => {
                store.dispatch('getQAHTMLResult', {
                  sentList: [...sentList],
                  compareTarget: true,
                })
              }, 400)
            } else {
              store.dispatch('getQAHTMLResult', {
                sentList: [...sentList],
                compareTarget: true,
              })
            }
          }
        })

        eventSource.addEventListener('QA_CHECK_POSITION_REFRESH', function(e) {
          let result = JSON.parse(e.data)
          result.checkRuleList = result.checkRuleList
            ? JSON.parse(result.checkRuleList)
            : []
          result.data = JSON.parse(result.data)
          if (result.taskId == store.state.taskDetail.taskId) {
            //处于差异对比情况下停止
            if (store.state.excelProject.differentHighLightModel) {
              return
            }

            let sentList = []
            for (let key in result.data) {
              if (store.state.excelProject.ArrMap.has(key)) {
                let allowLine = [
                  'SPELL_CHECK',
                  'RULE_LOWE_CHECK',
                  'SYNTAX_CHECK',
                ]
                // if (result.checkRuleList.includes(''))
                let isHasLine = allowLine.some((item1) =>
                  result.checkRuleList.includes(item1)
                )
                if (isHasLine) {
                  sentList.push(key)
                }
              }
            }

            if (sentList?.length == 1) {
              let sentItem =
                store.state.excelProject.docData.sentenceDTOList[
                  store.state.excelProject.ArrMap.get(sentList[0])
                ]
              if (sentItem.qaTimer) {
                clearTimeout(sentItem.qaTimer)
              }
              sentItem.qaTimer = setTimeout(() => {
                store.dispatch('getQAHTMLResult', {
                  sentList: [...sentList],
                  compareTarget: true,
                })
              }, 500)
            } else {
              store.dispatch('getQAHTMLResult', {
                sentList: [...sentList],
                compareTarget: true,
              })
            }
            store.dispatch('getAllSentenceLogs')
          }
        })

        //重复句推送
        eventSource.addEventListener('REPEAT_SENTENCE_REFRESH', function(e) {
          let result = JSON.parse(e.data)
          let resultData = JSON.parse(result.data)
          // if (resultData.sentence.docId == store.state.taskDetail.docId) {
          store.commit('excelProject/repeatSentenceAdd', resultData)
          // }
          // if (result.taskId == store.state.taskDetail.taskId) {

          // }
        })

        //通知用户重新进入webcat
        eventSource.addEventListener('PUSH_SYSTEM_NOTIFY', function(e) {
          store.commit('modal/changeKey', {
            refreshErrdialogVisible: true,
          })
        })

        //智能体推送
        eventSource.addEventListener('agent_refresh_task_progress', function(e) {
          console.log(e)
          let result = JSON.parse(e.data);
          console.log(result)
          if (result.taskId == store.state.taskDetail.taskId) {
            if (result.status == 'FAIL') {
              store.commit("modal/changeKey",{
                intelligentAgentDialog:{}
              })
              message.error(result.errorMsg);
            } else {
              store.commit("modal/changeKey",{
                intelligentAgentDialog:result
              })
            }
          }
        })


        //查找替换
        eventSource.addEventListener('REPLACE_TRANSLATION', function(e) {
          let result = JSON.parse(e.data)
          if (result.taskId == store.state.taskDetail.taskId) {
            //先关闭一次弹窗
            store.commit('modal/changeKey', {
              replaceDialogData: {
                visible: false,
                progress: 0,
                total: 0,
                complete: 0,
              },
            })
            //收到推送直接打开新弹窗
            nextTick(() => {
              store.commit('modal/changeKey', {
                replaceDialogData: {
                  visible: true,
                  progress: 0,
                  total: 0,
                  complete: 0,
                },
              })
            })
          }
        })

        // 翻译字数
        eventSource.addEventListener('TASK_PROGRESS_REFRESH', function(e) {
          let result = JSON.parse(e.data)
          if (result.taskId == store.state.taskDetail.taskId) {
            if (!store.state.modal.replaceDialogData.visible) {
              store.dispatch('getTranslateWordCount')
            }
          }
        })

        //批量润色 or 术语重译
        eventSource.addEventListener('BLOCKING_TASK_STATUS', function(e) {
          let result = JSON.parse(e.data)
          if (result.taskId == store.state.taskDetail.taskId) {
            // result.taskData = JSON.parse(result.taskData);
            result.taskData = JSON.parse(result.taskData)
            //批量润色
            if (result.taskType == 'BatchAiDecorate') {
              batchDecorationData.value = { ...result.taskData }
            }
            //术语重译
            else if (result.taskType == 'TermRetranslate') {
              termVisible.value.visible = true
              termVisible.value.termData = { ...result.taskData }
            } else if (result.taskType == 'SentenceTermExtract') {
            }
          }
        })

        eventSource.addEventListener('HTML_PREVIEW_REFRESH', function(e) {
          let result = JSON.parse(e.data)
          if (result.docId == store.state.taskDetail.docId) {
            if (result.success) {
              store.dispatch('getTaskDetail').then((res) => {
                //走一遍重刷新下方的流程
                let oldWordPreviewTabsValue = store.state.wordPreviewTabsValue
                store.commit('changeKey', {
                  wordPreviewTabsValue: 0,
                })
                nextTick(() => {
                  setTimeout(() => {
                    store.commit('changeKey', {
                      wordPreviewTabsValue: oldWordPreviewTabsValue,
                    })
                  }, 100)
                })
              })
            }
          }
        })

        eventSource.addEventListener('RE_PREPROCESS', function(e) {
          let result = JSON.parse(e.data)
          let stringIds = store.state.taskDetail.pedocIds.map(item=>{
            return `${item}`
          })
          if (stringIds.includes(result.PEDocId)) {
            //给toast提示
            if (result.status == 'processing') {
              message.warning({content:"PM正在预翻译，未避免您的翻译成果被覆盖，请暂停！等待PM预翻译完成再继续，详细请联系PM",duration:3})
            } else {
              message.success({
                icon: createVNode('span'),
                content:createVNode(preprocessingMessage),
                duration:3
              })
            }
          }
        })

        // const obj = {"PEDocId":"22334","iolCode":"c7f3jqmi21pjrd6p7teg","status":"processing","taskType":"DocPreHandle"};
        // const obj1 = {"PEDocId":"22334","iolCode":"c7f3jqmi21pjrd6p7teg","status":"finished","taskType":"DocPreHandle"};

      })

      return {
        closeModal,
        batchDecorationData,
        termvisiblechange,
        termVisible,
        store,
        locale: zhCN,
      }
    },
  })
</script>

<style lang="less">
@import "./styles/language-text.less";
.app-screenLoading {
  .ant-spin {
    max-height: inherit !important;
  }
}
#create-html-test-node{
  word-break: break-word;
  white-space: pre-wrap;
}
</style>
