
import {computed} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'

export default {
    name: 'index.vue',
    props: ['disabled'],
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail: any = computed(() => store.state.taskDetail)
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })
      const resetStyle = () => {
        document.execCommand('removeformat', false, '')
      }
      return {
        resetStyle,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
