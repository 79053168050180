<script setup>

</script>

<template>
<div class="thead word">
  字符
</div>
</template>

<style scoped lang="less">
.word{

}
</style>