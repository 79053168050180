<template>
  <a-modal
    v-model:visible="replaceDialogVisible"
    title="替换"
    @ok="closeModal"
    :okText="replaceDialogData.progress == 100 ? '好的' : '请等待'"
    cancelText="停止替换"
    :closable="false"
    :maskClosable="false"
    centered
    :width="560"
    :okButtonProps="{
      loading: replaceDialogData.progress != 100,
      disabled: replaceDialogData.progress != 100,
    }"
  >
    <div class="replace-re">
      <!--重译完成 有句子重译-->
      <div class="result" v-if="replaceDialogData.progress == 100">
        <div><img src="./images/success.png" /></div>
        <div class="text">
          已替换完成，替换
          <span>{{ replaceDialogData.complete }}</span>
          句
        </div>
      </div>
      <!--替换正在进行中-->
      <div class="progress" v-else>
        <div class="left">
          <div class="text">检测涉及替换的句子</div>
          <div>
            <div class="number">
              {{ replaceDialogData.total || 0 }}
            </div>
            <!--            <div class="progress-number">-->
            <!--              <a-progress :percent="30" strokeColor="#00C6F3" />-->
            <!--            </div>-->
          </div>
        </div>
        <div
          class="center"
          :class="{
            center_animatemove: replaceDialogData.progress != 100,
          }"
        >
          <img src="./images/loading.png" />
        </div>
        <div class="right">
          <div class="text">已替换句子</div>
          <div>
            <div class="number">
              {{ replaceDialogData.complete }}/{{ replaceDialogData.total }}
            </div>
            <div class="progress-number">
              <a-progress
                :percent="
                  parseInt(
                    (replaceDialogData.complete / replaceDialogData.total) * 100
                  )
                "
                strokeColor="#00C6F3"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="replace-re-remind">
        提醒： 大批量替换可能需要一定时间，请耐心等待。
      </div>
    </div>
    <template #footer>
      <a-button @click="stopProgress" v-if="replaceDialogData.progress != 100">
        停止替换
      </a-button>
      <a-button
        type="primary"
        :loading="replaceDialogData.progress != 100"
        class="margin-l-12"
        @click="closeModal"
      >
        {{ replaceDialogData.progress == 100 ? '好的' : '请等待' }}
      </a-button>
    </template>
  </a-modal>

  <a-modal
    v-model:visible="stopVisible"
    @ok="confirmStop"
    @cancel="cancelStop"
    okText="确认停止"
    cancelText="返回"
    title="提示"
    centered
  >
    <div style="text-align: center">
      <div class="margin-b-20">
        <img src="@/assets/warning_1.png" width="56" />
      </div>
      <div style="font-size: 16px;color:#1c2129" class="margin-b-8">
        是否停止替换
      </div>
      <div style="font-size: 14px;color:#4e5969">
        停止后，已经替换的译文会填充到译文框，未替换的译文保持不变
      </div>
    </div>
  </a-modal>
</template>

<script>
//这是术语重译全局弹窗
import {checkReplaceProgress, postStopReplaceApi,} from '@/http/api'

export default {
    name: 'index.vue',
    data: function() {
      return {
        //停止弹窗
        stopVisible: false,
        // loading:true
      }
    },
    computed: {
      replaceDialogData: {
        get: function() {
          return this.$store.state.modal.replaceDialogData
        },
      },
      replaceDialogVisible: {
        get: function() {
          return this.$store.state.modal.replaceDialogData.visible
        },
        set: function(newValue) {
          if (newValue == false) {
            this.$store.commit('modal/changeKey', {
              replaceDialogData: {
                visible: false,
                progress: 0,
                total: 0,
                complete: 0,
              },
            })
          }
        },
      },
    },
    methods: {
      confirmStop() {
        postStopReplaceApi().then((res) => {
          //关闭 等待结果
          this.stopVisible = false
          this.closeModal()
        })
      },
      cancelStop() {
        this.stopVisible = false
      },
      stopProgress() {
        //触发二次弹窗；
        this.stopVisible = true
      },
      closeModal() {
        this.replaceDialogVisible = false
        this.$store.dispatch('getSentence')
      },
      httpGetReplace() {
        checkReplaceProgress().then((res) => {
          let result = res.data.data
          this.$store.commit('modal/changeKey', {
            replaceDialogData: {
              visible: true,
              progress: result.progress,
              total: result.total,
              complete: result.complete,
            },
          })
          if (result.progress != 100) {
            setTimeout(() => {
              this.httpGetReplace()
            }, 3000)
          }
        })
      },
    },
    created() {
      this.httpGetReplace()
    },
  }
</script>

<style scoped lang="less">
  .term-success {
    text-align: center;
    .term-success-explain {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
      height: 22px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .term-success-condition {
      height: 20px;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .tip-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 14px 0 10px 0;
    }
    .term-success-tip {
      width: 280px;
      height: 40px;
      background: rgba(0, 198, 243, 0.08);
      border-radius: 4px;
      font-size: 14px;
      color: #0cb5dc;
      line-height: 40px;
      margin-bottom: 8px;
    }
    .term-success-remind {
      color: #999999;
      font-size: 12px;
    }
  }
  .replace-re {
    .progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .text {
        height: 20px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      .number {
        height: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
        margin-top: 8px;
      }
      .progress-number {
      }
      .waiting {
        margin-top: 8px;
        height: 24px;
        font-size: 18px;
        font-weight: 400;
        color: #bbbbbb;
        line-height: 24px;
      }

      .left {
        padding: 16px 20px;
        width: 230px;
        height: 98px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #eeeeee;
      }
      .center_animatemove {
        position: relative;
        animation: mymove 1s infinite;
        @keyframes mymove {
          0% {
            left: -16px;
            opacity: 1;
          }
          25% {
            //left:-10px;
            opacity: 1;
          }
          50% {
            //left:-4px;
            opacity: 1;
          }
          75% {
            //left:4px;
            opacity: 0.6;
          }
          100% {
            left: 10px;
            opacity: 0.2;
          }
        }
        img {
          width: 40px;
        }
      }
      .center {
        //background: red;
      }
      .right {
        padding: 16px 20px;
        width: 230px;
        height: 98px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #eeeeee;
      }
    }

    .result {
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 56px;
        height: 56px;
      }
      .text {
        margin-top: 16px;
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        span {
          color: #00c6f3;
        }
      }
    }

    .replace-re-remind {
      height: 36px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
  }
</style>
