<template>
  <a-button
      class="li"
      :class="{
      disabled: props.disabled || (checkedDetail.locked && checkedList.length == 0),
    }"
      type="link"
      @click="openSelectModal"
  >
    <icon-font class="font-size-16 icon-box" type="icon-zhinengti"></icon-font>
    <p class="li-txt">智能体</p>
  </a-button>

  <a-modal
      :visible="state.visible"
      title="提示"
      okText="确认处理"
      cancelText="取消"
      centered
      @ok="confirmOk"
      @cancel="confirmCancel"
  >
    <div class="modal">
      <div class="title">
        <img src="./images/logo.png" />
      </div>
      <div class="content">
        已勾选
        <span style="color:#00B4FA">{{ checkedList?.length }}</span>
        个句段，确认批量智能体处理？
      </div>
      <div class="footer">
        若选择的智能体是预翻、审校，已有译文可能会将被替换。
      </div>
      <!--保存快照模块，本地存储-->
      <saveSnapshotCheck
          v-model:saveSnapshotValue="state.saveSnapshotValue"
      ></saveSnapshotCheck>

    </div>
  </a-modal>


  <selectModal ref="selectModalRef"></selectModal>
  <priceModal ref="priceModalRef"></priceModal>

  <pushModal></pushModal>
</template>

<script setup>
import pushModal from "./pushModal/index.vue"
import priceModal from "./price/price.vue"
import selectModal from "./selectModal/index.vue"
import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import saveSnapshotCheck from "@/components/saveSnapshotCheck/index.vue";
import {postStartByGroupApi} from "@/http/api";
import {message} from "ant-design-vue";

const store = useStore();
const selectModalRef = ref(null);
const priceModalRef = ref(null);
const props = defineProps(['disabled']);
const checkedDetail = computed(()=>{
  return store.state.checkedDetail;
})
const checkedList = computed(()=> store.state.excelProject.checkedList);


const state = reactive({
  confirmVisible: false, //确认弹窗
  visible: false, //机翻价格弹窗 如果设置关闭 则不会弹出,
  saveSnapshotValue: false,
})

const setLanguage = (id) => {
  const languageList = store.state.languageList;
  if (languageList) {
    const item = languageList.find((res) => res.languageId == id);
    if (item) {
      return item.languageName;
    } else {
      return id;
    }
  } else {
    return id;
  }
};
const confirmOk=()=>{
  confirmCancel();
  let taskDetail = store.state.taskDetail;
  let data1 = {
    language:`${setLanguage(taskDetail.sourceLangId)}-${setLanguage(taskDetail.targetLangId)}`,
  };
  let sentIds =checkedList.value.map(item=>{
    return item.sentId
  }).join(",");
  selectModalRef.value.init(data1,(costDetail)=>{
    //e为选择了某一个智能体
    // console.log(e)
    // if (!costDetail.wordCount) {
    //   message.warning("当前无字数需处理，可解锁或重新上传文件");
    //   return
    // }
    priceModalRef.value.init(costDetail,(e)=>{
      postStartByGroupApi({
        docId:store.state.taskDetail.docId,
        emkcWorkflowId: costDetail.emkcWorkflowId,
        sentIds:sentIds,
        saveSnapshot:state.saveSnapshotValue,
      })
      store.commit("excelProject/changeKey",{
        checkedList:[],
      })
    })
  })
}

const confirmCancel=()=>{
  state.visible = false;
}

const openSelectModal=()=>{
  if (checkedList.value.length) {
    state.visible = true;
  } else {
    let taskDetail = store.state.taskDetail;
    let data1 = {
      language:`${setLanguage(taskDetail.sourceLangId)}-${setLanguage(taskDetail.targetLangId)}`,
    };



    let sentId = store.state.checkedDetail.sentId;
    if (!sentId) {
      message.warning("请选择句子或者勾选句子");
      return
    }
    selectModalRef.value.init(data1,(costDetail)=>{
      if (costDetail.wordCount === 0) {
        message.warning("当前无字数需处理，可解锁或重新上传文件");
        return
      }
      priceModalRef.value.init(costDetail,(e)=>{
        postStartByGroupApi({
          docId:store.state.taskDetail.docId,
          emkcWorkflowId: costDetail.emkcWorkflowId,
          sentIds:sentId,
        })
      })
    })
  }
}


</script>

<style scoped lang="less">

.modal {
  text-align: center;
  .title {
    img {
      width: 56px;
    }
  }
  .content {
    color: #1c2129;
    line-height: 22px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  .footer {
    color: #4e5969;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
}
</style>
