import store from '@/store/index.ts';
import useUtils from "@/plugins/utils";

const { setFocusEnd } = useUtils()
export const getFactTargetText = (item, key) => {
  let nodeStr = key ? item[key] : item.targetPlaceholder
  return nodeStr || item.target
}
// https://ali-file-cdn.iol8.com/webcat/img/placeholder/L${url}.png
export const getFactSourceText = (item) => {
  let nodeStr = item.sourcePlaceholder
  return nodeStr
}


//判断某一句是否在页面内；已经不需要了 开始学习three.js
export const isPageHasSentence=(sentId)=>{
  // if (store.state.excelProject.ArrMap.has(sentId)) {
  //
  // }
}

export const positionSentence = (sentId,isFocus,isVideo) => {
  //需要判断是否处于折叠状态；根据sentId 查询当前列表数据，获取父级节点；打开折叠；
  let excelProject = store.state.excelProject;
  let index = excelProject.ArrMap.get(sentId);
  let tableItem = excelProject.docData.sentenceDTOList[index] || {};
  store.commit('changeKey', {
    checkedDetail: {
      ...tableItem,
      isVideo:isVideo || false
    },
  })
  store.commit('excelProject/changeKey', {
    tabActiveIndex: index,
  })

  //如果列表处于分组状态；
  let timeout = 0;
  //如果处于被折叠状态；
  if (store.state.collapseGroup.includes(tableItem.groupParentId)) {
    //移除
    store.dispatch("removeCollapseGroup",{groupParentId:tableItem.groupParentId});
    //延迟半秒执行；因为有动画效果；不需要
    // timeout = 400;
  }

  setTimeout(()=>{
    let childNode = window.$getTargetDom(tableItem.sentId);
    let container = document.getElementsByClassName('new-edit-table-scroll')[0]

    container.scrollTop = 0

    const { left, top, height } = container.getBoundingClientRect()
    const { left: childLeft, top: childTop } = childNode.getBoundingClientRect()

    const diffY = Math.abs(top - childTop)
    if (diffY < height / 2) {
      container.scrollTop = 0
    } else {
      container.scrollTop += diffY - height / 2
    }
    if (isFocus){
      // console.log("聚焦")
      setFocusEnd(childNode,true)
    }
  },timeout)
}


export const isFocusPositionSource=()=>{
  let selection = window.getSelection();
  let focusNode = selection.focusNode;
  if (!focusNode) {
    return false
  }
  let node = focusNode;
  try {
    while (node && (node.nodeName == '#text' || node.className?.indexOf('source-sent-') < 0)) {
      if (node.parentNode) {
        node = node.parentNode
      } else {
        node = null
      }
    }
  } catch (rej) {
    return false;
  } finally {

  }
  if (node && node.className && node.className?.indexOf('source-sent-') > -1) {
    return node
  } else {
    return false
  }
}

export const isFocusPositionTarget=()=>{
  let selection = window.getSelection();
  let focusNode = selection.focusNode;
  if (!focusNode) {
    return false
  }
  let node = focusNode;
  try {
    while (node && (node.nodeName == '#text' || node.className?.indexOf('target-sent-') < 0)) {
      if (node.parentNode) {
        node = node.parentNode
      } else {
        node = null
      }
    }
  } catch (rej) {
    return false;
  }

  if (node && node.className && node.className?.indexOf('target-sent-') > -1) {
    return node
  } else {
    return false
  }
}



export const calculateTextSecond=(textLength,startTime,endTime)=>{
  //获取长度；startTime，endTime 进行计算；
  // console.log(textLength,startTime,endTime)
  let timeDifference = endTime - startTime;
  let value = textLength / (timeDifference/1000 );
  // console.log(value)
  return Math.floor(value);
}

//srt 媒体流文件赋值句子信息；item 为某一个句子;
export const assignmentSentenceInformation=(item,nodeText)=>{
  if (!store.state.taskDetail.isVideoProject) {
    return
  }
  let text = nodeText || "";
  let wordArr = [];
  if (text) {
    wordArr = text.split("\n");
  }
  item.srtTextArr = wordArr.map(item=>{
    return item.length
  });
  //计算字符/秒；
  let srcTextSecond = 0;
  let wordArrText = wordArr.join("");
  if (wordArrText) {
    srcTextSecond = calculateTextSecond(wordArrText.length,item.startTimeMs,item.endTimeMs);
  }
  item.srcTextSecond = srcTextSecond;

  //同步需要更新缓存信息里面的句子信息；·


}


//时间戳转时间轴；
export  const transformTimeFunc=(milliseconds)=>{
  if (milliseconds !== 0 && !milliseconds) {
    return "";
  }
  const hours = Math.floor(milliseconds / 3600000); // 1小时 = 3600000毫秒
  const minutes = Math.floor((milliseconds % 3600000) / 60000); // 1分钟 = 60000毫秒
  const seconds = Math.floor((milliseconds % 60000) / 1000); // 1秒 = 1000毫秒
  const ms = milliseconds % 1000; // 毫秒部分

  // 格式化为两位数，确保统一格式
  const pad = (num) => num.toString().padStart(2, '0');

  // 返回影视时间轴格式
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)},${ms.toString().padStart(3, '0')}`;
}