<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div
      class="
      word-preview-tabs
      padding-lr-24
      display-flex
      align-center
      justify-between
    "
  >
    <a-tag
        class="target-preview-tag"
        v-if="store.state.wordPreviewTabsValue == 3"
    >
      *译文预览与下载后的最终格式可能不同
    </a-tag>
    <div class="left">
      <a-space :size="12" style="flex-shrink: 0">
        <div
            class="title"
            @click="controlOpen == 100 - minSize ? openPreview() : ''"
        >
          <a-dropdown>
            <div class="ant-dropdown-link" @click.prevent>
              {{
                tabsIndex == 1
                    ? '原文预览'
                    : tabsIndex == 2
                        ? '双语预览'
                        : '译文预览'
              }}
              <icon-font type="icon-xiala" class="font-size-12"></icon-font>
            </div>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1">原文预览</a-menu-item>
                <a-menu-item key="3">
                  译文预览
                  <span
                      v-if="
                      $userLevelPrivilege.privilege.file_preview.indexOf(
                        'bilingual'
                      ) < 0
                    "
                      style="position: relative;top: -2px"
                  >
                    <img src="@/images/vipVersion.png" width="16" />
                  </span>
                </a-menu-item>
                <a-menu-item key="2">
                  双语预览
                  <span
                      v-if="
                      $userLevelPrivilege.privilege.file_preview.indexOf(
                        'bilingual'
                      ) < 0
                    "
                      style="position: relative;top: -2px"
                  >
                    <img src="@/images/vipVersion.png" width="16" />
                  </span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>

        <!--//如果路由页面是预览，则屏蔽下方所有-->
        <a-space :size="12" v-if="fromPage == 'Project'">
          <div
              class="controlIcon"
              @click="openPreview"
              v-if="controlOpen == 100 - minSize"
          >
            <a-tooltip title="展开">
              <icon-font type="icon-zhankai"></icon-font>
            </a-tooltip>
          </div>
          <div
              class="controlIcon"
              @click="closePreview"
              v-if="controlOpen < 100 - minSize"
          >
            <a-tooltip title="收起">
              <icon-font type="icon-shouqi"></icon-font>
            </a-tooltip>
          </div>
          <div class="controlIcon" @click="setFullScreen">
            <a-tooltip title="全屏预览">
              <icon-font type="icon-quanping"></icon-font>
            </a-tooltip>
          </div>
        </a-space>
      </a-space>
      <div class="docNameClass">
        <div class="docNameClass-text">
          {{ docName }}
        </div>
      </div>
    </div>
    <div class="ellipsis-tabs-button" v-if="fromPage == 'Project'">
      <pagination></pagination>
    </div>
  </div>
  <div
      id="word-preview-dom"
      class="word-preview"
      :class="{ 'word-preview-fullScreen': fullScreen }"
  >
    <!--译文预览 闪语种-->
    <xgVideo :scroll-height="scrollHeight" ref="xgVideoRef" :controlOpen="controlOpen" :minSize="minSize"></xgVideo>
  <img
      src="./exit.png"
      width="40"
      v-show="fullScreen"
      class="full-leave"
      @click="closeFullScreen"
  />
  </div>
</template>

<script>
import {useStore} from 'vuex'
import pagination from '../pagination.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {computed, h, onBeforeUnmount, onMounted, reactive, ref, toRefs,} from 'vue'
import {useI18n} from 'vue-i18n'
import {ExclamationCircleFilled, FullscreenExitOutlined, LoadingOutlined,} from '@ant-design/icons-vue'
import {message} from 'ant-design-vue'
import sourcePreview from '@/components/source/module/wordExcel/modal/sourcePreview.vue'
import resultPreview from '@/components/source/module/wordExcel/modal/resultPreview.vue'
import targetPreview from '@/components/source/module/wordExcel/modal/targetPreview.vue'
import {getBatchDocList} from '@/http/api'
import mitt from '@/plugins/bus'
import {useRouter} from "vue-router";
import xgVideo from "./video/xgVideo.vue";

export default {
  emits: ['openpreview', 'closepreview'],
  props: {
    scrollHeight: {
      type: Number,
      default: 0,
    },
    controlOpen: {
      type: Number,
      default: 50,
    },
    minSize: {
      type: Number,
      default: 4,
    },
    fromPage:{
      type:String,
      default:'Project'
    }
  },
  components: {
    xgVideo,
    targetPreview,
    ExclamationCircleFilled,
    pagination,
    resultPreview,
    FullscreenExitOutlined,
    sourcePreview,
  },
  setup(props, ctx) {
    // const { appContext } = getCurrentInstance();
    // const globalProxy = appContext.config.globalProperties;
    const { t } = useI18n()
    const store = useStore()
    const fullScreen = computed(() => store.state.fullScreen)
    const CLIENT_HEIGHT = computed(() => store.state.CLIENT_HEIGHT)
    const tabsIndex = computed(() => store.state.wordPreviewTabsValue)
    const checkedDetail = computed(() => store.state.checkedDetail);
    const router = useRouter();
    let BroadCastChannel = null;
    const xgVideoRef = ref(null);
    // userLevelPrivilege
    const $userLevelPrivilege = computed(() => {
      return store.state.userLevelPrivilege
    })
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '12px',
      },
      spin: true,
    })

    const state = reactive({
      isSupportHtmlPreview: false,
      pageCountHtmlPreview: 0,
      docList: [],
    })


    const docName = computed(() => {
      return state.docList.find((item) => {
        return item.docId == checkedDetail.value.docId
      })?.docName
    })




    //新开窗口打开路由
    const openOtherPage=()=>{
      if (checkedDetail.value.sentId) {
        let url = location.href.replaceAll('/project','');
        let newUrl = url + `/preview?sentId=${checkedDetail.value.sentId}&docId=${checkedDetail.value.docId}&sentIndex=${checkedDetail.value.sentIndex}`
        // 打开新窗口
        window.open(newUrl, '_blank');
      } else {
        message.warning("请选中句子出现再打开");
      }
    }

    const setFullScreen = () => {
      if (xgVideoRef.value.isShowMock) {
        message.warning("暂无播放源");
        return
      }
      xgVideoRef.value.player.getFullscreen()
      // store.commit('changeKey', {
      //   fullScreen: true,
      // })
    }

    const closeFullScreen = () => {
      // store.commit('changeKey', {
      //   fullScreen: false,
      // })
    }

    const openPreview = () => {
      ctx.emit('openpreview');
      xgVideoRef.value.player.volume = localStorage.getItem("playVolume")?Number(localStorage.getItem("playVolume")): 1;
    }

    const closePreview = () => {
      xgVideoRef.value.player.volume = 0;
      ctx.emit('closepreview')
    }

    const handleMenuClick = (e) => {
      if (
          e.key > 1 &&
          $userLevelPrivilege.value.privilege.file_preview.indexOf(
              'bilingual'
          ) < 0
      ) {
        if (e.key == 2) {
          window.$levelMessage({
            msg: `${$userLevelPrivilege.value.nameTeam ||
            $userLevelPrivilege.value.name}不支持双语预览`,
          })
        }
        if (e.key == 3) {
          window.$levelMessage({
            msg: `${$userLevelPrivilege.value.nameTeam ||
            $userLevelPrivilege.value.name}不支持译文预览`,
          })
        }
        return
      }

      if (e.key != store.state.wordPreviewTabsValue) {
        store.commit('changeKey', {
          wordPreviewTabsValue: e.key,
        })
      }
    }

    const returnFloat1 = (value) => {
      value = Math.round(parseFloat(value) * 10) / 10
      if (value.toString().indexOf('.') < 0) {
        value = value.toString() + '.0'
      }
      return Number(value)
    }

    const updatePreviewRefScroll = () => {

    }


    const goToPageSentence=(docId,sentIndex)=>{
      //根据index 用接口查出 有哪些句子id 然后再进行定位 有个问题？  利用获取译文的那个接口
    }

    const goToPage = (e) => {
      // console.log(e);
    }

    onMounted(() => {

      BroadCastChannel = new BroadcastChannel("InfoData");

      BroadCastChannel.onmessage=function (e) {
        // console.log(e,"接收信息",props.fromPage);
        let infoData = e.data;
        //对比任务Id
        if (store.state.taskDetail.taskId != infoData.taskId ) {
          return
        }
        switch (props.fromPage) {
          case 'Project':
            if (infoData.type == "bottom") {
              //由单开预览页面点击句子传输过来
              goToPageSentence(infoData.docId,infoData.sentIndexes)
            }

            break;
          case 'Preview':
            if (infoData.type == 'top') {
              //由编辑器更改定位传输过来
              store.commit("changeKey",{
                checkedDetail:{
                  sentId:infoData.sentId,
                  docId:infoData.docId,
                  sentIndex:infoData.sentIndex,
                }
              })
            } else if (infoData.type == 'previewRenew') {
              //由编辑器更新译文传输过来
              mitt.emit("wordPreviewRenew",JSON.parse(infoData.data));
            }
            break;
        }
      }

      //不能放到omMessage里面，每发送一次就会增加一次监听
      if (props.fromPage == 'Project') {
        mitt.on("wordPreviewRenew",(e)=>{
          console.log("检测死循环","译文更新")
          BroadCastChannel.postMessage({
            taskId:store.state.taskDetail.taskId,
            type:'previewRenew',
            data:JSON.stringify(e),
          })
        })
      }




      //获取当前文档下所有配置项；
      getBatchDocList().then((res) => {
        state.docList = [...res.data.data];
        //给store 赋值
        let obj = {

        }
        state.docList.forEach((item=>{
          let docNameArr = item.docName.split(".");
          obj[item.docId] = docNameArr[docNameArr.length - 1]
        }))
        store.commit("changeKey",{
          batchDocList:obj
        })
        // console.log(obj)
      })
    })



    //清空mitt
    onBeforeUnmount(()=>{
      mitt.all.clear()
      BroadCastChannel.close();
    })


    return {
      xgVideoRef,
      openOtherPage,
      docName,
      checkedDetail,
      ...toRefs(state),
      goToPage,
      $userLevelPrivilege,
      indicator,
      handleMenuClick,
      CLIENT_HEIGHT,
      fullScreen,
      setFullScreen,
      closeFullScreen,
      closePreview,
      store,
      tabsIndex,
      t,
      openPreview,
    }
  },
}
</script>

<style lang="scss" scoped>
.docNameClass {
  margin-left: 12px;
  width: 0;
  flex-grow: 1;
  .docNameClass-text {
    word-break: break-all;
    /* overflow: hidden; */
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.ellipsis-tabs-button {
  flex-shrink: 0;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  //align-items: center;
  //.ellipsis-tabs-button-fullScreen {
  //  display: flex;
  //  align-items: center;
  //  cursor: pointer;
  //  span {
  //    margin-left: 4px;
  //    color: #999999;
  //    font-size: 14px;
  //  }
  //}
  //.ellipsis-tabs-button-open-close {
  //  cursor: pointer;
  //  width: 40px;
  //  height: 24px;
  //  margin-left: 20px;
  //  background: #00c6f3;
  //  border-radius: 4px;
  //  text-align: center;
  //  line-height: 24px;
  //  color: white;
  //  ::v-deep(svg) {
  //    position: relative;
  //    top: 1px;
  //    font-size: 16px;
  //  }
  //}
}
.word-preview-tabs {
  position: relative;
  width: 100%;
  height: 40px;
  background: #ffffff;
  -webkit-user-drag: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  .word-preview-tabs-scale {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    border: 1px solid #f2f3f5;
    height: 112px;
    width: 40px;
    right: 0;
    bottom: -20px;
    transform: translateY(100%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    span {
      cursor: pointer;
      font-size: 14px;
    }
  }
  .target-preview-tag {
    position: absolute;
    left: 50%;
    top: 42px;
    z-index: 1;
    transform: translateX(-50%);
  }
  * {
    -webkit-user-drag: none;
  }
  .left {
    display: flex;
    flex-grow: 1;
    .title {
      cursor: pointer;
      font-size: 14px;
      color: #333333;
    }
    .controlIcon {
      cursor: pointer;
      border: 1px solid #eeeeee;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      ::v-deep(svg) {
        font-size: 16px;
      }
      &:hover {
        border-color: #00c6f3;
        ::v-deep(svg) {
          color: #00c6f3;
        }
      }
    }
  }
  .n-resize {
    flex-grow: 1;
    height: 30px;
    cursor: n-resize;
  }

  ::v-deep(.ellipsis-button) {
    border-color: #aaaaaa;
    color: #aaaaaa;
    font-size: 18px;
    span {
      position: relative;
      top: -8px;
    }
  }
}
.word-preview {
  -webkit-user-drag: none;
  * {
    -webkit-user-drag: none;
  }
  position: relative;
  &:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //border: 1px solid #00c6f3;
    z-index: -1;
  }
  ::v-deep(.preview-popover) {
    margin: 0 auto;
    padding: 0 10px;
    min-width: 80px;
    &:hover {
      background: #00c6f3;
    }
  }
}
.word-preview-fullScreen {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}
.full-leave {
  cursor: pointer;
  position: fixed;
  top: 20px;
  z-index: 99;
  right: 20px;
  font-size: 28px;
}
</style>
