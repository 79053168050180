<script setup>
import Player, {Events} from 'xgplayer';
import {computed, onMounted, ref, watch} from "vue";
import 'xgplayer/dist/index.min.css';
import {useStore} from "vuex";
import {DownOutlined} from "@ant-design/icons-vue"
import {postGetVideoPreviewInfoApi, postReComputePage, postSentenceByDocIdAndTimeMs} from "@/http/api";
import filterStrings from "@/plugins/filterString";
import mitt from "@/plugins/bus";
import {positionSentence} from "@/plugins/comm";
import {message} from "ant-design-vue";
import {HlsPlugin} from "xgplayer-hls";

const props =defineProps(['scrollHeight','controlOpen','minSize']);
const tabsIndex = computed(() => store.state.wordPreviewTabsValue)
const store = useStore();
const player = ref(null);
const docRangeArrMap=ref({});

const isShowMock = ref(true);
const videoModel = ref('2');
const videoModelMap = ref({
  '3':'播放全集',
  '2':'播放单句（单次）',
  '1':'播放单句（循环）'
});
const captionDataMap = computed(()=>store.state.video.captionDataMap);
//字幕数据缓存数据，存全局变量；提交译文等一系列操作都会更新缓存数据里面的值;
const captionData = computed({
  get(){
    return store.state.video.captionData;
  },
  set(val){
    //重置值
    let captionDataMap = {

    };
    val.forEach((item,index)=>{
      captionDataMap[item.sentId] = index;
    })
    store.commit("video/changeKey",{
      captionDataMap:captionDataMap
    })
    store.commit("video/changeKey",{
      captionData:val
    })
  }
});
//当前在哪一句记录；
const captionCurrent = ref(null);
//监听checkedDetail
const videoCurrentTime = ref (null);

const checkedDetail = computed(() => {
  return store.state.checkedDetail
})

const captionText = computed(()=>{
  if (captionData.value[captionCurrent.value]) {
    return {
      sText:filterStrings.getPureString(captionData.value[captionCurrent.value].sourcePlaceholder),//原文
      tText:filterStrings.getPureString(captionData.value[captionCurrent.value].targetPlaceholder),//译文
    }
  }
  return {
    sText:"",//原文
    tText:"",//译文
  }
})

// const docRange = computed(()=>{
//   if (checkedDetail.value.docId)
// })

//重置所有数据；重置加载资源；终止缓存字幕数据
const initData=()=>{
  //数据
  captionData.value = [];
  videoCurrentTime.value = null;
  captionCurrent.value = null;
  let videoUrl = getDocVideoUrl(checkedDetail.value.docId);
  if (!videoUrl) {
    player.value?.pause();
    return
  } else {

  }
  console.log(videoUrl);
  player.value.playNext({
    url:videoUrl,
    plugins: [HlsPlugin],
  })
}
//做一个计算属性；还要做更新译文方法；
watch(checkedDetail, (newDetail, oldDetail) => {
  let { docId,sentId,sentIndex,startTimeMs } = newDetail;
  let oldSentId = oldDetail.sentId;

  //如果不在同一个文档，则需要重新加载数据；
  if (newDetail.docId != oldDetail.docId && newDetail.docId) {
    initData();
    return
  }

  if (oldSentId != sentId && !newDetail.isVideo) {
    //重置播放
    if (isShowMock.value) {
      return;
    }
    playerSeek((startTimeMs/1000));
  }

})

const backStart=()=>{
  //单句循环
  if (videoModel.value == 1) {
    if (checkedDetail.value.startTimeMs) {
      playerSeek(checkedDetail.value.startTimeMs/1000);
    } else {
      playerSeek(0);
    }
  }
  //连续播放
  else if (videoModel.value ==2) {
    playerSeek(0)
  }
  else {
    playerSeek(0);
  }
}

const playerSeek=(time)=>{
  //跳转到指定时间的开始节点；
  // time = 30;
  player.value.seek(time);
  //同时定位句子？;
}

//更改播放模式
const changeVideoModel=(type)=>{

  if (type != videoModel.value) {
    player.value.play();

    if (checkedDetail.value?.startTimeMs || checkedDetail.value?.startTimeMs === 0) {
      playerSeek(checkedDetail.value.startTimeMs/1000);
    }
    //如果是切换到自动播放，需要清除筛选 重新加载句子
    if (type == 3) {
      store.commit('excelProject/resetFilterValue')
      store.commit('changeKey', {
        pageNum: 1,
      })
      store.commit('excelProject/changeKey', {
        serialNum:"",
        sourceText: '',
        targetText: '',
        groupBy: null,
        groupSort: null
      })
      store.dispatch('getSentence', { loading:true}).then(res=>{
        // videoModel.value
      })
      // store.dispatch("")
    }

    videoModel.value = type;
  }
}


const seekTimeIsDocRange=(time1000)=>{
  // if (docRangeArrMap)
  let result = null;
  if (!checkedDetail.value.docId) {
    return result;
  }

  // console.log(docRangeArrMap.value[checkedDetail.value.docId],time1000);
  if (docRangeArrMap.value[checkedDetail.value.docId]) {
    docRangeArrMap.value[checkedDetail.value.docId].docRange.forEach(item=>{
        if (time1000>item[0] && time1000<item[1]) {
          result = true;
        }
    })
    // console.log(docRangeArrMap.value[checkedDetail.value.docId])
    return result;
  } else {
    return result;
  }
}


//从Data数据中根据时间找值 保证Data 有数据;
const findCaptionDataIndex=(time)=>{
  let currentTime = time * 1000;
  //这是值;
  // console.log(currentTime,"这是时间")
  let seekRes = seekTimeIsDocRange(currentTime);
  // console.log(seekRes,'这是结果')
  if (seekRes === null) {
    return null
  }

  //如果不在文档列表 返回 null;
  if (!captionData.value?.length) {
    return false
  }
  if (captionData.value[captionData.value.length - 1].endTimeMs >= currentTime && captionData.value[0].startTimeMs <= currentTime ) {
    let cr = captionData.value.findIndex(item=>{
      return item.startTimeMs <= currentTime && item.endTimeMs >= currentTime;
    })
    if (cr !== -1) {
      captionCurrent.value = cr;
    }
    // console.log(cr);
    return cr;
  } else {
    return false
  }
}


//根据时间定位加载句子信息，由自动播放或者进度条滚动的时候触发；
const httpLoadDataFunc=async (time,isPosition)=>{
  if (!checkedDetail.value.docId) {
    return
  }
  let startTimeMs = Math.floor(time*1000);
  let endTimeMs = Math.floor(time*1000) + 60000;
  //查询句子信息 一分钟范围信息；
  let resp = await postSentenceByDocIdAndTimeMs({docId:checkedDetail.value.docId,startTimeMs:startTimeMs,endTimeMs:endTimeMs});
  let list = resp.data.data || [];

  //如果有数据
  if (list.length) {
    let firstStartTime = list[0].startTime;
    //获取到了List并且时间不在数组里面，说明非范围空白帧数
    if (firstStartTime > time) {
      //进行插空白帧字幕
      list.unshift({
        sentId:"xxx",
        startTimeMs:time,
        endTimeMs:firstStartTime-1,
        targetPlaceholder:"",
        sourcePlaceholder:"",
      })
    }
  } else {
    //如果没有数据
    list.unshift({
      sentId:"xxx",
      startTimeMs:startTimeMs,
      endTimeMs:endTimeMs,
      targetPlaceholder:"",
      sourcePlaceholder:"",
    })
  }

  captionData.value = list;
  //进行查询,这个不能用time查；要用当前播放时间查询；
  let res = findCaptionDataIndex(isPosition?time:videoCurrentTime.value);
  if (res !== -1 && res !== false && res !== null) {
    captionCurrent.value = res;
  } else {
    captionCurrent.value = null;
  }

  //如果需要定位
  if (isPosition) {
    let sentId = captionData.value[captionCurrent.value].sentId;
    if (sentId !== 'xxx' && checkedDetail.value.sentId != sentId) {
      let item = {
        sentId
      }
      //开始定位句子；
      if (store.state.excelProject.ArrMap.has(item.sentId)) {
        playerSeek(time)
        positionSentence(item.sentId,false,true)
      } else {
        postReComputePage({
          pageSize: store.state.pageSize,
          taskId: store.state.taskDetail.taskId,
          docId: store.state.taskDetail.docId,
          sentId: item.sentId,
        }).then((res) => {
          playerSeek(time)
          store.commit('changeKey', {
            pageNum: res.data.data.pageIndex,
          })
          store.commit('excelProject/changeKey', {
            serialNum:'',
            sourceText: '',
            targetText: '',
            groupBy: null,
            groupSort: null
          })
          store.commit('excelProject/resetFilterValue')
          store.dispatch('getSentence', { loading: true }).then(() => {
            if (store.state.excelProject.ArrMap.has(item.sentId)) {
              positionSentence(item.sentId,false,true)
            } else {
              message.warning('该句已经被隐藏')
            }
          }) //请求译文
        })
      }
    }
  }

}


let timeout;
let lastExecTime = 0;
let wait = 3000;
let throttleWait = 5000;

//加载字幕数据和缓存文件；
const positionLoadCaptionData=(time)=>{
  //动态加载字幕
  // console.log(captionData.value,captionCurrent.value);
  if (captionData.value[captionCurrent.value]) {
    let time1000 = time * 1000;
    // console.log(captionData.value[captionCurrent.value].startTimeMs,captionData.value[captionCurrent.value].endTimeMs,time);
    if (time1000 > captionData.value[captionCurrent.value].startTimeMs && time1000 < captionData.value[captionCurrent.value].endTimeMs) {
      return;
    }
  }



  //判断是否有字幕数据 //查询当前时间是否处于captionData.value；
  let res = findCaptionDataIndex(time);
  // console.log(res,time,captionData.value);
  // console.log(res,time,"进行加载字幕");
  if (res === null) {
    //清楚字幕，重制captionCurrent.value
    captionCurrent.value = null;
    return;
  }

  // console.log("动态加载字幕")
  //为false 不在缓存list 里面 ；但是在文档句子列表里面
  if (res === false) {
    //不在此数组；需要加载数据
    // const context = this;
    const now = Date.now();
    // 防抖节流逻辑
    if (now - lastExecTime < throttleWait) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        lastExecTime = now;
        httpLoadDataFunc(time)
      }, wait);
    } else {
      // 如果超过 throttleWait，则立即执行
      clearTimeout(timeout);
      lastExecTime = now;
      httpLoadDataFunc(time)
    }

  } else {
    //不等于-1 说明确定为某一项
   if (res !== -1) {
      captionCurrent.value = res;
    } else {
     captionCurrent.value = null;
   }
  }
}

//时间定位句子;
const timePositionSentence=(currentTime)=>{
  //如果无数据
  if (!captionData.value?.length) {
    return;
  }

  let res = findCaptionDataIndex(currentTime);
  //说明不在此文档句子里面;
  if (res === null) {
    return
  }

  //说明查不到句子Id 但是一定有数据
  if (res === false) {
    return;
  }

  //说明有数据
  if (res !== -1) {
    //查询数据
    let sentId = captionData.value[res].sentId;
    if (sentId !== 'xxx' && checkedDetail.value.sentId != sentId) {
      let item = {
        sentId
      }
      //开始定位句子；
      if (store.state.excelProject.ArrMap.has(item.sentId)) {
        positionSentence(item.sentId,false,true)
      } else {
        postReComputePage({
          pageSize: store.state.pageSize,
          taskId: store.state.taskDetail.taskId,
          docId: store.state.taskDetail.docId,
          sentId: item.sentId,
        }).then((res) => {
          store.commit('changeKey', {
            pageNum: res.data.data.pageIndex,
          })
          store.commit('excelProject/changeKey', {
            serialNum:'',
            sourceText: '',
            targetText: '',
            groupBy: null,
            groupSort: null
          })
          store.commit('excelProject/resetFilterValue')
          store.dispatch('getSentence', { loading: true }).then(() => {
            if (store.state.excelProject.ArrMap.has(item.sentId)) {
              positionSentence(item.sentId,false,true)
            } else {
              message.warning('该句已经被隐藏')
            }
          }) //请求译文
        })
      }
    }
  }

  // 如果res 有值 则 开始定位；
  //查找当前数据的sentId；
  //如果有数据 进行定位；
}


const getDocVideoUrl =(docId)=>{
  //如果有选中值 则查询docId对应的文档
  let url = "";


  if (!docRangeArrMap.value[docId]) {
    isShowMock.value = 1;
  } else if (docRangeArrMap.value[docId] && docRangeArrMap.value[docId].transCodeStatus == 'init') {
    isShowMock.value = 2;
  } else if (docRangeArrMap.value[docId] && docRangeArrMap.value[docId].transCodeStatus == '') {
    console.log(666)
    isShowMock.value = 2;
  } else if (docRangeArrMap.value[docId] && docRangeArrMap.value[docId].transCodeStatus == 'failed') {
    isShowMock.value = 3;
  } else {
    console.log(docRangeArrMap.value[docId])
    isShowMock.value = false;
    url = docRangeArrMap.value[docId].videoUrl;
  }

  // isShowMock.value = 2;
  return url
}

//刷新接口状态
const refreshStatus = ()=>{
  postGetVideoPreviewInfoApi().then(res=> {
    // console.log(res);
    let resp = res.data.data.data;

    resp.forEach(item => {
      if (!docRangeArrMap.value[item.webCatDocId]) {
        docRangeArrMap.value[item.webCatDocId] = {};
        docRangeArrMap.value[item.webCatDocId].docRange = [];
      }

      docRangeArrMap.value[item.webCatDocId].videoUrl = item.transCodeFileUrl;
      docRangeArrMap.value[item.webCatDocId].docRange.push([item.startTimeMs, item.endTimeMs]);
      docRangeArrMap.value[item.webCatDocId].videoDuration = item.videoDuration;
      docRangeArrMap.value[item.webCatDocId].transCodeStatus = item.transCodeStatus;
    })
    initData();
  })
}

//更新 webcatDoc
onMounted(()=>{


  let playVolume = localStorage.getItem("playVolume")?Number(localStorage.getItem("playVolume")): 1;
  let playbackRate = localStorage.getItem("playbackRate") || 1;

  //初始化实例
  console.log(playVolume);




  player.value = new Player({
    id: 'xgPlayer',
    url: "",
    height: '100%',
    width: '100%',
    plugins: [HlsPlugin],
    playbackRate: [0.5,1,1.5, 2, 4],
    volume:playVolume,
    defaultPlaybackRate:playbackRate,
  });
  //播放时间改变时间；然后加载字幕数据
  player.value.on(Events.TIME_UPDATE, async (data) => {

    if (isShowMock.value) {
      return
    }
    //查看当前时间；
    const {currentTime} = data;

    //检测模式，单句？ 单句连续？ 自动播放？
    videoCurrentTime.value = currentTime;

    let time1000 = currentTime*1000;

    //加载字幕
    positionLoadCaptionData(currentTime);
    //单句循环
    if (videoModel.value == 1) {
      //检测时间
      if (checkedDetail.value.startTimeMs) {
        if (checkedDetail.value.startTimeMs > docRangeArrMap.value[checkedDetail.value.docId].videoDuration) {
          return
        }
        if (time1000<checkedDetail.value.startTimeMs || time1000 > checkedDetail.value.endTimeMs) {
          playerSeek(checkedDetail.value.startTimeMs / 1000);
        }
      }
    }
    //连续播放
    else if (videoModel.value ==2) {
      if (checkedDetail.value.startTimeMs === 0 || checkedDetail.value.startTimeMs) {
        if (checkedDetail.value.startTimeMs > docRangeArrMap.value[checkedDetail.value.docId].videoDuration) {
          return
        }
        if (time1000<checkedDetail.value.startTimeMs || time1000 > checkedDetail.value.endTimeMs) {
          player.value.pause()
        }
      }
    }
    //自动播放要定位上方；
    else {

      if (props.controlOpen == 100 - props.minSize) {
        return
      }


      let seekRes = seekTimeIsDocRange(time1000);
      if (seekRes === null) {
        return null;
      }
      //检测SentId 更改CheckDetail信息；并附上特殊字段；
      timePositionSentence(currentTime)
    }

  })


  //倍速变化
  // player.value.on(Events.RATE_CHANGE, () => {
  //   // const rate = player.playbackRate // 默认为1;
  //   const rate = player.value.playbackRate
  //   localStorage.setItem("playbackRate",rate)
  // })


  // player.value.on(Events.VOLUME_CHANGE, () => {
  //   // const muted = player.muted // 是否静音， Boolean
  //   console.log()
  //   const volume = player.value.volume;  // 音量 0-1
  //   localStorage.setItem("playVolume",volume);
  // })

  player.value.on(Events.USER_ACTION, (data) => {
    console.log(data)
    if (data.action == "change_muted" && data.pluginName == "volume") {
      let muted = data.props[0].muted.to;
      if (muted === true) {
        muted = 0;
      }
      if (muted === false) {
        muted = data.volume;
      }
      // console.log(muted)
      localStorage.setItem("playVolume",muted);
      return;
    }

    if (data.action == "change_volume" && data.pluginName == "volume") {
      let volume = data.props[0].volume.to;
      localStorage.setItem("playVolume",volume);
      return;
    }

    if (data.action == "change_rate" && data.pluginName == 'playbackrate') {
      // data.
      let rate = data.props[0].playbackRate.to;
      localStorage.setItem("playbackRate",rate)
    }
    // data结构如下
    /**
     * data = {
     *   action: String,        // 用户行为
     *   pluginName: String,    // 从哪个插件触发
     *   props: [{              // 发生变化的属性列表
     *     props: String,       // 发生变化的属性
     *     from: any,           // 变化前的值
     *     to: any              // 变化后的值
     *   }],
     *   event: Event,          // 事件
     *   currentTime: Number,   // 当前播放时间
     *   duration: Number,      // 当前播放器时长
     *   ended:  Boolean,        // 是否播放结束
     *   paused:  Boolean,       // 是否暂停
     * }
     */
  })


  // 进度条插件绑定
  const progress = player.value.getPlugin('progress');



  //绑定Click时间
  progress.addCallBack('click', (data) => {

    if (isShowMock.value) {
      return
    }
    // console.log(data)
    let {currentTime} = data;
    let oldCurrentTime = currentTime;

    if (videoModel.value !=3 ){
      //需要进行定位;
      currentTime = currentTime * 1000;
      //这是值;
      // console.log(currentTime,"这是时间")
      let seekRes = seekTimeIsDocRange(currentTime);

      //如果不在范围直接return
      if (seekRes == null) {
        return
      }

      let index = false; //设置默认值不在false

      if (captionData.value?.length) {
        if (captionData.value[captionData.value.length - 1].endTimeMs >= currentTime && captionData.value[0].startTimeMs <= currentTime ) {
          let cr = captionData.value.findIndex(item=>{
            return item.startTimeMs <= currentTime && item.endTimeMs >= currentTime;
          })
          if (cr > -1) {
            captionCurrent.value = cr;
            index = cr;
          } else {
            //无值
            index = null;
          }
          // console.log(cr);
        } else {
          index = false
        }
      } else {
        index = false;
      }

      // console.log(index)
      //说明在缓存数组里面
      if (index === 0 || index>0) {
        //查询数据
        let sentId = captionData.value[index].sentId;
        if (sentId !== 'xxx' && checkedDetail.value.sentId != sentId) {
          let item = {
            sentId
          }
          //开始定位句子；
          if (store.state.excelProject.ArrMap.has(item.sentId)) {
            positionSentence(item.sentId,false,true)
          } else {
            postReComputePage({
              pageSize: store.state.pageSize,
              taskId: store.state.taskDetail.taskId,
              docId: store.state.taskDetail.docId,
              sentId: item.sentId,
            }).then((res) => {
              store.commit('changeKey', {
                pageNum: res.data.data.pageIndex,
              })
              store.commit('excelProject/changeKey', {
                serialNum:'',
                sourceText: '',
                targetText: '',
                groupBy: null,
                groupSort: null
              })
              store.commit('excelProject/resetFilterValue')
              store.dispatch('getSentence', { loading: true }).then(() => {
                if (store.state.excelProject.ArrMap.has(item.sentId)) {
                  positionSentence(item.sentId,false,true)
                } else {
                  message.warning('该句已经被隐藏')
                }
              }) //请求译文
            })
          }
        }
      } else {
        //不在缓存数组里面;则

        if (index !== null) {
          httpLoadDataFunc(oldCurrentTime,true);
        }

      }
    }
  })

  refreshStatus();

  mitt.on("wordPreviewRenew",(e)=>{
    if (e.type == 1) {
      e.list.forEach((item) => {
        //如果是处于同一文档，则需要更新句子；
        if (item.docId == checkedDetail.value.docId) {
          //如果存在 则更新值 原文无需更改，只要更改原文都会触发getSentce；
          let index = captionDataMap.value[item.sentId];
          // console.log(index);
          if (index !== undefined) {
            store.dispatch("video/updateCaptionData",{
              // index
              index,
              targetPlaceholder:item.targetPlaceholder,
            })
          }
        }
      })
    }
  })
})
defineExpose({ player,isShowMock });
</script>

<template>
<div :style="{height: props.scrollHeight + 'px'}" style="position: relative">
  <div class="xgplayer-plugin">
    <a-space :size="8" direction="vertical">
      <div class="xgplayer-plugin-button" @click="backStart">回到起点</div>
      <a-dropdown>
        <template #overlay>
          <div class="font-size-14 padding-lr-16 color-fff" style="padding-left: 90px">
            <div style="line-height: 30px" class="cursor-pointer" @click="changeVideoModel(index)" v-for="(item,index) in videoModelMap" :key="item">
              {{item}}
            </div>
          </div>
        </template>
        <div class="xgplayer-plugin-button"><span style="display: inline-block;width: 74px;">播放模式：</span>{{videoModelMap[videoModel]}}<DownOutlined /></div>
      </a-dropdown>
    </a-space>
  </div>
  <!--字幕数据-->
  <div class="xgplayer-captions-plugin">
    <!--原文字幕数据-->
    <div v-if="tabsIndex!=3" class="text" v-html="captionText.sText">
    </div>
    <!--译文字幕数据-->
    <div v-if="tabsIndex!=1" class="text" v-html="captionText.tText">
    </div>
  </div>

  <!--字幕数据//需要根据状态改数据-->
  <div class="xgplayer-mock" v-show="isShowMock">
    <div v-if="isShowMock==1 ">没有关联视频，可让PM在文件列表关联视频哦</div>
    <div v-if="isShowMock==2" class="text-center">
      <div>
        <img src="./fail.png" width="96">
      </div>
      <div class="margin-tb-20 ">
        视频努力转码中，请稍后刷新
      </div>
      <div>
        <a-button type="primary" shape="round" @click="refreshStatus">
          刷新
        </a-button>
      </div>
    </div>
    <div v-if="isShowMock==3">视频转码失败</div>
  </div>
  <div id="xgPlayer"></div>
</div>
</template>

<style scoped lang="less">
.xgplayer-plugin{
  position: absolute;
  left:20px;
  top: 20px;
  z-index: 1;
  .xgplayer-plugin-button{
    cursor: pointer;
    height: 32px;
    border-radius: 16px;
    border: 1px solid rgba(255,255,255,0.2);
    color:white;
    text-align: center;
    line-height: 30px;
    padding:0 16px;
  }
}
.xgplayer-captions-plugin{
  position: absolute;
  bottom:50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  .text{
    white-space: pre-wrap;
    font-size: 22px;
    color:red;
    font-weight: bold;
  }
}
.xgplayer-mock{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
  background: #333;
  color:#fff;
}
</style>