<script setup>
import {computed, onBeforeMount, reactive} from "vue";
import {useStore} from "vuex";
import {message} from "ant-design-vue";
import {postSetUserDictionaryApi} from "@/http/api";

const store = useStore();
const word_setting=computed(()=>{
  return store.state.video.word_setting;
})
const wordSec_setting = computed(()=>{
  return store.state.video.wordSec_setting;
})

const state = reactive({
  visible:false,
  word_setting_one:"",
  word_setting_two:"",
  word_setting_three:"",
  wordSec_setting_one:"",
  wordSec_setting_two:"",
  wordSec_setting_three:"",
})
const onIconClick=()=>{
  if (state.visible) {
    return
  }
  //赋值；
  state.word_setting_one = word_setting.value.one_level;
  state.word_setting_two = word_setting.value.two_level;
  state.word_setting_three = word_setting.value.three_level;

  state.wordSec_setting_one = wordSec_setting.value.one_level;
  state.wordSec_setting_two = wordSec_setting.value.two_level;
  state.wordSec_setting_three = wordSec_setting.value.three_level;

  state.visible=true;
}
const buttonCancel=()=>{
  state.visible=false
}
const buttonSubmit=(type)=>{
  if (state.word_setting_two<state.word_setting_one) {
    message.warning("字符高亮设置一般超长不能小于轻微超长")
    return
  }
  if (state.word_setting_two>state.word_setting_three) {
    message.warning("字符高亮设置一般超长不能大于严重超长")
    return
  }

  if (state.wordSec_setting_two<state.wordSec_setting_one) {
    message.warning("字符/秒高亮设置一般超长不能小于轻微超长")
    return
  }
  if (state.wordSec_setting_two>state.wordSec_setting_three) {
    message.warning("字符/秒高亮设置一般超长不能大于严重超长")
    return
  }


  let com = {
    word_setting:{
      one_level:state.word_setting_one,
      two_level:state.word_setting_two,
      three_level:state.word_setting_three,
    },
    wordSec_setting:{
      one_level:state.wordSec_setting_one,
      two_level:state.wordSec_setting_two,
      three_level:state.wordSec_setting_three,
    },
  }

  // 保存值
  store.commit("video/changeKey",com)
  state.visible=false;

  store.dispatch("getSentence",{loading:true});
  let enterpriseId = store.state.taskDetail.enterpriseId;
  let groupId = store.state.taskDetail.groupId;
  if (type == 1) {
    postSetUserDictionaryApi({enterpriseId:enterpriseId,key:"projectAllSet",value:JSON.stringify(com),description:"字符/秒全局设置"});
    // 保存新项目;同步保存本项目 也就是下面那个接口必须调用
  }
  postSetUserDictionaryApi({enterpriseId:enterpriseId,key:groupId,value:JSON.stringify(com),description:"字符/秒用户设置"});

}

onBeforeMount(async ()=>{

})
</script>

<template>
<div class="thead word-sec">
  字符/秒
  <a-popover placement="bottom" :title="null" v-model:visible="state.visible" :trigger="'click'">
    <template #content>
      <div style="width: 360px;">
        <div class="word-setting">
          <div class="setting-title">
            字符高亮设置
          </div>
          <div class="setting-content">
            <div class="setting-logo">
              <span class="global-level-one-bg"></span>
              <label>轻微超长</label>
            </div>
            <a-input-number v-model:value="state.word_setting_one" :precision="0" :min="1" class="number-input"></a-input-number>
          </div>
          <div class="setting-content">
            <div class="setting-logo">
              <span class="global-level-two-bg"></span>
              <label>一般超长</label>
            </div>
            <a-input-number v-model:value="state.word_setting_two" :precision="0" :min="1" class="number-input"></a-input-number>
          </div>
          <div class="setting-content">
            <div class="setting-logo">
              <span class="global-level-three-bg"></span>
              <label>严重超长</label>
            </div>
            <a-input-number v-model:value="state.word_setting_three" :precision="0" :min="1" class="number-input"></a-input-number>
          </div>
        </div>

        <div class="word-setting padding-t-8">
          <div class="setting-title">
            字符/秒高亮设置
          </div>
          <div class="setting-content">
            <div class="setting-logo">
              <span class="global-level-one-bg"></span>
              <label>轻微超长</label>
            </div>
            <a-input-number v-model:value="state.wordSec_setting_one" :precision="0" :min="1" class="number-input"></a-input-number>
          </div>
          <div class="setting-content">
            <div class="setting-logo">
              <span class="global-level-two-bg"></span>
              <label>一般超长</label>
            </div>
            <a-input-number v-model:value="state.wordSec_setting_two" :min="1" :precision="0" class="number-input"></a-input-number>
          </div>
          <div class="setting-content">
            <div class="setting-logo">
              <span class="global-level-three-bg"></span>
              <label>严重超长</label>
            </div>
            <a-input-number v-model:value="state.wordSec_setting_three"  :min="1" :precision="0" class="number-input"></a-input-number>
          </div>
        </div>
        <div class="text-right padding-t-8">
          <a-button class="margin-r-8" @click="buttonCancel" size="small">取消</a-button>
          <a-button class="margin-r-8" type="primary" @click="buttonSubmit(1)" size="small">保存，新项目生效</a-button>
          <a-button type="primary" @click="buttonSubmit(2)" size="small">保存，仅本项目生效</a-button>
        </div>
      </div>
    </template>
    <icon-font type="icon-setting" @click="onIconClick" v-if="store.state.taskDetail.taskClassify == 8"></icon-font>
  </a-popover>
</div>
</template>

<style scoped lang="less">
.word-setting{
  color:#333;
  .setting-title{
    margin-bottom: 8px;
    font-weight: bold;
  }
  .setting-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .setting-logo{
      display: flex;
      align-items: center;
      flex-shrink: 0;
      span{
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }
      label{
        flex-shrink: 0;
        font-size: 14px;
      }
    }
    .number-input{
      width: 100px;
    }
  }
}
</style>