<template>
  <a-tooltip>
    <template #title>
      {{ isWindow ? 'Alt + →' : 'Control + Option + →' }}
    </template>
    <a-button
      class="li"
      type="link"
      style="height: 22px; line-height: 22px;"
      @click="copySource()"
      :class="{ disabled: checkedDetail.locked || disabled }"
    >
      <icon-font class="font-size-16 margin-r-4" type="icon-fuzhi"></icon-font>
      <span class="li-txt">复制原文</span>
    </a-button>
  </a-tooltip>

  <a-modal
    :visible="true"
    title="提示"
    @ok="handleOk"
    centered
    okText="确认复制"
    @cancel="handleCancel"
    v-if="copySourceVisible"
  >
    <div class="modal">
      <div class="title">
        <img src="./images/copySource.png" />
      </div>
      <div class="content">
        已勾选
        <span style="color:#00B4FA">{{ checkedList?.length }}</span>
        个句段，确认批量复制原文？
      </div>
      <div class="footer">
        译文框内将直接填充原文内容
      </div>
      <saveSnapshotCheck
        v-model:saveSnapshotValue="saveSnapshotValue"
      ></saveSnapshotCheck>
    </div>
  </a-modal>
</template>

<script>
import {computed, getCurrentInstance, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import useUtils from '@/plugins/utils'
import filterStrings from '@/plugins/filterString'
import {updateBatchTarget} from '@/http/api'
import saveSnapshotCheck from '@/components/saveSnapshotCheck/index.vue'

export default {
    name: 'index.vue',
    components: { saveSnapshotCheck },
    props: ['disabled'],
    setup() {
      const { t } = useI18n()
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const store = useStore()
      const { setFocusEnd } = useUtils()
      const taskDetail = computed(() => store.state.taskDetail)
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })
      const copySourceVisible = computed(
        () => store.state.modal.copySourceVisible
      )

      const checkedList = computed(() => store.state.excelProject.checkedList)

      const state = reactive({
        saveSnapshotValue: false,
      })

      const handleOk = () => {
        let data = store.state.excelProject.checkedList.map((item) => {
          return {
            sentId: item.sentId,
            targetPlaceholder: item.sourcePlaceholder,
            sourceHasChange: false,
            targetHasChange: true,
            targetText: filterStrings.getPureString(item.sourcePlaceholder),
          }
        })
        store.state.screenLoading = true
        handleCancel()
        updateBatchTarget({
          data: JSON.stringify({
            docId: store.state.taskDetail.docId,
            data: data,
          }),
          saveSnapshot: state.saveSnapshotValue,
        })
          .then((res) => {
            if (state.saveSnapshotValue) {
              //更改快照信息
              store.commit('optionsBar/changeKey', {
                allowSaveSnapshot: true,
              })
            }
            store.commit('excelProject/changeKey', {
              checkedList: [],
            })
            if (store.state.excelProject.differentHighLightModel) {
              store.dispatch('getSentence', { loading: true })
            } else {
              store.state.screenLoading = false
              data.forEach((item) => {
                let sentence =
                  store.state.excelProject.docData.sentenceDTOList[
                    store.state.excelProject.ArrMap.get(item.sentId)
                  ]
                store.dispatch('updateTargetOrStatus', {
                  type: 'CopySource',
                  targetHTML: item.targetPlaceholder,
                  sentId: item.sentId,
                })
              })
            }
          })
          .catch((rej) => {
            store.dispatch('getSentence', { loading: true })
          })
      }

      const handleCancel = () => {
        store.state.modal.copySourceVisible = false
      }

      const copySource = () => {
        store.dispatch('optionsBar/copySource')
      }
      return {
        checkedList,
        ...toRefs(state),
        isWindow: store.state.isWindow,
        handleCancel,
        store,
        handleOk,
        copySourceVisible,
        copySource,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
</script>

<style scoped lang="less">
  .li {
    display: flex;
    align-items: center;
    padding: 0 !important;
    .li-txt {
      position: relative;
      top: -1px;
    }
  }

  .modal {
    text-align: center;
    .title {
      img {
        width: 56px;
      }
    }
    .content {
      color: #1c2129;
      line-height: 22px;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    .footer {
      color: #4e5969;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }
</style>
