import {message} from 'ant-design-vue'
import {getQueryString} from '@/plugins/prototype'
import {
  getDomainDetail,
  getEnterprisePermission,
  getGetUserDictionaryApi,
  getLastModifiedSent,
  getPreviewDetail,
  getUserLevelPrivilege,
} from '@/http/api'
import {$storageUser} from '@/plugins/proxyStorage'
import {positionSentence} from '@/plugins/comm'
import {deleteNotUsedDb, initDB} from '@/indexDB/indexDB.js'

import {initAutoCommit, timeIntervalInspect,} from '@/indexDB/autoCommit.js'

export default async function init(store) {
  const isPreview = getQueryString('isPreview')
  const splitIndex = getQueryString('splitIndex')
  //是否是审校模式
  // const isCorpus = getQueryString('isCorpus')

  const initStorage = () => {
    if ($storageUser.userId != store.state.taskDetail.userId) {
      //记录存储的值 userId 存储的时候已经自动初始化
      $storageUser.userId = store.state.taskDetail.userId
    }
  }

  //预览模式
  if (isPreview === 'true') {
    //获取按钮配置
    store.dispatch('getPreviewModelOptionResource')
    getUserLevelPrivilege({}).then((level) => {
      store.commit('changeKey', {
        userLevelPrivilege: level.data.data,
      })
      getPreviewDetail({ splitIndex: splitIndex }).then(async (res) => {

        //根据返回要提示的原因，来直接toast提示出来，如果没有则不提示；
        if (res.data.data.reason) {
          message.warning(res.data.data.reason);
        }

        //获取语种
        store.dispatch('getLanguageList');


        //存储taskDetail信息
        store.commit('changeKey', {
          taskDetail: res.data.data,
        })

        //赋值是否媒体流
        if (res.data.data.docParseType == 7) {
          res.data.data.isVideoProject = true;
          let resp1 = await getGetUserDictionaryApi({enterpriseId:store.state.taskDetail.enterpriseId,key:store.state.taskDetail.groupId});
          console.log(resp1.data.data);
          let data1 = resp1.data.data;
          if (data1) {
            let com = JSON.parse(data1);
            store.commit("video/changeKey",com)
          } else {
            //查全局
            let resp2 = await getGetUserDictionaryApi({enterpriseId:store.state.taskDetail.enterpriseId,key:"projectAllSet"});
            let data2 = resp2.data.data;
            if (data2) {
              let com = JSON.parse(data2);
              store.commit("video/changeKey",com)
            }
          }
        }

        getDomainDetail({ enterpriseId: res.data.data.enterpriseId }).then(
          (result) => {
            let settings = result.data.data
            store.commit('changeKey', {
              settings,
            })
            let link = document.querySelector('link[rel*="icon"]')
            link.href = settings.logo
          }
        )
        store.dispatch('getSentence',{isBackTop:true})
        // store.dispatch("get")
        //初始化本地存储用户信息以及差异对比开关操作
        initStorage();

        //根据源语种方向和目标语种方向来确定值
        if (store.state.specialLanguageList.includes(store.state.taskDetail.sourceLangId)) {
          document.body.classList.add("source-language-text-2");
        } else {
          document.body.classList.add("source-language-text-1");
        }
        if (store.state.specialLanguageList.includes(store.state.taskDetail.targetLangId)) {
          document.body.classList.add("target-language-text-2");
        } else {
          document.body.classList.add("target-language-text-1");
        }
      })
    })
  } else {
    //正常模式 获取用户权限
    getUserLevelPrivilege({}).then((res) => {
      store.commit('changeKey', {
        userLevelPrivilege: res.data.data,
      })
      //获取润色列表并且获取自定义润色数据



      store.dispatch("getDecorateListFunc")
      //获取任务信息
      store.dispatch('getTaskDetail').then((res) => {
        //获取域名信息
        getDomainDetail({ enterpriseId: res.enterpriseId }).then((res) => {
          let settings = res.data.data
          store.commit('changeKey', {
            settings,
          })
          let link = document.querySelector('link[rel*="icon"]')
          link.href = settings.logo
        });

        getEnterprisePermission({ enterpriseId: res.enterpriseId }).then(res=>{
          // console.log(res);
          let enterpriseObj = res.data.data;
          console.log(enterpriseObj);
          // let enterpriseObj = enterpriseList.find(item=>{
          //   return item.enterpriseId == res.enterpriseId;
          // });
          store.commit("changeKey",{
            enterpriseGlobalObj:enterpriseObj,
          })
        })

        //获取按钮配置
        if (res.taskStatus == 2) {
          store.dispatch('getPreviewModelOptionResource')
        } else {
          //初始化数据库
          initDB();
          //初始化自动提交；
          initAutoCommit();
          //删除未使用的数据库；
          deleteNotUsedDb();
          // //普通模式进来触发循环检查
          timeIntervalInspect()
          store.dispatch('getOptionResource')
        }
        if (res.taskClassify == 8) {
          store.commit('excelProject/replaceFilterArr', {
            activityWorkflow: res.activityWorkflow,
          })
        }
        //获取语种
        store.dispatch('getLanguageList')
        //获取行业
        store.dispatch('getIndustryInfoList')
        //获取QA配置
        store.dispatch('rightToolbar/getQASetting')


        //获取最后一句更新的句子；直接定位过去
        getLastModifiedSent({ sentListSize: store.state.pageSize }).then(
          (res) => {
            let result = res.data.data
            if (result.modified) {
              store.commit('changeKey', {
                pageNum: result.page,
              })
              store.dispatch('getSentence', { sentencePosition: result.sentId}).then((res) => {
                  positionSentence(result.sentId)
                })
              return
            }
            store.dispatch('getSentence', {isBackTop:true}).then((res) => {
              let tableItem =
                store.state.excelProject.docData.sentenceDTOList[0] || {}
              store.commit('changeKey', {
                checkedDetail: {
                  ...tableItem,
                },
              })
            })
          }
        )
        //初始化本地存储用户信息以及差异对比开关操作
        initStorage()

        store.dispatch('checkProgress') //请求qa预览进度


        //根据源语种方向和目标语种方向来确定值
        if (store.state.specialLanguageList.includes(store.state.taskDetail.sourceLangId)) {
          document.body.classList.add("source-language-text-2");
        } else {
          document.body.classList.add("source-language-text-1");
        }
        if (store.state.specialLanguageList.includes(store.state.taskDetail.targetLangId)) {
          document.body.classList.add("target-language-text-2");
        } else {
          document.body.classList.add("target-language-text-1");
        }
      })
    })
  }
}
