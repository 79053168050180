import {createApp} from 'vue'
import Antd, {message, notification} from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less'
import i18n from './i18n/' //国际化
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import {createFromIconfontCN} from '@ant-design/icons-vue'
import NButton from '@/components/NButton/index.vue'
import {ElButton, ElScrollbar} from 'element-plus'
import 'element-plus/es/components/scrollbar/style/css'
import 'element-plus/es/components/button/style/css'

import '@transn/im-sdk/dist/style.css'
// import { create, all} from 'mathjs';
// const mathConfig= create(all);
// mathConfig.config({ number: 'BigNumber' });
import './$Message.js'
import 'animate.css'
import '@/styles/new-edit-table.less'
import initWindowFunc from "@/init/initWindowFunc.js";
import EngineTag from "@/components/EngineTag/EngineTag.vue";
// import "@/styles/customize.scss"
// //阻止警告提示
// import 'default-passive-events'
const IconFont: any = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3888084_y0fj88db6r8.js',
})
// import { Decimal } from 'decimal.js'

const app = createApp(App)
app.component('IconFont', IconFont)
app.component('NButton', NButton)
app.component('ElScrollbar', ElScrollbar)
app.component('ElButton', ElButton);
app.component('EngineTag', EngineTag);


app.config.globalProperties.$assignValueToSrc = function (placeholderHTML = ""){
  //给占位符图片重新赋值用于过滤转换器
  return (window as any).$assignValueToSrc(placeholderHTML);
}

initWindowFunc();

message.config({
  top: `10px`,
  duration: 2,
  maxCount: 1,
})

notification.config({
  placement: 'topRight',
  bottom: '50px',
  duration: 3,
});

if (localStorage.locale === 'en') {
  document.title = 'WebCAT Online translation'
} else {
  document.title = 'WebCAT在线翻译'
}

app
  .use(store)
  .use(router)
  .use(Antd)
  .use(i18n)
  .use(PerfectScrollbar)
  .mount('#app')
