<template>
  <a-modal
      :visible="intelligentAgentDialog.status"
      title="智能体"
      @ok="closeModal"
      :closable="false"
      :maskClosable="false"
      centered
      :width="560"
  >
    <template #title>
      <div class="display-flex align-center">
        <img src="./images/logo.png">
        <b class="color-333">智能体：</b>
        <span></span>
      </div>
    </template>

    <div class="replace-re">
      <!--重译完成 有句子重译-->
      <div class="result" v-if="intelligentAgentDialog.status == 'SUCCESS'">
        <div><img src="./images/success.png" /></div>
        <div class="text">
          智能体处理完成，处理
          <span>{{ intelligentAgentDialog.finishCount }}</span>
          句
        </div>
<!--        &lt;!&ndash;需要控制&ndash;&gt;-->
<!--        <div style="background: #F2F3F5;border-radius: 8px;height: 40px;" class="margin-t-16 padding-lr-16 display-flex align-center justify-between">-->
<!--          <span class="font-size-13 color-666"><b class="color-333">智能体处理结果：</b>{}xxx.doc 下载</span><span style="color:#00B4FA" class="cursor-pointer" @click="downloadFile">下载</span>-->
<!--        </div>-->
      </div>
      <!--替换正在进行中-->
      <div v-else>
        <div class="progress">
          <div class="left">
            <div class="text">智能体需处理的句子</div>
            <div>
              <div class="number">
                {{ intelligentAgentDialog.totalCount || 0 }}
              </div>
              <!--            <div class="progress-number">-->
              <!--              <a-progress :percent="30" strokeColor="#00C6F3" />-->
              <!--            </div>-->
            </div>
          </div>
          <div
              class="center"
              :class="{
            center_animatemove: intelligentAgentDialog.status != 'SUCCESS',
          }"
          >
            <img src="./images/loading.png" />
          </div>
          <div class="right">
            <div class="text">已处理完成的句子</div>
            <div>
              <div class="number">
                {{ intelligentAgentDialog.finishCount }}/{{
                  intelligentAgentDialog.totalCount
                }}
              </div>
              <div class="progress-number">
                <a-progress
                    :percent="parseInt(intelligentAgentDialog.progress)"
                    strokeColor="#00C6F3"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="replace-re-remind">
          提醒： 大批量的智能体处理可能需要一定时间，请耐心等待。
        </div>
      </div>
    </div>

    <template #footer>
      <a-button @click="stopProgress" v-if="intelligentAgentDialog.status != 'SUCCESS'">
        停止处理
      </a-button>
      <a-button
          type="primary"
          :loading="intelligentAgentDialog.status != 'SUCCESS'"
          @click="closeModal"
      >
        {{ intelligentAgentDialog.status == 'SUCCESS' ? '好的' : '请等待' }}
      </a-button>
    </template>
  </a-modal>

  <a-modal
      v-model:visible="stopVisible"
      @ok="confirmStop"
      @cancel="cancelStop"
      okText="确认停止"
      cancelText="返回"
      centered
  >
    <div style="text-align: center">
      <div class="margin-b-20">
        <img src="@/assets/warning_1.png" width="56" />
      </div>
      <div style="font-size: 16px;color:#1c2129" class="margin-b-8">
        是否停止智能体处理
      </div>
      <div style="font-size: 14px;color:#4e5969">
        停止后，已处理的译文会填充到译文框，未处理的会自动退款
      </div>
    </div>
  </a-modal>
</template>

<script setup>
import {postCheckExistCount, postStopByGroupApi} from '@/http/api'
import {computed, ref} from "vue";
import {useStore} from "vuex";

const visible = ref(true);
const stopVisible = ref(false);
const store = useStore();

const intelligentAgentDialog = computed(()=>{
  return store.state.modal.intelligentAgentDialog;
})

const downloadFile =()=>{

}

const confirmStop=()=>{
  postStopByGroupApi({agentTaskId:intelligentAgentDialog.value.agentTaskId}).then((res) => {
    //关闭 等待结果
    stopVisible.value = false
    // closeModal();
  })
}

const cancelStop=()=>{
  stopVisible.value = false;
}

const stopProgress=()=>{
  stopVisible.value = true
}

const closeModal=()=>{
  store.commit("modal/changeKey",{
    intelligentAgentDialog:{

    }
  });
  //查询快照日志
  postCheckExistCount().then((res) => {
    let { startIndex, endIndex, snapshotCount } = res.data.data
    if (snapshotCount > 0) {
      store.commit('optionsBar/changeKey', {
        allowSaveSnapshot: true,
      })
    }
  })
  store.dispatch("getSentence",{loading:true});
}

</script>

<style scoped lang="less">
.term-success {
  text-align: center;
  .term-success-explain {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    height: 22px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .term-success-condition {
    height: 20px;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
  }
  .tip-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 14px 0 10px 0;
  }
  .term-success-tip {
    width: 280px;
    height: 40px;
    background: rgba(0, 198, 243, 0.08);
    border-radius: 4px;
    font-size: 14px;
    color: #0cb5dc;
    line-height: 40px;
    margin-bottom: 8px;
  }
  .term-success-remind {
    color: #999999;
    font-size: 12px;
  }
}
.replace-re {
  .progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .text {
      height: 20px;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .number {
      height: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      margin-top: 8px;
    }
    .progress-number {
    }
    .waiting {
      margin-top: 8px;
      height: 24px;
      font-size: 18px;
      font-weight: 400;
      color: #bbbbbb;
      line-height: 24px;
    }

    .left {
      padding: 16px 20px;
      width: 230px;
      height: 98px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #eeeeee;
    }
    .center_animatemove {
      position: relative;
      animation: mymove 1s infinite;
      @keyframes mymove {
        0% {
          left: -16px;
          opacity: 1;
        }
        25% {
          //left:-10px;
          opacity: 1;
        }
        50% {
          //left:-4px;
          opacity: 1;
        }
        75% {
          //left:4px;
          opacity: 0.6;
        }
        100% {
          left: 10px;
          opacity: 0.2;
        }
      }
      img {
        width: 40px;
      }
    }
    .center {
      //background: red;
    }
    .right {
      padding: 16px 20px;
      width: 230px;
      height: 98px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #eeeeee;
    }
  }

  .result {
    text-align: center;
    margin-bottom: 20px;
    img {
      width: 56px;
      height: 56px;
    }
    .text {
      margin-top: 16px;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      span {
        color: #00c6f3;
      }
    }
  }

  .replace-re-remind {
    height: 36px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
  }
}
</style>
