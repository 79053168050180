<template>
  <a-modal
      :visible="visible"
      title="提示"
      centered
      :width="600"
      wrapClassName="remark-tip-modal"
      :zIndex="99"
      okText="确认"
      cancelText="取消"
      @ok="handleOk"
      @cancel="cancel"
  >
    <div class="content">
      <div class="img">
        <img src="@/images/fail.png" width="48">
      </div>
      <p class="text">您暂无企业术语、语料库库创建权限，修改内容不会自动存储，可导出保存。</p>
    </div>
  </a-modal>
</template>

<script>
import {DownloadOutlined} from '@ant-design/icons-vue'
import {onMounted} from 'vue'
import {useStore} from 'vuex'

export default {
  props: ['visible'],
  components: {
    DownloadOutlined,
  },
  setup(props, ctx) {
    const store = useStore()

    const cancel = () => {
      ctx.emit('update:visible', false)
    }

    const handleOk = () => {
      cancel()
    }

    onMounted(() => {

    })

    return {
      store,
      cancel,
      handleOk
    }
  },
}
</script>

<style lang="less">
.content {
  .img {
    text-align: center;
  }
  .text {
    margin: 0;
    margin-top: 20px;
    text-align: center;
  }
}
</style>
