<template>
  <div class="newSetting">
    <div class="newSetting-tab">
      <div class="title-tab">
        <h4 :class="{ select: tabActive == 1 }" @click="tabChange(1)">
          我的引擎
        </h4>
        <h4 :class="{ select: tabActive == 2 }" @click="tabChange(2)">
          垂直引擎
        </h4>
        <h4 :class="{ select: tabActive == 3 }" @click="tabChange(3)">
          通用引擎
        </h4>
      </div>
      <div class="search-content">
        <!--        <span class="createTerm" @click="createMT">-->
        <!--          <img src="./create.png" />-->
        <!--          创建我的孪生引擎-->
        <!--        </span>-->
        <a-input
          v-model:value="searchContent"
          placeholder="请输入引擎名称"
          style="width: 240px"
          @change="inputChange"
        >
          <template #addonAfter>
            <SearchOutlined @click="closeInput" />
          </template>
        </a-input>
      </div>
    </div>
    <div id="twinslator-scroll">
      <div
        class="list"
        v-for="(item, index) in listTree"
        :key="index"
        :id="`newSetting${index}`"
      >
        <div v-for="(sitem, sindex) in item" :key="sindex">
          <div v-if="!sitem.domain || store.state.settings[sitem.domain]">
            <div class="list-item" v-if="sitem.data?.length || sitem.isShow">
              <div class="title">
                <span class="yuan"></span>
                {{ sitem.title }}
              </div>
              <div class="content">
                <div
                  class="item"
                  @click="checkItem($event,citem)"
                  :class="{
                    isSelected: checkedId == citem.twinslatorId,
                    isDisabled: citem.disable,
                  }"
                  v-for="(citem, cindex) in sitem.data"
                  :key="cindex"
                >
                  <div class="item-title">
                    <span class="name" :title="citem.twinslatorName">
                      {{ citem.twinslatorName }}
                    </span>
                    <a-tooltip title="术语替换：支持机翻同时替换自定术语">
                      <span class="tag" v-if="citem.isSupport">
                        术
                      </span>
                    </a-tooltip>
                    <a-tooltip title="精准样式：支持带上标签进行机翻">
                      <span class="tag" v-if="citem.isPreciseStyle">
                        精
                      </span>
                    </a-tooltip>
                    <EngineTag :item="citem"></EngineTag>
                    <span class="isFavorite" @click.stop="joinFavorite(citem)">
                      <img
                        src="./images/favorite.png"
                        v-if="!citem.isFavorite"
                      />
                      <img
                        src="./images/favorite1.png"
                        v-if="citem.isFavorite"
                      />
                    </span>
                  </div>
                  <div class="item-content" v-if="citem.isOnline">
                    <div class="price">{{ citem.price }}译力/千字</div>
                    <div class="avgScore">
                      <a-rate
                        :value="citem.avgScore"
                        :count="5"
                        size="small"
                        disabled
                        class="rate"
                        allow-half
                        v-if="citem.avgScore"
                      />
                      <span class="score" v-if="citem.avgScore">
                        {{ citem.avgScore }}
                      </span>
                      <span v-else class="font-size-12" style="color:#86909C;">
                        暂无评分
                      </span>
                    </div>
                  </div>
                  <div
                    class="item-content font-size-12"
                    style="color:#86909C;justify-content: flex-start"
                    v-else
                  >
                    引擎创建中，
                    <a
                      style="color:#00BEFA;text-decoration: underline"
                      @click.stop="toUpload(citem)"
                    >
                      点击上传语料
                    </a>
                  </div>
                </div>
                <div
                  class="item justify-center align-center display-flex"
                  style="flex-direction: column"
                  v-if="sitem.isShow"
                  @click="createMT"
                >
                  <PlusCircleOutlined />
                  <div class="font-size-12 margin-t-10" style="color: #4E5969;">
                    创建{{
                      store.state.taskDetail.enterpriseId == 'personal'
                        ? '我的'
                        : '企业'
                    }}的孪生引擎
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {rate} from "ant-design-vue";
import {onBeforeMount, onMounted, ref} from 'vue'
import {favoriteTw, getCheckUserCreateTwinslator, getTwinslators,} from '@/http/api'
import {useStore} from 'vuex'
import {HeartFilled, PlusCircleOutlined, SearchOutlined,} from '@ant-design/icons-vue'
import {useI18n} from 'vue-i18n'
import {message} from 'ant-design-vue'
import EngineTag from "@/components/EngineTag/EngineTag.vue";

export default {
    name: 'newSetting',
    components: {
      EngineTag,
      HeartFilled,
      SearchOutlined,
      PlusCircleOutlined,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      //标签页激活项
      const tabActive = ref(1)
      //语言方向Map
      const languageMap = new Map()
      //行业方向Map
      const industryMap = new Map()
      //选择的twId
      const checkedId = ref('')
      //搜索框内容
      const searchContent = ref('')
      //是否有正在创建
      const isCreated = ref(false)
      //源数据
      const timeout = ref(null)
      //表格数据;

      const listTree = ref({
        1: [
          {
            title: '我收藏的',
            type: 'MY_FAVORITE',
            data: [],
          },
          {
            title: '我创建的',
            type: 'CREATE_TWINSLATOR',
            data: [],
            isShow: true,
            domain: 'engine_show_my_created',
          },
          {
            title: '我共建的',
            type: 'BUILT_TOGETHER',
            data: [],
            domain: 'engine_show_my_together',
          },
        ],
        2: [
          {
            title: '垂直引擎',
            type: 'OFFICIAL_TWINSLATOR',
            data: [],
          },
        ],
        3: [
          {
            title: '通用引擎',
            type: 'BASE_TWINSLATOR',
            data: [],
          },
        ],
      })

      const checkItem = (e,item) => {
        if (item.disable) {
          if (!item.isOnline) {
            //前往添加语料
            toUpload(item);
            e.stopPropagation();
          } else {
            message.warning('当前引擎不可使用')
            return
          }
        } else {
          checkedId.value = item.twinslatorId
        }
      }

      //请求表格数据列表
      const httpGetTwinslators = (type) => {
        let params = {
          industryId: store.state.taskDetail.industryId,
          sourceLangId: store.state.taskDetail.sourceLangId,
          targetLangId: store.state.taskDetail.targetLangId,
          marketType: type,
          pageSize: 1000,
          pageNum: 0,
        }

        getTwinslators(params).then((res) => {
          if (res.data.result == 1) {
            let dataList = res.data.data.content.filter((item) => {
              if (
                searchContent.value &&
                item.twinslatorName.indexOf(searchContent.value) < 0
              ) {
                return false
              } else {
                item.checked = false
                item.languageDirection = '多语种'
                if (item.sourceLangId && item.targetLangId) {
                  item.languageDirection = `${languageMap.get(
                    item.sourceLangId
                  )} - ${languageMap.get(item.targetLangId)}`
                }
                if (item.industryId) {
                  item.industryName = industryMap.get(item.industryId)
                }
                item.industryName = item.industryName || '通用'
                return true
              }
            })

            for (let key in listTree.value) {
              listTree.value[key].forEach((item) => {
                if (item.type == type) {
                  item.data = dataList
                }
              })
            }
            //初次进来时候调用，如果我收藏的没有数据，则跳转到官方推荐里去
          }
        })
      }

      const renderTree = () => {
        for (let valueKey in listTree.value) {
          listTree.value[valueKey].forEach((item) => {
            httpGetTwinslators(item.type)
          })
        }
      }

      const clearCheckInterval = () => {
        clearTimeout(timeout.value)
      }

      const inputChange = () => {
        if (timeout.value) {
          clearCheckInterval()
        }
        timeout.value = setTimeout(() => {
          renderTree()
        }, 1000)
      }

      const openDetail = (item) => {
        window.open(`/go/tw_pc_login/detail?id=${item.twinslatorId}&type=1`)
      }

      const createMT = () => {

        //增加接口层校验
        const newWindow = window

        let url
        if (store.state.taskDetail.industryId) {
          url = `/go/tw_pc_login/create?from=WEBCAT&sourceLangId=${store.state.taskDetail.sourceLangId}&targetLangId=${store.state.taskDetail.targetLangId}&industryId=${store.state.taskDetail.industryId}`
        } else {
          url = `/go/tw_pc_login/create?from=WEBCAT&sourceLangId=${store.state.taskDetail.sourceLangId}&targetLangId=${store.state.taskDetail.targetLangId}`
        }
        newWindow.open(url)

        //打开创建引擎地址
      }

      store.state.industryList.forEach((item) => {
        industryMap.set(item.industryId, item.industryName)
      })

      store.state.languageList.forEach((item) => {
        languageMap.set(item.languageId, item.languageShortName)
      })

      const toUpload = (item) => {
        window.open(`/go/tw_pc/subData?id=${item.twinslatorId}`)
      }

      const joinFavorite = (citem) => {
        if (citem.disable) {
          if (!citem.isOnline) {
            //前往添加语料
            toUpload(citem);
          } else {
            favoriteTw({ twinslatorId: citem.twinslatorId }).then(() => {
              citem.isFavorite = !citem.isFavorite
            })
          }
        } else {
          favoriteTw({ twinslatorId: citem.twinslatorId }).then(() => {
            citem.isFavorite = !citem.isFavorite
          })
        }
      }

      //搜索框失去焦点之后触发过滤 (留着备用)
      const closeInput = () => {
        // console.log('closeInput')
      }

      const getSubmitValue = () => {
        return {
          twinslatorId: checkedId.value,
        }
      }

      //标签页改变
      const tabChange = (value) => {
        tabActive.value = value
        let twinslatorScroll = document.getElementById('twinslator-scroll')

        let height = 0
        for (let i = 1; i < value; i++) {
          let nodeHeight = document.getElementById(`newSetting${i}`)
            .offsetHeight
          height = height + nodeHeight
        }

        twinslatorScroll.scrollTop = height
      }

      onBeforeMount(() => {
        if (store.state.taskDetail.enterpriseId == 'personal') {
        } else {
          listTree.value[1][1].title = '企业创建的'
          listTree.value[1].splice(2, 1)
          getCheckUserCreateTwinslator().then((res) => {
            // console.log(res);
            let result = res.data.data
            if (!result.canCreate) {
              listTree.value[1][1].isShow = false
            }
          })
        }
      })

      onMounted(() => {
        checkedId.value = store.state.taskDetail.newTwinslatorId
        renderTree()
      })

      return {
        checkItem,
        inputChange,
        listTree,
        toUpload,
        isCreated,
        store,
        clearCheckInterval,
        openDetail,
        createMT,
        joinFavorite,
        tabActive,
        checkedId,
        tabChange,
        getSubmitValue,
        closeInput,
        searchContent,
        t,
      }
    },
  }
</script>
<style scoped lang="less">
  .newSetting-tab {
    border-bottom: 1px solid #f2f3f5;
    margin-bottom: 15px;
  }

  #twinslator-scroll {
    max-height: 360px;
    overflow-y: auto;
  }
  .list {
    .title {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #4e5969;
      height: 18px;
      line-height: 18px;
      margin-bottom: 12px;
      .yuan {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #4e5969;
        margin-right: 4px;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .item {
        cursor: pointer;
        width: 236px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
        border: 2px solid #f2f3f5;
        padding: 16px 20px;
        margin-bottom: 16px;
        margin-right: 16px;
        &.isSelected {
          border: 2px solid #00befa;
          position: relative;
          &::before {
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 16px;
            height: 16px;
            content: url('./images/setSelected.png');
            background-size: cover;
          }
        }
        &.isDisabled {
          opacity: 0.5;
          //pointer-events: none;
        }
        .item-title {
          display: flex;
          margin-bottom: 8px;
          align-items: center;
          .name {
            font-size: 14px;
            height: 16px;
            line-height: 16px;
            margin-right: 8px;
            color: #1c2129;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .tag {
            width: 18px;
            height: 18px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #dff3f9;
            color: #3fcbf8;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
          }
          .isFavorite {
            margin-left: auto;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
        .item-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .price {
            flex-shrink: 0;
            height: 16px;
            font-size: 12px;
            color: #86909c;
            line-height: 16px;
          }
          .avgScore {
            text-align: right;
            flex-shrink: 0;
            flex-grow: 1;
            .rate {
              margin-right: 6px;
              transform: scale(0.6);
            }
            .score {
              display: none;
              font-size: 12px;
              color: #ffb55b;
            }
          }
        }
      }
    }
  }
</style>
