<template>
  <a-tooltip>
    <template #title>
      合并句段
    </template>
    <a-button
        class="li"
        type="link"
        style="height: 22px; line-height: 22px;"
        :class="{ disabled: disabled }"
        @mousedown="mergeSentence"
    >
      <a-dropdown>
        <a class="ant-dropdown-link" @click.prevent>
          <icon-font class="font-size-16 margin-r-4" type="icon-hebing"></icon-font>
          <span class="li-txt">合并</span>
          <icon-font class="font-size-12" type="icon-xiala"></icon-font>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item :disabled="store.state.taskDetail.sourceParseType=='RichText'" @click="cancelMerge()">
              <div class="font-size-12 display-flex justify-center align-center">
                <icon-font  class="font-size-16 margin-r-2"  type="icon-quxiaohebing" style="position: relative;top: -1px"></icon-font>取消合并
              </div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </a-button>
  </a-tooltip>
  <a-tooltip>
    <template #title>
      拆分句段
    </template>
    <a-button
        class="li"
        type="link"
        style="height: 22px; line-height: 22px;width: 100%"
        :class="{
        disabled:
          disabled ||
          checkedDetail.locked ||
          !store.state.optionsBar.targetEditFocus,
      }"
        @mousedown="splitSentence"
    >
      <icon-font
          class="font-size-16 margin-r-4"
          type="icon-chaifen"
      ></icon-font>
      <span class="li-txt">拆分</span>
      <!--      <icon-font class="font-size-12" type="icon-xiala" style="visibility: hidden"></icon-font>-->
    </a-button>
  </a-tooltip>

  <a-modal :visible="mergeSentenceModalVisible" centered title="合并弹窗" :width="960" @ok="handleOkMerge"
           okText="确认合并" @cancel="mergeSentenceModalVisible=false">
    <div class="mergeSpitModal">
      <div class="display-flex margin-b-20" style="width: 100%;">
        <div style="flex-basis: 60px;flex-shrink: 0;padding-top: 8px"><b>合并前:</b></div>
        <el-scrollbar  class="scroll-bar" style="flex-grow: 1">
          <div class="mergeSplitTable">
            <div class="mergeSplitTable-th" v-for="(item,index) in checkedList" :key="index">
              <div class="mergeSplitTable-td">
                {{ item.extSentId }}
              </div>
              <div class="mergeSplitTable-td">
                <div>
                  {{transformTimeFunc(item.startTimeMs)}}
                </div>
                <div>
                  {{transformTimeFunc(item.endTimeMs)}}
                </div>
              </div>
              <div class="mergeSplitTable-td target-txt source-language-text-style" v-html="item.sourcePlaceholder">

              </div>
              <div class="mergeSplitTable-td target-txt target-language-text-style" v-html="item.targetPlaceholder">

              </div>
              <div class="mergeSplitTable-td">

                <div v-if="item.srtTextArr">
                  <div v-for="(citem,cindex) in item.srtTextArr" :key="cindex">
                    {{citem}}
                  </div>
                </div>


<!--                <div>-->
<!--                  {{item.srtTextArr?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}}-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="display-flex result-style" style="width: 100%;">
        <div style="flex-basis: 60px;flex-shrink: 0;padding-top: 8px"><b style="color: #00B4FA;">合并后:</b></div>
        <el-scrollbar style="flex-grow: 1;" class="scroll-bar">
          <div class="mergeSplitTable">
            <div class="mergeSplitTable-th">
              <div class="mergeSplitTable-td">
                {{ mergeSentenceInfo.extSentId }}
              </div>
              <div class="mergeSplitTable-td">
                <div>
                  {{transformTimeFunc(mergeSentenceInfo.startTimeMs)}}
                </div>
                <div>
                  {{transformTimeFunc(mergeSentenceInfo.endTimeMs)}}
                </div>
              </div>
              <div class="mergeSplitTable-td target-txt source-language-text-style"
                   v-html="mergeSentenceInfo.sourcePlaceholder">

              </div>
              <div class="mergeSplitTable-td target-txt target-language-text-style"
                   v-html="mergeSentenceInfo.targetPlaceholder">
              </div>
              <div class="mergeSplitTable-td">

                <div v-if="mergeSentenceInfo?.srtTextArr">
                  <div v-for="(citem,cindex) in mergeSentenceInfo.srtTextArr" :key="cindex">
                    {{citem}}
                  </div>
                </div>

<!--                <div>-->
<!--                  {{mergeSentenceInfo.srtTextArr?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}}-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </a-modal>

  <a-modal :visible="cancelMergeSentenceModalVisible" @ok="handleOkCancelMerge" centered title="取消合并" :width="960" okText="确认" @cancel="closeCancelMergeModal" v-if="cancelMergeSentenceModalVisible">
    <div class="mergeSpitModal">
      <div class="display-flex margin-b-20" style="width: 100%;">
        <div style="flex-basis: 60px;flex-shrink: 0;padding-top: 8px"><b>已合并:</b></div >
        <el-scrollbar style="flex-grow: 1;" class="scroll-bar">
          <div class="mergeSplitTable">
            <div class="mergeSplitTable-th">
              <div class="mergeSplitTable-td">
                {{cancelMergeInfo.source.extSentId}}
              </div>
              <div class="mergeSplitTable-td">
                <div>
                  {{transformTimeFunc(cancelMergeInfo.source.startTimeMs)}}
                </div>
                <div>
                  {{transformTimeFunc(cancelMergeInfo.source.endTimeMs)}}
                </div>
              </div>
              <div class="mergeSplitTable-td target-txt source-language-text-style" v-html="cancelMergeInfo.source.sourcePlaceholder">

              </div>
              <div class="mergeSplitTable-td target-txt target-language-text-style" v-html="cancelMergeInfo.source.targetPlaceholder">

              </div>
              <div class="mergeSplitTable-td">

                <div v-if="cancelMergeInfo.source?.srtTextArr">
                  <div v-for="(citem,cindex) in cancelMergeInfo.source.srtTextArr" :key="cindex">
                    {{citem}}
                  </div>
                </div>


<!--                <div>-->
<!--                  {{cancelMergeInfo.source?.srtTextArr?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}}-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="display-flex result-style" style="width: 100%;">
        <div style="flex-basis: 60px;flex-shrink: 0;padding-top: 8px"><b style="color: #00B4FA;">取消后:</b></div>
        <el-scrollbar style="flex-grow: 1;" class="scroll-bar">
          <div class="mergeSplitTable">
            <div class="mergeSplitTable-th" v-for="(item,index) in cancelMergeInfo.result" :key="index">
              <div class="mergeSplitTable-td">
                {{item.extSentId}}
              </div>
              <div class="mergeSplitTable-td">
                <div>
                  {{transformTimeFunc(item.startTimeMs)}}
                </div>
                <div>
                  {{transformTimeFunc(item.endTimeMs)}}
                </div>
              </div>
              <div class="mergeSplitTable-td target-txt source-language-text-style" v-html="item.sourcePlaceholder">

              </div>
              <div class="mergeSplitTable-td target-txt target-language-text-style" v-html="item.targetPlaceholder">

              </div>
              <div class="mergeSplitTable-td">
                <div v-if="item?.srtTextArr">
                  <div v-for="(citem,cindex) in item?.srtTextArr" :key="cindex">
                    {{citem}}
                  </div>
                </div>
<!--                {{item?.srtTextArr?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}}-->
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </a-modal>

  <a-modal :visible="splitTipModalVisible" centered title="提示" @cancel="splitTipModalVisible=false">
    <div>
      <div class="text-center font-size-16">
        <div class="margin-b-20">
          <img src="@/assets/warning_1.png" width="48"/>
        </div>
        <div>
          当前为合并句，拆分后将无法“取消合并”，是否继续拆分？
        </div>
      </div>
      <div class="display-flex font-size-14 justify-center margin-t-20">
        <div class="padding-r-16">
          <div class="margin-b-10">
            <b>拆分：</b>直接拆分成上下两句
          </div>
          <div>
            <img src="./images/split1.png" width="224">
          </div>
        </div>
        <div class="padding-l-16">
          <div class="margin-b-10">
            <b>取消合并：</b>将合并句还原至原位
          </div>
          <div>
            <img src="./images/split2.png" width="224">
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <a-button @click="splitTipModalVisible=false">关闭</a-button>
      <a-button @click="openCancelMerge">取消合并</a-button>
      <a-button type="primary" @click="continueSplit">继续拆分</a-button>
    </template>
  </a-modal>
</template>

<script>
import {computed, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {
  mergeWebcatSentenceV2Api,
  postCancelMergeApi,
  postCancelPreviewMergeApi,
  postPreviewMergeResultApi,
  splitWebcatSentence
} from '@/http/api.ts'
import {message} from 'ant-design-vue'
import filterString from '@/plugins/filterString'
import {assignmentSentenceInformation, isFocusPositionTarget, transformTimeFunc} from '@/plugins/comm.js'
import mitt from "@/plugins/bus";

export default {
  name: 'index.vue',
  props: ['disabled'],
  setup() {
    const {t} = useI18n()
    const store = useStore()
    const taskDetail = computed(() => store.state.taskDetail)
    const checkedDetail = computed(() => {
      return store.state.checkedDetail
    })

    const checkedList = ref([]);

    //获取当前激活项item

    const state = reactive({
      //合并弹窗显示开关
      mergeSentenceModalVisible: false,
      //合并句子信息集合结果
      mergeSentenceInfo: {},
      //拆分合并句的提示
      splitTipModalVisible: false,
      //取消合并句的弹窗显示开关
      cancelMergeSentenceModalVisible: false,
      //拆分句的信息结果
      splitSentenceInfo: {
        nodeSentId: null,
        oneHtml: '',
        twoHtml: ''
      },
      //取消合并信息
      cancelMergeInfo: {
        source: {},
        result: []
      }
      //存变量
    })


    //打开取消合并的弹窗，由拆分句弹窗触发
    const openCancelMerge = () => {
      //关闭拆分弹窗
      state.splitTipModalVisible = false;
      cancelMerge(state.splitSentenceInfo.nodeSentId);
    }

    //弹窗确认取消合并
    const handleOkCancelMerge = () => {
      state.cancelMergeSentenceModalVisible = false;
      // console.log("取消合并")
      store.commit("changeKey", {
        screenLoading: true
      })
      postCancelMergeApi({sentId: state.cancelMergeInfo.source.sentId}).then(res => {
        if (res) {
          closeCancelMergeModal();
          updatePreview(state.cancelMergeInfo.result);
          store.dispatch("getSentence",{loading:true}).then(res => {
            //同步更新译文预览 //同步更新新的选中
            updateCheckDetail(checkedDetail.value)
          })
        } else {
          store.commit("changeKey", {
            screenLoading: false
          })
        }
      })
    }

    //关闭取消合并的弹窗，同步清除信息
    const closeCancelMergeModal = () => {
      state.cancelMergeInfo = {
        source: {},
        result: []
      }
      state.cancelMergeSentenceModalVisible = false;
    }

    //取消合并
    const cancelMerge = (sentId) => {
      //判断条件 1.只能选择单句，2.单句必须有合并标识；3.根据taksDetail 禁用
      if (store.state.taskDetail.sourceParseType == 'RichText') {
        return;
      }

      if (!store.state.taskDetail.allowSplitMerge) {
        if (store.state.taskDetail.isOutSource || store.state.taskDetail.existOrderOut ) {
          message.error('存在外包订单，不支持取消合并');
          return
        }
        message.error('历史文件暂不支持句子的取消合并，可重新上传')
        return false
      }

      let list = JSON.parse(JSON.stringify(store.state.excelProject.checkedList));
      list.sort((a,b)=>Number(a.extSentId) - Number(b.extSentId));
      checkedList.value =list;

      if (!sentId) {
        if (checkedList.value.length > 1) {
          message.warning("不可多句取消合并");
          return
        }
        if (checkedList.value.length == 0) {
          message.warning("请选择要取消合并的句子");
          return;
        }
        if (!checkedList.value[0].isMerged) {
          message.warning("选择的句子非合并句");
          return;
        }
        sentId = checkedList.value[0].sentId;
      }
      let params = {
        sentId: sentId
      }

      postCancelPreviewMergeApi(params).then(res => {
        let result = res.data.data;
        state.cancelMergeInfo.source = result.source;
        state.cancelMergeInfo.source.sourcePlaceholder = window.$assignValueToSrc(state.cancelMergeInfo.source.sourcePlaceholder);
        state.cancelMergeInfo.source.targetPlaceholder = window.$assignValueToSrc(state.cancelMergeInfo.source.targetPlaceholder);
        let dom = document.getElementById("create-html-test-node");
        dom.innerHTML = state.cancelMergeInfo.source.targetPlaceholder;
        assignmentSentenceInformation(state.cancelMergeInfo.source,dom.innerText);
        //过滤值
        // state.cancelMergeInfo.source
        state.cancelMergeInfo.result = result.result;
        state.cancelMergeInfo.result.forEach(item=>{
          item.sourcePlaceholder = window.$assignValueToSrc(item.sourcePlaceholder);
          item.targetPlaceholder = window.$assignValueToSrc(item.targetPlaceholder);
          let dom = document.getElementById("create-html-test-node");
          dom.innerHTML = item.targetPlaceholder;
          assignmentSentenceInformation(item,dom.innerText);
        })
      })
      state.cancelMergeSentenceModalVisible = true;
    }

    //确定拆分
    const continueSplit = () => {
      state.splitTipModalVisible = false;
      let {nodeSentId, oneHtml, twoHtml} = state.splitSentenceInfo;
      store.commit('changeKey', {
        screenLoading: true,
      })
      //根据ArrMap 获取 sentIndex 的值；
      let sentIndex = store.state.excelProject.docData.sentenceDTOList[store.state.excelProject.ArrMap.get(nodeSentId)].sentIndex;
      let params = {
        sentId: nodeSentId,
        sentIndex: sentIndex,
        sourceParseType:store.state.taskDetail.sourceParseType || "RichText",
        sources: [
          filterString.getString(oneHtml),
          filterString.getString(twoHtml),
        ],
      }
      setTimeout(() => {
        splitWebcatSentence(params)
            .then((res) => {
              // console.log(res);
              if (res) {
                store.dispatch('getSentence', {loading: true}).then(res => {
                  updatePreviewSentIndex([sentIndex])
                });
                //拆分需要重新更新译文预览是直接加载句子
              } else {
                store.commit('changeKey', {
                  screenLoading: false,
                })
              }
            })
            .catch((rej) => {
              store.commit('changeKey', {
                screenLoading: false,
              })
            })
      }, 200)
    }

    //占位符拆分结果是否匹配算法 (仅新版执行)
    const compareImgMatch = (list) => {
      //历史项目直接return true
      if (store.state.taskDetail.sourceParseType == 'RichText') {
        return true
      }
      let L = [];
      let R = [];
      let copyL = [];
      let copyR = [];
      list.forEach(item => {
        if (item.id.includes('_') && item.id.includes('rs')) {
          L.push(item.id.split('_')[0]);
        }
        // console.log(item.id.includes('_') && item.id.includes('re'))
        if (item.id.includes('_') && item.id.includes('re')) {
          // console.log(item.id);
          R.push(item.id.split('_')[0]);
        }
      })
      //消消乐算法
      L.forEach((item, index) => {
        let rImgId = item.replace("s", 'e');
        if (R.includes(rImgId)) {
          copyL.push(item);
          // copyR
          copyR.push(R[R.indexOf(rImgId)])
        }
      })

      //比较copyL 和 copyR 的长度
      if (copyL.length != L.length) {
        return false
      }
      if (copyR.length != R.length) {
        return false
      }

      return true
    }

    //拆分句子
    const splitSentence = () => {
      if (!store.state.taskDetail.allowSplitMerge) {
        if (store.state.taskDetail.isOutSource || store.state.taskDetail.existOrderOut ) {
          message.error('存在外包订单，不支持合并拆分');
          return
        }
        message.error('历史文件暂不支持句子的合并拆分，可重新上传')
        return false
      }
      newSplitSentence()
    }

    //新拆分方法
    const newSplitSentence = () => {
      let selection = window.getSelection()
      let node = isFocusPositionTarget();
      // console.log(selection);
      if (node) {
        // console.log(node?.dataset?.sentid);
        let nodeSentId = node.dataset.sentid
        // console.log(nodeSentId);
        if (!nodeSentId) {
          return
        }
        //开始while 判断 光标节点在哪 然后执行
        if (selection.type == 'Caret') {
          // 记录下光标所在的节点
          let selectNode = selection.focusNode
          let selectOffset = selection.focusOffset
          // //开始选中首位和 光标所在节点
          let startNode = window.$getTargetDom(nodeSentId).firstChild
          // let startNode = document.getElementById(
          //     `source-sent-${nodeSentId}`
          // ).firstChild
          let range = document.createRange()
          range.setStart(startNode, 0)
          range.setEnd(selectNode, selectOffset)
          // selection.removeAllRanges()
          // selection.addRange(range)
          //获取HTML
          // let rangeObj = selection.getRangeAt(0);
          let docFragment = range.cloneContents()

          let tempDiv = document.getElementById('create-html-test-node')
          tempDiv.innerHTML = ''
          tempDiv.appendChild(docFragment)
          let oneHtml = tempDiv.innerHTML;
          if (!filterString.getPureString(oneHtml)) {
            message.error('句首或句尾不可拆分哦')
            return true
          }
          // console.log(oneHtml);
          let tempDivImgList = tempDiv.getElementsByTagName("img");

          let compareMatch = compareImgMatch(tempDivImgList);
          //比较算法
          if (!compareMatch) {
            //此处判断占位符数量是否匹配；
            message.error('占位符拆分未匹配，请不要拆散配对的占位符')
            return true
          }
          // selection.removeAllRanges();
          let lastNode = window.$getTargetDom(nodeSentId)
          // let lastNode = document.getElementById(
          //     `source-sent-${nodeSentId}`
          // ).lastChild
          // console.dir(window.$getSourceDom(nodeSentId));
          //如果是标签元素
          // if (lastNode.nodeType == 3) {
          //
          // }
          // while (lastNode.lastChild) {
          //   lastNode = lastNode.lastChild
          // }
          range.setStart(selectNode, selectOffset)
          range.setEnd(lastNode, lastNode.childNodes.length)
          // selection.removeAllRanges()
          // selection.addRange(range)
          let docFragment2 = range.cloneContents()
          // console.log(docFragment2)
          let tempDiv2 = document.getElementById('create-html-test-node')
          tempDiv2.innerHTML = ''
          tempDiv2.appendChild(docFragment2)
          let twoHtml = tempDiv2.innerHTML;

          if (!filterString.getPureString(twoHtml)) {
            message.error('句首或句尾不可拆分哦')
            return true
          }
          let tempDivImgList2 = tempDiv.getElementsByTagName("img");

          let compareMatch2 = compareImgMatch(tempDivImgList2);
          //比较算法
          if (!compareMatch2) {
            //此处判断占位符数量是否匹配；
            message.error('占位符拆分未匹配，请不要拆散配对的占位符')
            return true
          }
          // console.log(oneHtml);
          // console.log(twoHtml);
          selection.removeAllRanges()


          state.splitSentenceInfo = {
            nodeSentId: nodeSentId,
            oneHtml: oneHtml,
            twoHtml: twoHtml
          }


          //获取当前句子信息
          if (store.state.excelProject.docData.sentenceDTOList[store.state.excelProject.ArrMap.get(nodeSentId)].isMerged) {
            state.splitTipModalVisible = true;
            return
          }
          //如果是合并句展示弹窗，如果不是合并句，则直接触发拆分；
          continueSplit();

        }
      } else {
        message.error('请点击要拆分的译文处')
      }
    }




    //确认合并
    const handleOkMerge = () => {
      //还需要加一层校验；合并后预览出现的占位符有可能超过数量
      // state.mergeSentenceInfo.sourcePlaceholder
      //进行占位符重新排序
      let testNode = document.getElementById("create-html-test-node");
      testNode.innerHTML = state.mergeSentenceInfo.sourcePlaceholder;

      //获取所有的imgList 判断是否合并超出长度
      let imgList = testNode.getElementsByTagName('img');
      // console.log(imgList)
      //分成左中右
      let L = [];
      imgList.forEach((item, index) => {
        if (item.id.includes('rs')) {
          L.push(item);
        }
        if (item.id.includes('m') && item.id.includes("_")) {
          L.push(item);
        }
      })
      if (L.length > 30) {
        message.warning("合并后产生的句子中含有的占位符总数超过了30个，不可合并")
        return false
      }


      let sentList = [];
      let sentIds = checkedList.value.map((item, index) => {
        let copyItem = {...item};
        copyItem.targetPlaceholder = "";
        sentList.push(copyItem)
        return item.sentId
      }).join(',')
      let params = {
        sentIds
      }
      state.mergeSentenceModalVisible = false;
      store.commit("changeKey", {
        screenLoading: true,
      })
      setTimeout(() => {
        mergeWebcatSentenceV2Api(params).then(res => {
          if (res) {
            // //同步更新预览
            sentList[sentList.length - 1].targetPlaceholder = state.mergeSentenceInfo.targetPlaceholder;
            // console.log(sentList);
            updatePreview(sentList);
            store.dispatch("getSentence", {loading: true}).then(res => {
              updateCheckDetail(checkedDetail.value);
            })
          } else {
            store.commit("changeKey", {
              screenLoading: false,
            })
          }
        })
      }, 300)
    }

    //新合并
    const verificationMerge = () => {
      let mergeSourcePlaceholder = checkedList.value.map(item => {
        return item.sourcePlaceholder
      }).join("");

      if (filterString.getPureString(mergeSourcePlaceholder).length > 4000) {
        message.error("句段合并超过最大字符数");
        return false
      }

      let docId = null;
      const result = checkedList.value.some(item => {
        return docId && (item.docId != docId)
      })

      if (result) {
        message.error("不可合并多个文档的句子")
        return false
      }

      if (checkedList.value.length<2) {
        message.error("请选择两句及以上的句子进行合并")
        return false
      }

      return true
    }

    //预览合并算法 前端版，已经不需要

    const newMergeSentenceFunc = () => {
      const result = verificationMerge();
      if (!result) {
        return false
      }

      store.commit("changeKey",{
        screenLoading:true
      })
      let sentIds = checkedList.value.map(item=>{
        return item.sentId
      }).join(",");
      postPreviewMergeResultApi({sentIds }).then(res=>{
        state.mergeSentenceInfo = {...res.data.data.result};
        state.mergeSentenceModalVisible = true;
        state.mergeSentenceInfo.sourcePlaceholder = window.$assignValueToSrc(state.mergeSentenceInfo.sourcePlaceholder);
        state.mergeSentenceInfo.targetPlaceholder = window.$assignValueToSrc(state.mergeSentenceInfo.targetPlaceholder);
        let dom = document.getElementById("create-html-test-node");
        dom.innerHTML = state.mergeSentenceInfo.targetPlaceholder;
        assignmentSentenceInformation(state.mergeSentenceInfo,dom.innerText);
      }).finally(()=>{
        store.commit("changeKey",{
          screenLoading:false
        })
      })
    }


    //合并句子给后端判断，前端没有判断的字段。
    const mergeSentence = () => {
      if (!store.state.taskDetail.allowSplitMerge) {
        if (store.state.taskDetail.isOutSource || store.state.taskDetail.existOrderOut ) {
          message.error('存在外包订单，不支持合并拆分');
          return
        }
        message.error('历史文件暂不支持句子的合并拆分，可重新上传')
        return false
      }


      let list = JSON.parse(JSON.stringify(store.state.excelProject.checkedList));
      list.sort((a,b)=>Number(a.extSentId) - Number(b.extSentId));
      checkedList.value =list;
      newMergeSentenceFunc();
    }

    //更新预览
    const updatePreview = (list = []) => {
      //处理list
      let sentenceArr = [...list];
      mitt.emit('wordPreviewRenew', {
        type: 1,
        list: sentenceArr
      })
    }

    const updatePreviewSentIndex = (list = []) => {
      //处理list
      if (list.length == 0) {
        return
      }
      mitt.emit('wordPreviewRenew', {
        type: 2,
        list: list
      })
    }

    const updateCheckDetail = (oldCheckDetail) => {
      let index = -1;
      index = store.state.excelProject.docData.sentenceDTOList.findIndex(item => {
        return item.sentId == oldCheckDetail.sentId;
      })
      if (index > -1) {
        store.commit("excelProject/changeKey", {
          tabActiveIndex: index,
        })
      } else {
        store.commit("excelProject/changeKey", {
          tabActiveIndex: -1,
        })
        store.commit('changeKey', {
          checkedDetail: {},
        })
      }
    }

    onBeforeMount(()=>{

    })

    return {
      transformTimeFunc,
      continueSplit,
      handleOkCancelMerge,
      closeCancelMergeModal,
      openCancelMerge,
      handleOkMerge,
      cancelMerge,
      checkedList,
      ...toRefs(state),
      splitSentence,
      mergeSentence,
      store,
      t,
      checkedDetail,
      taskDetail,
    }
  },
}
</script>

<style scoped lang="less">
//.li-txt {
//  position: relative;
//  top: -1px;
//}
.li {
  display: flex;
  align-items: center;
  padding: 0 !important;

  .li-txt {
    position: relative;
    top: -1px;
  }
}


.mergeSpitModal {
  ::v-deep(.el-scrollbar__wrap) {
    max-height: 180px;
  }

  .scroll-bar{
    padding:12px;
    border-radius: 8px;
    background: #F2F3F5;
  }

  .result-style {
    //.mergeSplitTable {
    //  background: #F6FDFF;
    //  border-left: 1px #00BEFA solid;
    //  border-top: 1px #00BEFA solid;
    //
    //  .mergeSplitTable-th {
    //    .mergeSplitTable-td {
    //      border-right: 1px #00BEFA solid;
    //      border-bottom: 1px #00BEFA solid;
    //    }
    //  }
    //}
    .scroll-bar{
      background: #EAF5FE;
    }
  }

  .mergeSplitTable {
    border-left: 1px #EEEEEE solid;
    border-top: 1px #EEEEEE solid;
    background: white;

    .mergeSplitTable-th {
      display: flex;

      .mergeSplitTable-td {
        border-right: 1px #eee solid;
        border-bottom: 1px #eee solid;
        padding: 8px 10px;
      }

      .mergeSplitTable-td:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 56px;
        flex-shrink: 0;
        padding: 8px 10px;
      }

      .mergeSplitTable-td:nth-child(2) {
        flex-basis: 110px;
        flex-shrink: 0;
        padding: 8px 10px;
      }

      .mergeSplitTable-td:nth-child(3) {
        width: 0;
        flex-grow: 1;
        white-space: pre-wrap;
      }

      .mergeSplitTable-td:nth-child(4) {
        width: 0;
        flex-grow: 1;
        white-space: pre-wrap;
      }
      .mergeSplitTable-td:nth-child(5) {
        flex-basis: 60px;
        flex-shrink: 0;
        padding: 8px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        div{
          text-align: center;
        }
      }
    }
  }
}

</style>
