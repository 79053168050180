<script setup>

</script>

<template>
  <div class="timeAxis thead">
    时间轴
  </div>
</template>

<style scoped lang="less">

</style>