<template>
  <a-modal
    v-model:visible="visible"
    title="润色"
    @ok="closeModal"
    :okText="batchDecorationData.finished ? '好的' : '请等待'"
    :closable="false"
    :maskClosable="false"
    centered
    :width="560"
    :okButtonProps="{
      loading: batchDecorationData.finished == false,
    }"
  >
    <div class="replace-re">
      <!--重译完成 有句子重译-->
      <div class="result" v-if="batchDecorationData.finished">
        <div><img src="./images/success.png" /></div>
        <div class="text">
          已润色完成，润色
          <span>{{ batchDecorationData.finishedNumber }}</span>
          句
        </div>
      </div>
      <!--替换正在进行中-->
      <div class="progress" v-else>
        <div class="left">
          <div class="text">检测涉及润色的句子</div>
          <div>
            <div class="number">
              {{ batchDecorationData.totalNumber || 0 }}
            </div>
            <!--            <div class="progress-number">-->
            <!--              <a-progress :percent="30" strokeColor="#00C6F3" />-->
            <!--            </div>-->
          </div>
        </div>
        <div
          class="center"
          :class="{
            center_animatemove: !batchDecorationData.finished,
          }"
        >
          <img src="./images/loading.png" />
        </div>
        <div class="right">
          <div class="text">已润色句子</div>
          <div>
            <div class="number">
              {{ batchDecorationData.finishedNumber }}/{{
                batchDecorationData.totalNumber
              }}
            </div>
            <div class="progress-number">
              <a-progress
                :percent="
                  parseInt(
                    (batchDecorationData.finishedNumber /
                      batchDecorationData.totalNumber) *
                      100
                  )
                "
                strokeColor="#00C6F3"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="replace-re-remind">
        提醒： 大批量润色可能需要一定时间，请耐心等待。
      </div>
    </div>

    <template #footer>
      <a-button @click="stopProgress" v-if="!batchDecorationData.finished">
        停止润色
      </a-button>
      <a-button
        type="primary"
        :loading="!batchDecorationData.finished"
        @click="closeModal"
      >
        {{ batchDecorationData.finished ? '好的' : '请等待' }}
      </a-button>
    </template>
  </a-modal>

  <a-modal
    v-model:visible="stopVisible"
    @ok="confirmStop"
    @cancel="cancelStop"
    okText="确认停止"
    cancelText="返回"
    centered
    title="提示"
  >
    <div style="text-align: center">
      <div class="margin-b-20">
        <img src="@/assets/warning_1.png" width="56" />
      </div>
      <div style="font-size: 16px;color:#1c2129" class="margin-b-8">
        是否停止润色
      </div>
      <div style="font-size: 14px;color:#4e5969">
        停止后，已润色的译文会填充到译文框，未润色的会自动退款
      </div>
    </div>
  </a-modal>
</template>

<script>
import {postStopTwinsApi} from '@/http/api'

export default {
    name: 'index.vue',
    props: ['batchDecorationData'],
    data: function() {
      return {
        visible: true,
        stopVisible: false,
      }
    },
    methods: {
      confirmStop() {
        postStopTwinsApi().then((res) => {
          //关闭 等待结果
          this.stopVisible = false
          // this.closeModal();
        })
      },
      cancelStop() {
        this.stopVisible = false
      },
      stopProgress() {
        //触发二次弹窗；
        this.stopVisible = true
      },
      getTableActiveItem() {
        const sentenceDTOList = this.$store.state.excelProject.docData
          .sentenceDTOList
        const tabActiveIndex = this.$store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex]
      },
      closeModal() {
        this.$emit('close-modal');
      },
    },
    created() {
      // this.httpGetReplace();
    },
  }
</script>

<style scoped lang="less">
  .term-success {
    text-align: center;
    .term-success-explain {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
      height: 22px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .term-success-condition {
      height: 20px;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .tip-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 14px 0 10px 0;
    }
    .term-success-tip {
      width: 280px;
      height: 40px;
      background: rgba(0, 198, 243, 0.08);
      border-radius: 4px;
      font-size: 14px;
      color: #0cb5dc;
      line-height: 40px;
      margin-bottom: 8px;
    }
    .term-success-remind {
      color: #999999;
      font-size: 12px;
    }
  }
  .replace-re {
    .progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .text {
        height: 20px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      .number {
        height: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
        margin-top: 8px;
      }
      .progress-number {
      }
      .waiting {
        margin-top: 8px;
        height: 24px;
        font-size: 18px;
        font-weight: 400;
        color: #bbbbbb;
        line-height: 24px;
      }

      .left {
        padding: 16px 20px;
        width: 230px;
        height: 98px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #eeeeee;
      }
      .center_animatemove {
        position: relative;
        animation: mymove 1s infinite;
        @keyframes mymove {
          0% {
            left: -16px;
            opacity: 1;
          }
          25% {
            //left:-10px;
            opacity: 1;
          }
          50% {
            //left:-4px;
            opacity: 1;
          }
          75% {
            //left:4px;
            opacity: 0.6;
          }
          100% {
            left: 10px;
            opacity: 0.2;
          }
        }
        img {
          width: 40px;
        }
      }
      .center {
        //background: red;
      }
      .right {
        padding: 16px 20px;
        width: 230px;
        height: 98px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #eeeeee;
      }
    }

    .result {
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 56px;
        height: 56px;
      }
      .text {
        margin-top: 16px;
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        span {
          color: #00c6f3;
        }
      }
    }

    .replace-re-remind {
      height: 36px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
  }
</style>
