<script setup>
import {useStore} from "vuex";

import {computed, onMounted} from "vue";

const props = defineProps(['item','index']);

const store = useStore();
//增加字符计算格式;

const word_setting=computed(()=>{
  return store.state.video.word_setting;
})

const bgColorRender =(textLength)=>{
  // console.log(textLength)
  if (textLength>=word_setting.value.three_level){
    return `global-level-three-bg`
  } else if (textLength>=word_setting.value.two_level) {
    return  `global-level-two-bg`
  } else if (textLength>=word_setting.value.one_level) {
    return  `global-level-one-bg`
  } else {
    return  ``
  }
}
onMounted(()=>{
  //获取字符长度
  // console.log(props.item.targetPlaceholder,'获取字符长度');
  //进行字符转换获取；并进行复制
})
</script>

<template>
<div class="word">
  <div v-if="props.item.srtTextArr?.length">
    <div v-for="(citem,cindex) in props.item.srtTextArr" :key="cindex" class="word-item" :class="bgColorRender(citem)">
      {{citem}}
    </div>
  </div>
  <div v-else>

  </div>
</div>
</template>

<style scoped lang="scss">
.word{
  display: flex;
  justify-content: center;
  align-items: center;
  .word-item{
    min-width:20px;
    height: 20px;
    text-align: center;
    line-height: 16px;
    padding:2px
  }
  .word-item:not(:last-child){
    //border-bottom: 1px solid red;
  }
}
</style>