<script setup>
import {message} from "ant-design-vue";
import {onBeforeMount, reactive, ref} from "vue"
import {transformTimeFunc} from "@/plugins/comm.js";
import {postUpdateSentenceTimeMsApi} from "@/http/api";
import {useStore} from "vuex";

const props = defineProps(['item','index']);
const time = ref('99:00:06,401');
// const timeAxisRegexAll = /^[0-9][0-9]:[0-5][0-9]:[0-5][0-9],[0-9][0,9][0-9]-[0-9][0-9]:[0-5][0-9]:[0-5][0-9],[0-9][0,9][0-9]$/;
const timeAxisRegex =/^(\d{1,2}):([0-5]\d):([0-5]\d),(\d{3})$/;
const store = useStore();

const state = reactive({
  lValue:'',
  rValue:'',
})


//校验时间轴
const checkTimeAxisReg=(value)=>{
  return timeAxisRegex.test(value);
}

//时间轴转化为时间
const transformTimeAxis=(value)=>{
  //更新时间戳；
  let leftValues  = value.split(",")[0];
  let rightValue = value.split(",")[1];
  let leftArrs = leftValues.split(':');
  let leftAllTime = 0;
  let rightAllTime = parseInt(rightValue);
  leftArrs.forEach((item,index)=>{
    if (index===0) {
      leftAllTime = leftAllTime+item*60*60*1000;
    } else if (index===1) {
      leftAllTime = leftAllTime+item*60*1000;
    } else if (index===2) {
      leftAllTime = leftAllTime+item*1000;
    }
  })
  let allTime = leftAllTime + rightAllTime;
  // console.log(allTime);
  return allTime;
}




//时间轴失去焦点事件；
const timeAxisSubmit =()=> {
  console.log(state.lValue,checkTimeAxisReg(state.lValue))
  if (!checkTimeAxisReg(state.lValue)) {
    message.warning(`请提交符合标准格式的起始时间，例如${time.value}`);
    return
  }
  console.log(checkTimeAxisReg(state.rValue))
  if (!checkTimeAxisReg(state.rValue)) {
    message.warning(`请提交符合标准格式的结束时间，例如${time.value}`);
    return
  }
  let startTimeMs = transformTimeAxis(state.lValue);
  let endTimeMs = transformTimeAxis(state.rValue);
  if (startTimeMs>endTimeMs) {
    message.warning("起始时间不能大于结束时间");
    return;
  }

  let data = {
    sentId:props.item.sentId,
    startTimeMs:startTimeMs,
    endTimeMs:endTimeMs
  }
  postUpdateSentenceTimeMsApi(data).then(res=>{
    // console.log(res)
    let resp = res.data.data;
    if (resp.success) {
      props.item.startTimeMs = startTimeMs;
      props.item.endTimeMs = endTimeMs;
      store.dispatch("video/updateCaptionDataTime",{
        sentId:props.item.sentId,
        startTimeMs:startTimeMs,
        endTimeMs:endTimeMs
      })
      message.success("修改成功");
    } else {
      message.warning(resp.msg);
    }
  });



  timeAxisCancel();
  //校验通过调用接口；

}

//时间轴取消，恢复默认值
const timeAxisCancel=()=>{
  props.item.timeAxisEdit=false;
}

const edit=()=>{
  if (props.item.timeAxisEdit) {
    return
  }
  props.item.timeAxisEdit=true;

  //赋值state
  state.lValue = transformTimeFunc(props.item.startTimeMs);
  //赋值state
  state.rValue = transformTimeFunc(props.item.endTimeMs);
}

//监听选择框移动，移动后则恢复当前节点；
onBeforeMount(()=>{

})
</script>

<template>
  <div class="timeAxis">
    <div>{{transformTimeFunc(props.item.startTimeMs)}}</div>
    <div>{{transformTimeFunc(props.item.endTimeMs)}}</div>
    <div class="edit-logo" v-if="store.state.taskDetail.taskClassify == 8">
      <a-popover placement="right" :title="null" v-model:visible="props.item.timeAxisEdit" trigger="click">
        <template #content>
          <div>
            <a-space :size="16" class="margin-b-16">
              <div>
                <div class="font-size-13 margin-b-8">
                  起始：
                </div>
                <div>
                  <a-input v-model:value="state.lValue" style="width: 130px" class="font-size-14"></a-input>
                </div>
              </div>
              <div>
                <div class="font-size-13 margin-b-8">
                  结束：
                </div>
                <div>
                  <a-input v-model:value="state.rValue" style="width: 130px" class="font-size-14"></a-input>
                </div>
              </div>
            </a-space>
            <div class="text-right">
             <a-button class="margin-r-12" @click="timeAxisCancel">取消</a-button>
             <a-button type="primary" @click="timeAxisSubmit">确定</a-button>
            </div>
          </div>
        </template>
        <icon-font type="icon-bianji1" class="font-size-12 color-333" @click="edit"></icon-font>
      </a-popover>
    </div>
  </div>

</template>


<style scoped lang="less">
.timeAxis{
  position:relative;
  border:2px transparent solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding-right: 20px;

  .edit-logo{
    cursor: pointer;
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover{
    .edit-logo{
      visibility: visible;
    }
  }

  ::v-deep(.ant-input){
    font-size: 12px;
    border-color: transparent;
    height: 100%;
    border-width: 0 !important;
    &:focus{
      border-color: transparent;
      box-shadow:0 0 0 0;
    }
    &.ant-input-disabled{
      background: transparent;
      color:#333;
      &:hover{
        border-color: transparent;
      }
    }
  }
}
</style>