<script setup>
import {onBeforeMount, reactive, ref} from "vue";
import {getRemoveDecorateConfigApi, postSaveDecorateConfigApi} from "@/http/api";
import {message} from "ant-design-vue";
import {useStore} from "vuex";


const store = useStore();
const state = reactive({
  editVisible:false,//智能体编辑弹窗
  delVisible:false,//智能体删除弹窗
})

const editParams = ref({

})

const delParams = ref({

})

let okCbFunc =()=>{

}

let cancelCbFunc=()=>{}


const editInit=(params,okCb,cancelCb)=>{
  editParams.value = params;
  if (!editParams.value.description) {
    editParams.value.description = "";
  }
  if (okCb) {
    okCbFunc = okCb
  }
  if (cancelCb) {
    cancelCbFunc = cancelCb;
  }
  state.editVisible = true;
}


const editOk=()=>{
  if (!editParams.value.description.trim()) {
    message.warning("请输入润色要求")
    return
  }
  postSaveDecorateConfigApi({
    id:editParams.value?.id || "",
    description:editParams.value.description.trim(),
  }).then(res=>{
    message.success("保存成功")
    store.dispatch("getDecorateListFunc")
  })
  state.editVisible = false;
  okCbFunc();
}

const editCancel=()=>{
  state.editVisible = false;
  cancelCbFunc();
}




const delOk=()=>{
  //调用删除接口；
  state.delVisible = false;
  getRemoveDecorateConfigApi({decorateId:delParams.value.id}).then(res=>{
    message.success("删除成功")
    store.dispatch("getDecorateListFunc");
  })
  okCbFunc();
}

const delCancel=()=>{
  state.delVisible = false;
  cancelCbFunc();
}



const delInit=(params,okCb,cancelCb)=>{
  delParams.value = params;
  if (okCb) {
    okCbFunc = okCb
  }
  if (cancelCb) {
    cancelCbFunc = cancelCb;
  }
  state.delVisible = true;
}

defineExpose({
  editInit,
  delInit,
})

onBeforeMount(()=>{
  //获取用户存的润色信息；
})

</script>

<template>
  <div>
    <a-modal centered :visible="state.editVisible" title="自定义润色要求" okText="保存" cancelText="取消" @ok="editOk" @cancel="editCancel">
      <div class="runseEditClass">
        <div class="textarea">
          <a-textarea v-model:value="editParams.description" showCount :maxlength="40" placeholder="请输入润色要求"/>
        </div>
      </div>
    </a-modal>

    <a-modal centered :visible="state.delVisible" title="删除" okText="确认删除" cancelText="取消" @ok="delOk" @cancel="delCancel">
      <div class="text-center">
        <div>
          <img src="@/assets/warning_1.png" style="width: 56px;">
        </div>
        <div class="color-333 margin-t-16 margin-b-8 font-size-16">
          是否确定删除自定义润色要求？
        </div>
        <div class="color-999 font-size-14">
          润色要求：{{delParams.description}}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<style scoped lang="less">
.runseEditClass{
  ::v-deep(textarea) {
    font-size: 13px;
    resize: none;
    min-height: 22px;
    border-width: 0 !important;
  }
  ::v-deep(.ant-input-textarea-show-count::after) {
    font-size: 12px;
    color: #bbb;
  }
  ::v-deep(.ant-input) {
    min-height: 49px;
    padding: 0 0 8px 0;
  }
  ::v-deep(.ant-input:focus) {
    border-width: 0 !important;
    box-shadow: 0 0 0 0;
  }
  ::v-deep(.ant-input-textarea-show-count::after) {
    text-align: right;
  }
}
</style>