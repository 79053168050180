<template>
  <a-modal
    v-model:visible="retranslationTermExplain"
    title="术语重译"
    @ok="termHandleOk"
    @cancel="termHandleCancel"
    okText="继续重译"
    :maskClosable="false"
    cancelText="取消"
    centered
  >
    <div class="term-success">
      <div class="term-success-explain">术语重译说明</div>
      <div class="term-success-condition">
        为保障人工处理过的翻译内容不受影响，术语重译范围需满足以下条件：
      </div>
      <div class="tip-box">
        <div class="term-success-tip">1、涉及该术语的句对</div>
        <div class="term-success-tip">2、未人工处理过的机翻译文</div>
      </div>
      <div class="term-success-remind">
        重译可能需要一定时间，文件大小会影响重译时间
      </div>
    </div>
  </a-modal>
  <a-modal
    :visible="termVisible.visible"
    title="术语重译"
    :okText="termVisible.termData.taskStatus != 'Finished' ? '请等待' : '好的'"
    :width="560"
    centered
    @ok="closeModal"
    :closable="false"
    :maskClosable="false"
  >
    <div class="term-re">
      <!--重译完成 有句子重译-->
      <div
        class="result"
        v-if="
          termVisible.termData.taskStatus == 'Finished' &&
            termVisible.termData.retranslateNum > 0
        "
      >
        <div><img src="./images/success.png" /></div>
        <div class="text">
          已重译完成，重译
          <span>{{ termVisible.termData.finishNum }}</span>
          句
        </div>
      </div>
      <!--重译完成 无句子重译-->
      <div
        class="result"
        v-else-if="
          termVisible.termData.taskStatus == 'Finished' &&
            termVisible.termData.retranslateNum == 0
        "
      >
        <div><img src="@/images/fail.png" /></div>
        <div
          class="text"
          v-show="termVisible.termData.errorType == 'EmptyContent'"
        >
          未检测到符合条件的术语句对
        </div>
      </div>
      <!--重译正在进行中-->
      <div class="progress" v-else>
        <div class="left">
          <div class="text">检测涉及术语的句子</div>
          <div>
            <div class="number">
              {{ termVisible.termData.retranslateNum || 0 }}/{{
                termVisible.termData.totalNum || 0
              }}
            </div>
          </div>
        </div>
        <div
          class="center"
          :class="{
            center_animatemove: termVisible.termData.taskStatus != 'Finished',
          }"
        >
          <img src="./images/loading.png" />
        </div>
        <div class="right">
          <div class="text">已重译译文</div>
          <div
            v-if="
              termVisible.termData.taskStatus == 'Running' ||
                termVisible.termData.taskStatus == 'Finished'
            "
          >
            <div class="number">
              {{ termVisible.termData.finishNum }}/{{
                termVisible.termData.retranslateNum
              }}
            </div>
            <div class="progress-number">
              <a-progress
                :percent="
                  parseInt(
                    (termVisible.termData.finishNum /
                      termVisible.termData.retranslateNum) *
                      100
                  )
                "
                strokeColor="#00C6F3"
              />
            </div>
          </div>
          <div class="waiting" v-else>
            等待中...
          </div>
        </div>
      </div>
      <div class="term-re-remind">
        提醒：
        <br />
        1、为保障人工处理过的翻译内容不受影响，术语重译范围需满足以下条件：涉及该术语的句对，且未人工处理过的机翻译文；
        <br />
        2、重译可能需要一定时间，请耐心等待。
      </div>
    </div>
    <template #footer>
      <a-button
        @click="stopProgress"
        v-if="termVisible.termData.taskStatus != 'Finished'"
      >
        停止重译
      </a-button>
      <a-button
        type="primary"
        :loading="termVisible.termData.taskStatus != 'Finished'"
        @click="closeModal"
      >
        {{ termVisible.termData.taskStatus == 'Finished' ? '好的' : '请等待' }}
      </a-button>
    </template>
  </a-modal>

  <a-modal
    v-model:visible="stopVisible"
    @ok="confirmStop"
    @cancel="cancelStop"
    okText="确认停止"
    cancelText="返回"
    centered
  >
    <div style="text-align: center">
      <div class="margin-b-20">
        <img src="@/assets/warning_1.png" width="56" />
      </div>
      <div style="font-size: 16px;color:#1c2129" class="margin-b-8">
        是否停止重译
      </div>
      <div style="font-size: 14px;color:#4e5969">
        停止后，已重译的译文会填充到译文框，未润色的会自动退款
      </div>
    </div>
  </a-modal>
</template>

<script>
//这是术语重译全局弹窗
import {postStopTwinsApi, postTermRetranslate, searchCheckSentences,} from '@/http/api'
import mitt from '@/plugins/bus'

export default {
    name: 'index.vue',
    emits: ['termvisiblechange'],
    props: {
      termVisible: {
        type: Object,
        default: function() {
          return {
            visible: false,
            loading: false,
            termData: {},
          }
        },
      },
    },
    data: function() {
      return {
        stopVisible: false,
      }
    },
    computed: {
      retranslationTermExplain: {
        get: function() {
          return this.$store.state.modal.retranslationTermExplain.visible
        },
        set: function(newValue) {
          if (newValue == false) {
            this.$store.commit('modal/changeKey', {
              retranslationTermExplain: {
                visible: false,
                termSource: '',
                termTarget: '',
              },
            })
          }
        },
      },
    },
    methods: {
      confirmStop() {
        postStopTwinsApi().then((res) => {
          //关闭 等待结果
          this.stopVisible = false
          // this.closeModal();
        })
      },
      cancelStop() {
        this.stopVisible = false
      },
      stopProgress() {
        //触发二次弹窗；
        this.stopVisible = true
      },
      closeModal() {
        this.$emit('termvisiblechange', false)
        this.$store.dispatch('getSentence')
      },
      termHandleCancel() {
        // 1;
      },
      startRe(sentIds) {
        let params = {
          docId: this.$store.state.taskDetail.docId,
          sourceText: this.$store.state.modal.retranslationTermExplain
            .termSource,
          targetText: this.$store.state.modal.retranslationTermExplain
            .termTarget,
          sentIds: sentIds.join(","),
        }
        postTermRetranslate(params)
          .then((res) => {
            if (res.data.result == 1) {
              this.$emit('termvisiblechange', true)
            }
          })
          .finally(() => {
            this.retranslationTermExplain = false
          })
      },
      termHandleOk() {
        // 开始调用接口 成功继续 ，失败提示
        let params = {
          docId: this.$store.state.taskDetail.docId,
          sourceText: this.$store.state.modal.retranslationTermExplain
            .termSource,
          targetText: this.$store.state.modal.retranslationTermExplain
            .termTarget,
        }
        searchCheckSentences(params).then((res) => {
          let sentIds = res.data.data.sentIds
          if (sentIds?.length) {
            let thats = this
            mitt.emit('openCostModal', {
              costType: 'TermReTranslate',
              sentIds: sentIds,
              ok: (e) => {
                thats.startRe(sentIds)
              },
            })
          } else {
            this.$message.warning('暂无需要重译的句子')
          }
        })
      },
    },
    created() {},
    beforeUnmount() {},
  }
</script>

<style scoped lang="less">
  .term-success {
    text-align: center;
    .term-success-explain {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
      height: 22px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .term-success-condition {
      height: 20px;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .tip-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 14px 0 10px 0;
    }
    .term-success-tip {
      width: 280px;
      height: 40px;
      background: rgba(0, 198, 243, 0.08);
      border-radius: 4px;
      font-size: 14px;
      color: #0cb5dc;
      line-height: 40px;
      margin-bottom: 8px;
    }
    .term-success-remind {
      color: #999999;
      font-size: 12px;
    }
  }
  .term-re {
    .progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .text {
        height: 20px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      .number {
        height: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
        margin-top: 8px;
      }
      .progress-number {
      }
      .waiting {
        margin-top: 8px;
        height: 24px;
        font-size: 18px;
        font-weight: 400;
        color: #bbbbbb;
        line-height: 24px;
      }

      .left {
        padding: 16px 20px;
        width: 230px;
        height: 98px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #eeeeee;
      }
      .center_animatemove {
        position: relative;
        animation: mymove 1s infinite;
        @keyframes mymove {
          0% {
            left: -16px;
            opacity: 1;
          }
          25% {
            //left:-10px;
            opacity: 1;
          }
          50% {
            //left:-4px;
            opacity: 1;
          }
          75% {
            //left:4px;
            opacity: 0.6;
          }
          100% {
            left: 10px;
            opacity: 0.2;
          }
        }
        img {
          width: 40px;
        }
      }
      .center {
        //background: red;
      }
      .right {
        padding: 16px 20px;
        width: 230px;
        height: 98px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #eeeeee;
      }
    }

    .result {
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 56px;
        height: 56px;
      }
      .text {
        margin-top: 16px;
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        span {
          color: #00c6f3;
        }
      }
    }

    .term-re-remind {
      height: 72px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
  }
</style>
