<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const props = defineProps(['item','index']);
const store = useStore();


const wordSec_setting = computed(()=>{
  return store.state.video.wordSec_setting;
})

const bgColorRender =(textLength)=>{
  // console.log(textLength)
  if (textLength>=wordSec_setting.value.three_level){
    return `global-level-three-bg`
  } else if (textLength>=wordSec_setting.value.two_level) {
    return  `global-level-two-bg`
  } else if (textLength>=wordSec_setting.value.one_level) {
    return  `global-level-one-bg`
  } else {
    return  ``
  }
}

</script>

<template>
<div class="word-sec">
  <span :class="bgColorRender(props.item.srcTextSecond)" v-if="props.item.srtTextArr?.length">
    {{props.item.srcTextSecond}}
  </span>
</div>
</template>

<style scoped lang="scss">
.word-sec{
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    text-align: center;
    line-height: 16px;
    min-width: 20px;
    height: 20px;
    padding:2px;
  }
}
</style>