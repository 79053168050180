<template>
  <a-button
    class="li"
    type="link"
    :class="{
      disabled: disabled || (checkedDetail.locked && checkedList.length == 0),
    }"
    @click="clearSelectedTargetText"
  >
    <icon-font class="font-size-16 icon-box" type="icon-qingkong"></icon-font>
    <div class="li-txt">
      清空
    </div>
  </a-button>
  <a-modal
    v-model:visible="visible"
    title="提示"
    okText="确认清空"
    cancelText="取消"
    centered
    @ok="clearOk"
  >
    <div class="modal">
      <div class="title">
        <img src="@/assets/warning_1.png" />
      </div>
      <div class="content">
        已勾选
        <span class="color-F55C3F">{{ checkedList?.length }}</span>
        个句段，确认清空译文？
      </div>
      <div class="footer color-F55C3F">
        已选句段的译文内容将被清空，谨慎操作
      </div>
      <saveSnapshotCheck
        v-model:saveSnapshotValue="saveSnapshotValue"
      ></saveSnapshotCheck>
    </div>
  </a-modal>
</template>

<script>
import {computed, reactive, toRefs} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {clearTranslationTarget} from '@/http/api'
import {DownOutlined} from '@ant-design/icons-vue'
import saveSnapshotCheck from '@/components/saveSnapshotCheck/index.vue'

export default {
    name: 'index.vue',
    components: {
      saveSnapshotCheck,
      DownOutlined,
    },
    props: ['disabled'],
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail = computed(() => store.state.taskDetail)
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })

      const state = reactive({
        visible: false,
        saveSnapshotValue: false,
      })

      const checkedList = computed(() => store.state.excelProject.checkedList)

      const initCheckedList = () => {
        store.commit('excelProject/changeKey', {
          checkedAll: false,
          checkedList: [],
        })
      }

      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex]
      }

      const clearOk = () => {
        state.visible = false
        httpClearTranslationTarget()
      }
      //model 代表批量 or 单句
      const httpClearTranslationTarget = () => {
        const item = getTableActiveItem()
        let itemArr = []
        itemArr.push(item)
        let model = 'single'
        if (checkedList.value.length > 0) {
          model = 'batch'
          itemArr = []
          checkedList.value.forEach((citem) => {
            itemArr.push(citem)
          })
        }
        let param = {
          sentIds: itemArr
            .map((item, index) => {
              return item.sentId
            })
            .join(','),
          saveSnapshot: model == 'batch' ? state.saveSnapshotValue : false,
        }
        clearTranslationTarget(param).then(() => {
          if (state.saveSnapshotValue) {
            //更改快照信息
            store.commit('optionsBar/changeKey', {
              allowSaveSnapshot: true,
            })
          }

          let sentenceArr = itemArr.map((item) => {
            let $storeExcelProject = store.state.excelProject
            let $List = $storeExcelProject.docData.sentenceDTOList
            let Index = $storeExcelProject.ArrMap.get(item.sentId)
            store.dispatch('updateTargetOrStatus', {
              type: 'Clear',
              targetHTML: '',
              sentId: item.sentId,
            })
            return {
              docId: item.docId,
              sentId: item.sentId,
              targetPlaceholder: '',
              sentIndex: item.sentIndex,
              sourcePlaceholder: item.sourcePlaceholder,
            }
          })
          store.dispatch('wordPreviewRenew', sentenceArr)
          initCheckedList()
        })
      }
      const clearSelectedTargetText = () => {
        const item = getTableActiveItem()
        let itemArr = []
        itemArr.push(item)
        let param = {
          sentIds: item.sentId.toString(),
        }
        if (checkedList.value.length > 0) {
          //出二次确认弹窗
          itemArr = []
          let indexs = checkedList.value
            .map((citem) => {
              itemArr.push(citem)
              return citem.sentId
            })
            .toString()
          param.sentIds = indexs
          state.visible = true
        } else {
          httpClearTranslationTarget()
        }
      }

      return {
        clearOk,
        ...toRefs(state),
        clearSelectedTargetText,
        checkedList,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
</script>

<style scoped lang="less">
  .clearTargetText {
    border: 1px #dddddd solid;
    border-radius: 4px;
    background: white;
    //padding: 4px 0;
    div {
      font-size: 12px;
      color: #333;
      border-radius: 4px;
      padding: 4px;
      &:hover {
        background: #f3f4f7;
        cursor: pointer;
      }
    }
  }

  .modal {
    text-align: center;
    .title {
      img {
        width: 56px;
      }
    }
    .content {
      color: #1c2129;
      line-height: 22px;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    .footer {
      color: #4e5969;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }
</style>
