<script>
import {
  toRefs,
  ref,
  watch,
  computed,
  onMounted,
  nextTick,
  getCurrentInstance,
  reactive,
} from 'vue'
import {message} from "ant-design-vue";
import useProject from "@/components/source/useProject";
import useUtils from "@/plugins/utils";
import {useStore} from "vuex"
import useTis from "@/components/right_toolbar/tabs/useTip";
import EditTermModal from '@/components/right_toolbar/tabs/modal/editTerm.vue'

export default {
  components: {
    EditTermModal
  },
  setup() {
    const {
      taskDetail,
      docData,
      checkedList,
      checkedDetail,
      initKeyQueue,
      targetMousedown,
      getRangeText,
    } = useProject()

    const {setFocusEnd} = useUtils()
    const store = useStore();
    const {del, apply} = useTis()
    const checkRule = computed(() => store.state.rightToolbar.checkRule)
    const state = reactive({
      translateX: 0,
      translateY: 0,
      isHidden: true,
      termTranslateX: 0,
      termTranslateY: 0,
      termIsHidden: true,
      termData: {
        sentId: '',
        list: [],
      },
      qaData: {
        sentId: '',
        list: [],
        qaId: '',
        qaInfo: {
          checkRule: [],
        },
      },
      //编辑术语弹窗
      editTermVisible: false,
      delVisible: false,
      //术语详情
      termDetail: {},
    })


    const onFloatTipTermMouseOver = () => {
      state.termIsHidden = false
    }
    const onFloatTipTermMouseOut = () => {
      state.termIsHidden = true
    }

    const onFloatTipQaMouseOver = () => {
      state.isHidden = false
    }

    const onFloatTipQaMouseOut = () => {
      state.isHidden = true
    }

    const floatTipMouseDown = (type, key = 0) => {
      //预览模式屏蔽
      if (store.getters.$isPreviewMode) {
        return
      }
      if (type == 'qa') {
        let sentId = state.qaData.sentId
        let sentIndex = store.state.excelProject.ArrMap.get(sentId)
        let sentItem =
            store.state.excelProject.docData.sentenceDTOList[
                store.state.excelProject.ArrMap.get(sentId)
                ]
        targetMousedown(sentItem, sentIndex)

        let sentenceItem =
            store.state.excelProject.docData.sentenceDTOList[
                store.state.excelProject.ArrMap.get(state.qaData.sentId)
                ]
        if (sentenceItem && sentenceItem.locked) {
          message.warning('锁定句禁止做任何操作')
          return
        }
        //获取QAClass // 这种不需要做请求接口操作 提交会自动操作。
        let domList = document.getElementsByClassName(state.qaData.qaInfo.id)
        for (let i = 0; i < domList.length; i++) {
          let dom = domList[0]
          store.commit('excelProject/changeKey', {
            keyQueue: 'stop',
          })
          //3002首字母大写 需要前端做，防止清除占位符
          if (state.qaData.qaInfo.errorCode == '3002') {
            let originalStr = dom.innerHTML;
            let replaceStr = originalStr.substring(0, 1).toUpperCase();
            dom.innerHTML = replaceStr + originalStr.slice(1);
            // originalStr
            // dom.innerHTML = originalStr.substring(0, 1) + originalStr.slice(1).replace(/.*/, replacementStr);
          } else {
            // console.log(key);
            dom.innerHTML = state.qaData.qaInfo.suggestionsList[key]
          }
          setTimeout(() => {
            store.commit('excelProject/changeKey', {
              keyQueue: '',
            })
            setFocusEnd(dom, true)
          }, 200)
        }

        state.isHidden = true
      }
    }

    const ignoreQAError = (item) => {
      // item.
      state.isHidden = true
      store.dispatch('rightToolbar/ignoreQAError', {qaItem: item})
    }

    const allTargetIgnore = (item) => {
      //需要传参
      state.isHidden = true
      store.dispatch('rightToolbar/allIgnore', {qaItem: item})
    }

    const applyTerm = (titem) => {
      apply(titem, 'insert')
    }

    const editTerm = (titem) => {
      // console.log(titem);
      // debugger
      if (!titem.canEdit) {
        message.error('您暂无权编辑该词条，请联系项目管理员')
        return
      }
      //打开编辑术语弹窗 传递参数
      state.termDetail = {...titem}
      // setTimeout(())
      state.editTermVisible = true
    }


    const onMouseLeave = (e) => {
      // console.log(2)
      // if (e.target.className.indexOf("qaError")<0) {
      //   state.isHidden = true;
      // }
      state.isHidden = true
      state.termIsHidden = true
      // state.termIsHidden = true;
      // state.isHidden = true;
      clearTimeout(mouseOverTimer)
    }

    let mouseOverTimer = null
    const onMouseOver = (events) => {
      let e = events;
      // mouseOverTimer
      let bool = typeof e.target.className === 'string';
      if (!bool) {
        return
      }

      // console.log("查看执行次数");
      //判断父级节点；需要找是否在处于父级节点之上；

      //while 循环满足这个条件 则不跳出循环
      // while (e.target.className?.includes('qa-node-position') && e.target.className?.indexOf('qaError')<0 || e.target.className?.indexOf('f-term')<0) {
      //
      // }
      let node = e.target;
      while (node && !node.className?.includes('qaError') && !node.className?.includes('f-term')) {
        if (node.parentNode) {
          node = node.parentNode
        } else {
          node = null
        }
      }

      if (!node) {
        return false;
      }
      e = {};
      e.target = node;

      //如果className 不存在，则return
      if (!e.target.className) {
        return false
      }
      // console.dir(e.target);
      //新版优化检测

      //说明是译文QA
      if (e.target.className?.indexOf('qaError') > -1) {
        if (mouseOverTimer) {
          clearTimeout(mouseOverTimer)
        }
        mouseOverTimer = setTimeout(() => {
          let result = e.target.getBoundingClientRect()
          // console.log(result.x)
          state.translateX = Math.ceil(result.x)
          state.translateY = Math.ceil(result.y + result.height - 4)
          //处理QA 需要找原文 或者 译文上面，译文上面就是id 原文上面就是绑定在class上面
          let classList = e.target.classList
          // classList
          for (let i = 0; i < classList.length; i++) {
            let classItem = classList[i]
            if (!classItem.includes('qaError')) {
              //说明是id项
              state.qaData.qaId = classItem
              break
            }
          }

          let node = e.target
          while (node && node.className.indexOf('qa-node-position') < 0) {
            if (node.parentNode) {
              node = node.parentNode
            } else {
              node = null
            }
          }
          // console.log(state.qaData.qaId);

          // console.dir(e.target);
          if (node.className.indexOf('qa-node-position') > -1) {
            //找出sentid 数据
            let sentId = node.dataset.sentid
            state.qaData.sentId = sentId
            state.qaData.list = []
            //查找QA数据；
            let sentItem =
                store.state.excelProject.docData.sentenceDTOList[
                    store.state.excelProject.ArrMap.get(sentId)
                    ]
            //QA数据已经查找出来了 //进行赋值
            // state.qaData.list = sentItem.
            let findItem = sentItem.qaInfoList.find((item) => {
              return item.id == state.qaData.qaId
            })

            findItem = findItem || {
              checkRule: [],
            }
            state.qaData.qaInfo = findItem
          }
          state.isHidden = false
        }, 100)
      }

      if (e.target.className?.indexOf('f-term') > -1) {
        //同时需要存储sentId；
        if (mouseOverTimer) {
          clearTimeout(mouseOverTimer)
        }
        mouseOverTimer = setTimeout(() => {
          state.termData.sentId = ''
          state.termData.list = []

          let result = e.target.getBoundingClientRect()

          state.termTranslateX = Math.ceil(result.x)
          state.termTranslateY = Math.ceil(result.y + result.height - 4)

          let node = e.target
          while (node && node.className.indexOf('qa-node-position') < 0) {
            if (node.parentNode) {
              node = node.parentNode
            } else {
              node = null
            }
          }
          // console.dir(e.target);
          if (node.className.indexOf('qa-node-position') > -1) {
            //找出sentid 数据
            let sentId = node.dataset.sentid
            state.termData.sentId = sentId
            //获取列表数据
            let sentItem =
                store.state.excelProject.docData.sentenceDTOList[
                    store.state.excelProject.ArrMap.get(sentId)
                    ]
            //有可能有多个
            let ids = e.target.dataset.id.split(',')
            ids.forEach((item) => {
              state.termData.list.push(
                  sentItem.matchedTerm.find((citem) => {
                    return item == citem.id
                  })
              )
            })
            // state.termData = sentItem.matchedTerm.filter(item=>{
            //   return item.id == e.target.dataset.id
            // })
            // console.log(state.termData);
          }
          state.termIsHidden = false
          // 获取术语Id 同步查找父级节点的sentId 寻找数据
        }, 100)
      }
    }


    return {
      editTerm,
      store,
      onMouseLeave,
      onMouseOver,
      applyTerm,
      allTargetIgnore,
      ignoreQAError,
      checkRule,
      floatTipMouseDown,
      onFloatTipTermMouseOver,
      onFloatTipTermMouseOut,
      onFloatTipQaMouseOver,
      onFloatTipQaMouseOut,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div
      class="edit-table-float-tip"
      v-show="!isHidden"
      :style="{ transform: `translate(${translateX}px,${translateY}px)` }"
      @mouseover.stop="onFloatTipQaMouseOver"
      @mouseout.stop="onFloatTipQaMouseOut"
  >
    <div class="float-tip float-tip-qa">
      <div class="title">
        <span class="round"></span>
        <span>{{ checkRule[qaData.qaInfo?.checkRule] }}</span>
      </div>

      <div
          class="content"
          v-if="
              qaData.qaInfo?.checkRule == 'RULE_LOWE_CHECK' &&
                qaData.qaInfo.errorType == 'RemoveExtraSpace'
            "
      >
        <button
            class="design-apply-button float-tip-dropdown-button"
            @mousedown.stop="floatTipMouseDown('qa')"
        >
          删除多余空格
          <div class="float-tip-tooltip">
            <button
                class="design-apply-text-button float-tip-tooltip-text"
                @mousedown.stop="floatTipMouseDown('qa')"
            >
              点击应用
            </button>
          </div>
        </button>
      </div>

      <div
          class="content"
          v-else-if="
              qaData.qaInfo?.checkRule == 'RULE_LOWE_CHECK' &&
                qaData.qaInfo.errorType == 'UpperFirstLetter'
            "
      >
        <button
            class="design-apply-button float-tip-dropdown-button"
            @mousedown.stop="floatTipMouseDown('qa')"
        >
          首字母大写
          <div class="float-tip-tooltip">
            <button
                class="design-apply-text-button float-tip-tooltip-text"
                @mousedown.stop="floatTipMouseDown('qa')"
            >
              点击应用
            </button>
          </div>
        </button>
      </div>

      <div
          class="content"
          v-else-if="qaData.qaInfo.suggestionsList?.length"
      >
        <button
            class="design-apply-button float-tip-dropdown-button"
            @mousedown.stop="floatTipMouseDown('qa')"
        >
          <span :class="{'target-language-text-style':qaData.qaInfo.checkRule == 'SPELL_CHECK'}">{{
              qaData.qaInfo.suggestionsList[0] }}</span>
          <icon-font
              type="icon-xiala"
              style="color:#4E5969;cursor: pointer"
              v-if="qaData.qaInfo.suggestionsList?.length > 1"
          ></icon-font>
          <div class="float-tip-tooltip">
            <button
                class="design-apply-text-button float-tip-tooltip-text"
                @mousedown.stop="floatTipMouseDown('qa')"
            >
              点击替换
            </button>
          </div>
          <div
              class="float-tip-dropdown"
              v-if="qaData.qaInfo.suggestionsList?.length > 1"
          >
            <ul class="float-tip-dropdown-ul">
              <li
                  v-for="(citem, cindex) in qaData.qaInfo.suggestionsList"
                  :key="cindex"
              >
                <button
                    class="design-apply-text-button font-size-14"
                    @mousedown.stop="floatTipMouseDown('qa', cindex)"
                    :class="{'target-language-text-style':qaData.qaInfo.checkRule == 'SPELL_CHECK'}"
                >
                  {{ citem }}
                </button>
              </li>
            </ul>
          </div>
        </button>
      </div>

      <div class="content" v-else>
        <span
            v-if="qaData.qaInfo?.checkRule == 'FORMAT_CHECK'"
            v-html="$assignValueToSrc(qaData.qaInfo?.descriptions)"
            class="description target-txt target-language-text-style"
        ></span>
        <span v-else v-html="qaData.qaInfo?.descriptions" class="description"></span>
      </div>

      <div class="footer text-left" v-if="!store.getters.$isPreviewMode">
        <span class="text" @click="ignoreQAError(qaData.qaInfo)">
          忽略
        </span>
        <span
            class="text"
            @click="allTargetIgnore(qaData.qaInfo)"
            v-if="qaData.qaInfo?.checkRule != 'TERM_CONSISTENCY_CHECK' && qaData.qaInfo?.checkRule != 'FORMAT_CHECK'"
        >
            全部忽略
        </span>
      </div>
    </div>
  </div>
  <div
      class="edit-table-float-tip edit-table-float-tip-term"
      :style="{
          transform: `translate(${termTranslateX}px,${termTranslateY}px)`,
        }"
      v-show="!termIsHidden"
      @mouseover.stop="onFloatTipTermMouseOver"
      @mouseout="onFloatTipTermMouseOut"
  >
    <div class="float-tip float-tip-term">
      <div class="padding-tb-6"></div>
      <div class="content term-list">
        <button
            class="design-apply-text-button float-tip-dropdown-button term-list-item"
            v-for="(titem, tindex) in termData.list"
            :key="tindex"
            @mousedown="applyTerm(titem)"
        >
          <span class="term-target">{{ titem.target }}</span>
          <icon-font
              type="icon-bianji"
              class="term-edit"
              @click.stop="editTerm(titem)"
          ></icon-font>
          <span class="float-tip-tooltip">
            <button
                class="design-apply-text-button float-tip-tooltip-text"
                @mousedown="applyTerm(titem)"
            >
              点击插入光标处
            </button>
          </span>
          <!--              <div class="float-tip-dropdown" v-show="termData.length">-->
          <!--                <ul-->
          <!--                  class="float-tip-dropdown-ul"-->
          <!--                  v-for="(titem, tindex) in termData"-->
          <!--                  :key="tindex"-->
          <!--                >-->
          <!--                  <li>-->
          <!--                    <button class="design-apply-text-button font-size-14">-->
          <!--                      {{ titem.target }}-->
          <!--                    </button>-->
          <!--                  </li>-->
          <!--                </ul>-->
          <!--              </div>-->
        </button>
      </div>
      <!--          <div class="footer text-right">-->
      <!--            <span class="text">-->
      <!--              修改-->
      <!--            </span>-->
      <!--          </div>-->
    </div>
    <EditTermModal
        v-if="editTermVisible"
        v-model:visible="editTermVisible"
        v-model:termDetail="termDetail"
        v-model:delVisible="delVisible"
    >

    </EditTermModal>
  </div>
</template>

<style scoped lang="less">
.term-list {
  .term-list-item {
    &:hover {
      .term-target {
        background: #f0f8ff;
        color: #00b4fa;
      }

      .term-edit {
        visibility: visible;
      }
    }

    .term-target {
      padding: 6px 12px;
      color: #1c2129;
      font-size: 14px;
      border-radius: 16px;
    }

    .term-edit {
      visibility: hidden;
      margin-left: 12px;
    }
  }
}
</style>