
import recommendSetting from './recommendSetting.vue'
import {onBeforeMount, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {updateTaskLibrary} from '@/http/api'
import {useI18n} from 'vue-i18n' //国际化
import newSetting from './newSetting.vue';
import termRepository from './termRepository.vue';
import selectCorpus from './selectCorpus.vue';
import twTable from "./twTermCorpusTable/table.vue"
import {Modal} from 'ant-design-vue';

export default {
    props: {
      visible: {
        type: Boolean,
        default: true,
      },
      propsType: {
        type: Number,
        default: 1,
      },
    },
    components: {
      twTable,
      recommendSetting,
      newSetting,
      termRepository,
      selectCorpus,
    },
    setup(props: any, ctx: any) {
      const store = useStore()
      // eslint-disable-next-line vue/no-setup-props-destructure
      const { propsType } = props
      const { t } = useI18n()
      const activeKey = ref(1)
      const sonTermRepository = ref(null)
      const sonNewSetting = ref(null)
      const sonSelectCorpus = ref(null)
      const sonRecommendSetting = ref(null)
      const guide = ref(false)
      const settingType = ref(1)
      const isRecommend = ref(true)
      const saveType = ref(1)

      if (store.state.taskDetail.mtId) {
        isRecommend.value = false
        settingType.value = 2
      }
      const cancel = () => {
        sonNewSetting.value && sonNewSetting.value.clearCheckInterval()
        sonTermRepository.value.clearCheckInterval()
        sonSelectCorpus.value.clearCheckInterval()
        ctx.emit('update:visible', false)
      }

      const tabsChange = (e: any) => {
        if (e.target.nodeName == 'LI') {
          activeKey.value = e.target.getAttribute('data-key')
        }
      }

      const showConfirm = () => {
        Modal.confirm({
          title: '提示',
          content: '请设置术语存储库，未设置存储库无法添加术语',
          okText: '确认保存',
          cancelText: '关闭',
          centered: true,
          onOk() {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleOk(2)
          },
        })
      }

      const confirmOk = (type = 1) => {
        saveType.value = type
        handleOk()
      }

      const handleOk = async (type: any = 1) => {
        //父子组件通信新解决办法 ref操作；
        let { twinslatorId } =
          settingType.value == 1
            ? sonRecommendSetting.value.getSubmitValue()
            : (sonNewSetting.value as any).getSubmitValue()
        let {
          termMasterLibIds,
          termReferLibIds,
        } = (sonTermRepository.value as any).getSubmitValue()
        let {
          corpusReferLibIds,
          corpusMasterLibIds,
        } = (sonSelectCorpus.value as any).getSubmitValue()

        if (type == 1 && !termMasterLibIds) {
          showConfirm()
          return
        }

        const params = {
          twinslatorId: twinslatorId,
          taskId: store.state.taskDetail.taskId,
          termMasterLibIds: termMasterLibIds,
          termReferLibIds: termReferLibIds,
          corpusReferLibIds: corpusReferLibIds,
          corpusMasterLibIds: corpusMasterLibIds,
          translateMode: 1,
          scope: saveType.value,
        }

        const res = await updateTaskLibrary(params)
        if (res.data.result == '1') {
          store.dispatch('getTaskDetail')
          cancel()
        }
      }

      const back = () => {
        sonNewSetting.value && sonNewSetting.value.clearCheckInterval()
        settingType.value = 1
      }

      const closeGuide = () => {
        guide.value = false
        localStorage.setItem('guide', 'close')
      }

      const pushyanzhilu = () => {
        // window.open("https://www.wordpower.top/groupDetail?id=129");

        window.open('/go/pe_pc_login/groupDetail?id=129')
      }
      onBeforeMount(() => {
        store.dispatch('getTaskDetail')
      })
      onMounted(() => {
        if (!localStorage.getItem('guide')) {
          guide.value = true
        }
        activeKey.value = propsType || 1
      })

      return {
        confirmOk,
        isRecommend,
        back,
        settingType,
        sonRecommendSetting,
        store,
        closeGuide,
        guide,
        pushyanzhilu,
        tabsChange,
        activeKey,
        cancel,
        handleOk,
        sonTermRepository,
        sonNewSetting,
        sonSelectCorpus,
        t,
      }
    },
  }
