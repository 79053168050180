<template>
  <div class="projectWord-tables">
    <splitpanes
      class="default-theme"
      horizontal
      style="height: 100%;"
      @resize="onPaneResize"
      :dbl-click-splitter="false"
      v-if="mounted"
    >
      <pane :size="paneSize">
        <div class="topContainer-Room">
          <table-scroll ref="tableScrollRef"></table-scroll>
        </div>
      </pane>
      <pane :size="100 - paneSize" :min-size="minSize">
        <div class="bottomContainer-Room" :class="{ fullScreen: fullScreen }">
          <srtBottomContainer
            v-if="isVideoProject"
            @openpreview="openPreview"
            @closepreview="closePreview"
            :scrollHeight="previewScrollHeight"
            :controlOpen="paneSize"
            :minSize="minSize"
          ></srtBottomContainer>
          <bottomContainer
              v-else
            ref="bottomContainerRef"
            @openpreview="openPreview"
            @closepreview="closePreview"
            :scrollHeight="previewScrollHeight"
            :controlOpen="paneSize"
            :minSize="minSize"
          ></bottomContainer>
        </div>
      </pane>
    </splitpanes>
    <progress-view v-if="!checkProgressDetail.hasClosed"></progress-view>
  </div>
</template>

<script>
  import {
    computed,
    ref,
    nextTick,
    onBeforeMount,
    reactive,
    toRefs,
    onMounted,
  } from 'vue'
  import { useStore } from 'vuex'
  // import useUtils from "@/plugins/utils.ts";
  // import MistakeCheckPopover from "./module/mistakeCheckPopover.vue";
  import TableScroll from './module/tableScroll/index.vue'
  import bottomContainer from './module/wordExcel/bottomContainer.vue'
  import srtBottomContainer from "./module/videopreview/bottomContainer.vue";
  import ProgressView from '@/components/progress/progress.vue'
  import mitt from '@/plugins/bus'
  import { Splitpanes, Pane } from 'splitpanes'

  export default {
    components: {
      srtBottomContainer,
      TableScroll,
      bottomContainer,
      ProgressView,
      Splitpanes,
      Pane,
    },
    setup() {
      const store = useStore()
      // const { getPagesSentId } = useUtils();
      // const pageSourceObject = computed(() => store.state.pageSourceObject);
      //除了标题以及上方工具栏以外剩余的高度
      const bottomContainerRef = ref(null);
      const isVideoProject = computed(()=>{return store.state.taskDetail.isVideoProject});

      const fullScreen = computed(() => store.state.fullScreen)
      const tableScrollRef = ref(null)

      const state = reactive({
        paneSize: 95,
        previewScrollHeight: 0,
        timer: null,
        minSize: 4,
        mounted: false,
      })

      const initScrollHeight = (loading = false) => {
        if (loading) {
          store.commit('changeKey', {
            screenLoading: true,
          })
        }
        setTimeout(() => {
          state.previewScrollHeight =
            document.getElementsByClassName(`bottomContainer-Room`)[0].offsetHeight - 42
          // if (loading) {
          store.commit('changeKey', {
            screenLoading: false,
          })
          // console.log(tableScrollRef);
          tableScrollRef.value.updateNewEditTableScrollRef()
          bottomContainerRef.value?.updatePreviewRefScroll()
          // }
        }, 400)
      }

      const keepTwoDecimal = (num) => {
        let result = parseFloat(num)
        if (isNaN(result)) {
          return false
        }
        result = Math.round(num * 100) / 100
        // return Math.trunc(num);
        return result
      }

      const setLocalStorage = (size) => {
        localStorage.setItem('paneSize', size)
      }

      //打开下方
      const openPreview = () => {
        state.paneSize = 50
        setLocalStorage(state.paneSize)
        nextTick(() => {
          initScrollHeight(true)
        })
      }

      //关闭下方
      const closePreview = () => {
        state.paneSize = keepTwoDecimal(100 - state.minSize)
        setLocalStorage(state.paneSize)
        nextTick(() => {
          initScrollHeight(true)
        })
      }

      const onPaneResize = (e) => {
        // console.log(e)
        state.paneSize = e[0].size
        setLocalStorage(state.paneSize)
        if (state.timer) {
          clearTimeout(state.timer)
        }
        state.timer = setTimeout(() => {
          nextTick(() => {
            initScrollHeight(true)
          })
        }, 1000)
      }

      const initPaneSize = () => {
        let paneSize = localStorage.getItem('paneSize')
        if (localStorage.getItem('paneSize')) {
          state.paneSize = keepTwoDecimal(paneSize)
        } else {
          state.paneSize = keepTwoDecimal(100 - state.minSize)
        }
      }

      const initMinSize = () => {
        let height = document.getElementsByClassName('projectWord-tables')[0]
          .offsetHeight
        let minSize = keepTwoDecimal((40 / height) * 100)
        state.minSize = minSize
      }

      // onBeforeMount(() => {
      //
      // });

      onMounted(() => {
        initMinSize()
        initPaneSize()
        state.mounted = true
        setTimeout(() => {
          state.previewScrollHeight =
            document.getElementsByClassName(`bottomContainer-Room`)[0]
              .offsetHeight - 42
        }, 1000)
        window.onresize = function() {
          if (state.timer) {
            clearTimeout(state.timer)
          }
          state.timer = setTimeout(() => {
            initMinSize()
            // state.paneSize = 100 - state.minSize;
            let height = document.getElementsByClassName(
              'projectWord-tables'
            )[0].offsetHeight
            let bottomHeightSize = 100 - state.paneSize
            let bottomHeight = keepTwoDecimal((height * bottomHeightSize) / 100)
            if (bottomHeight < 42) {
              state.paneSize = 100 - state.minSize
            }
            initScrollHeight()
          }, 500)
        }
      })

      return {
        isVideoProject,
        tableScrollRef,
        onPaneResize,
        bottomContainerRef,
        fullScreen,
        ...toRefs(state),
        openPreview,
        store,
        closePreview,
        checkProgressDetail: computed(() => store.state.checkProgressDetail),
      }
    },
  }
</script>

<style lang="less" scoped>
  .projectWord-tables {
    height: calc(100vh - 104px);
    //background: red;
    overflow: hidden;
    .topContainer-Room {
      background: var(--universal-color-table);
      height: 100%;
      overflow: hidden;
    }
    .bottomContainer-Room {
      height: 100%;
      box-shadow: 0px -2px 2px 0px rgba(2, 73, 89, 0.05);
      width: 100%;
      overflow: hidden;
      &.fullScreen {
        top: 0;
        z-index: 6;
        position: fixed;
        height: 100% !important;
        width: 100%;
        background: #f5f9fa;
      }
    }
  }
</style>
