<template>
  <splitpanes
    class="lsyy-box"
    :class="{ disabled: closelsyy || !store.state.taskDetail.isOpenTwinslator }"
    horizontal
    :dbl-click-splitter="false"
    @resize="onReSize"
  >
    <pane
      :size="
        store.state.taskDetail.isOpenTwinslator
          ? 100 - paneSize
          : 100 - closeMinSize
      "
      :min-size="20"
    >
      <div class="top-room" style="height: 100%;">
        <img
          src="./tip/newtop.png"
          class="drag-top-img"
          width="36"
          v-if="!closelsyy && store.state.taskDetail.isOpenTwinslator"
        />
        <ElScrollbar
          style="height: 100%;overflow-y: auto;padding:0 16px"
          wrap-class="rightBar-termCorpus"
        >
          <termCorpus></termCorpus>
        </ElScrollbar>
      </div>
    </pane>
    <pane
      v-if="!store.state.taskDetail.isOpenTwinslator"
      :size="closeMinSize"
      :min-size="closeMinSize"
    >
      <div class="bottom-room-bg display-flex align-center">
        <div class="bottom-room">
          <div class="isCloseTwinslator">
            <div class="left">
              <img src="./tip/closelsyy.png" />
            </div>
            <div class="right">
              <div class="title">
                PM关闭了孪生译员使用权限
              </div>
              <div class="content">
                包括：机翻、质检、润色、对话和术语重译
              </div>
            </div>
          </div>
        </div>
      </div>
    </pane>
    <pane :size="paneSize" v-else :min-size="30">
      <div class="bottom-room-bg">
        <!-- 查看翻译日志 -->
        <div class="bottom-room" :class="{ closelsyybg: closelsyy }">
          <img
            src="./tip/bottom.png"
            class="drag-bottom-img"
            width="36"
            v-if="!closelsyy"
          />
          <div
            class="bottom-room-top-icon"
            :style="{ visibility: `${closelsyy ? 'hidden' : ''}` }"
          >
            <!--            <img src="./tip/top.png" width="10" />-->
          </div>
          <ElScrollbar
            wrap-class="talk-list-scroll"
            class="talk-list"
            style="height: 100%;overflow-y: auto;position: relative"
            v-show="!closelsyy"
          >
            <div class="bottom-room-text">
              当前会话对应：第{{ checkedDetail.extSentId }}句
            </div>
            <talkList
              :list-data="listData"
              :showNumber="100"
              @openSetting="openSetting"
            ></talkList>
          </ElScrollbar>
          <div v-show="closelsyy" class="sleepLsyy">
            <img src="./tip/lsyy2.png" width="28" />
            <span>小译休眠中😴，您可唤醒我或直接发起指令</span>
            <a-tooltip title="点我唤醒孪生译员">
              <span class="icon">
                <icon-font
                  type="icon-kaiguan"
                  @click="closelsyyChange(false)"
                ></icon-font>
              </span>
            </a-tooltip>
          </div>
          <div class="text-input" v-show="!closelsyy">
            <div class="text-button">
              <img src="./tip/lsyy1.png" width="28" />
              <div @click="openSmartAi('Recommend')" class="button">
                <icon-font type="icon-zhifan" class="margin-r-4"></icon-font>
                智翻
              </div>
              <a-dropdown placement="topCenter">
                <div class="button">
                  <icon-font type="icon-zhirun" class="margin-r-4"></icon-font>
                  润色
                </div>
                <template #overlay>
                  <div style="width: 320px;">
                    <div class="runse-scrollBar" id="runse-scrollBar">
                      <div class="runse-options-title">
                        润色要求:
                      </div>
                      <ul class="runse-options">
                        <a-row :gutter="2">
                          <a-col :span="12" v-for="(item, index) in levelObj">
                            <li
                                @click="openSmartAi('Decorate', index)"
                                :key="index"
                            >
                              {{ item.description }}
                            </li>
                          </a-col>
                        </a-row>
                      </ul>
                      <div class="runse-options-title display-flex justify-between align-center margin-t-16">
                        <span>自定义:</span>
                        <span @click="openRunseModal" style="color:#00B4FA;font-weight: normal;cursor: pointer">+ 新增</span>
                      </div>
                      <ul class="runse-options">
                        <a-row :gutter="2">
                          <a-col :span="12" v-for="(item, index) in customLevelObj">
                            <li class="runse-options-item" @click="openSmartAi('Decorate', index)" :key="index" :title="item.description">
                              {{item.description}}
                              <span class="options-icon" @click.stop>
                                <icon-font type="icon-bianji2" style="border-right: 1px solid #EEEEEE;border-radius: 4px 0px 0px 4px;" @click="editRunseModal(item)"></icon-font>
                                <icon-font type="icon-guanbi" style="border-radius: 0px 4px 4px 0px;" @click="delRunseModal(item)"></icon-font>
                              </span>
                            </li>
                          </a-col>
                        </a-row>
                      </ul>
                    </div>
                  </div>


                  <ul class="runse-options">
                    <div
                      v-for="(item, index) in levelObj"
                      class="li"
                      :key="index"
                    >
                      <li :key="index" @click="openSmartAi('Decorate', index)">
                        {{ levelObj[index] }}
                      </li>
                    </div>
                  </ul>
                </template>
              </a-dropdown>
              <div @click="openSmartAi('Check')" class="button">
                <icon-font type="icon-zhijian1" class="margin-r-4"></icon-font>
                质检
              </div>
              <div class="setting-button">
                <icon-font
                  type="icon-shezhi"
                  class="font-size-12"
                  @click="openSetting"
                ></icon-font>
                |
                <a-tooltip title="点我关闭孪生译员">
                  <icon-font
                    class="font-size-12"
                    type="icon-kaiguan"
                    @click="closelsyyChange(true)"
                  ></icon-font>
                </a-tooltip>
              </div>
            </div>
          </div>
          <div class="text-textarea">
            <a-textarea
              v-model:value="textareaValue"
              @keydown="onkeydown"
              autoSize
              placeholder="您可以在这里针对当前句的原文或译文进行提问，例如 XXX是什么意思"
              :maxlength="200"
              showCount
              @focus="textareaFocus"
              @blur="textareaBlur"
            />
            <div class="send-png">
              <img src="./image/send.png" @click="sendMessage" />
            </div>
          </div>
        </div>
      </div>
    </pane>
  </splitpanes>

  <a-modal
    title="孪生译员设置"
    cancelText="取消"
    okText="保存设置"
    :visible="visible"
    centered
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="modal-text margin-b-30">
      <div>
        <a-checkbox v-model:checked="autoPayChecked">
          <b>使用孪生译员功能时，译力足够则自动扣费</b>
        </a-checkbox>
      </div>
      <div class="tip">
        包括：机翻、译文推荐、译文润色、智能质检、术语重译功能
      </div>
    </div>
    <div class="modal-text">
      <div>
        <a-checkbox
          v-model:checked="autoRecommendChecked"
          :disabled="!autoPayChecked"
        >
          <b>自动推荐译文自主学习结果</b>
        </a-checkbox>
      </div>
      <div class="tip">
        小译学习到新译文时，会自动为您推荐结果，并自动付费
      </div>
    </div>
  </a-modal>

  <runseModal ref="runseModalRef">

  </runseModal>
</template>

<script>
import runseModal from "@/components/dialog/runseModal/runseModal.vue"
import {Pane, Splitpanes} from 'splitpanes'
import mitt from '@/plugins/bus'
import {ExclamationCircleOutlined, SettingOutlined,} from '@ant-design/icons-vue'
import talkList from './tip/talkList.vue'
import {message, Modal} from 'ant-design-vue'
import {
  computed,
  createVNode,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue'
import termCorpus from './tip/termCorpus.vue'
import {useStore} from 'vuex'
import {
  getAiDecorateResult,
  getAiListLogs,
  getAiSmartResult,
  getDialogueNotify,
  getQualityCheckResult,
  getRestPower,
  getTermList,
  getTwinsSetStatus,
  postInstructionTalk,
  postTwinsSetStatus,
} from '@/http/api'
import filterStrings from '@/plugins/filterString'
import {ElScrollbar} from 'element-plus'

export default {
    components: {
      runseModal,
      ElScrollbar,
      SettingOutlined,
      talkList,
      Splitpanes,
      Pane,
      termCorpus,
    },
    setup() {
      const store = useStore()
      const levelObj = computed(() => {
        return store.state.decorationLevelObj
      })
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties

      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })
      const checkedSentId = computed(() => {
        return store.state.checkedDetail.sentId
      })
      const initTipTimer = ref(null);
      const customLevelObj = computed(()=>{
        return store.state.customDecorationObj
      })
      const runseModalRef = ref(null);

      const state = reactive({
        paneSize: 65,
        textareaValue: '',
        listData: [],
        randomId: 1,
        visible: false,
        sessionId: '',
        isMount: 1,
        workId: 1,
        closelsyy: false,
        oldPaneSize: '',
        closeMinSize: 5,
        openAutoPaySettingCallBack: null,
      })

      const autoPayChecked = computed({
        set(val) {
          store.commit('modal/changeKey', {
            autoPayChecked: val,
          })
          if (!val && autoRecommendChecked.value) {
            store.commit('modal/changeKey', {
              autoRecommendChecked: false,
            })
          }
        },
        get() {
          return store.state.modal.autoPayChecked
        },
      })

      const autoRecommendChecked = computed({
        set(val) {
          store.commit('modal/changeKey', {
            autoRecommendChecked: val,
          })
        },
        get() {
          return store.state.modal.autoRecommendChecked
        },
      })

      const keepTwoDecimal = (num) => {
        let result = parseFloat(num)
        if (isNaN(result)) {
          return false
        }
        result = Math.round(num * 100) / 100
        return result.toFixed(2)
      }

      const closelsyyChange = (type) => {
        if (type) {
          state.oldPaneSize = state.paneSize
          state.closelsyy = type
          //存下本地存储
          localStorage.setItem('closelsyy', true)
          //计算最小高度
          let minSize = keepTwoDecimal((150 / document.body.clientHeight) * 100)
          setTimeout(() => {
            state.paneSize = minSize
          }, 100)
        } else {
          //存下本地存储
          localStorage.removeItem('closelsyy')
          state.closelsyy = type
          setTimeout(() => {
            state.paneSize = state.oldPaneSize || 35
          }, 100)
          // state.paneSize = state.oldPaneSize?100-state.oldPaneSize : 35
        }
      }

      const openSetting = () => {
        state.visible = true
      }

      const resetSet = (type) => {
        getTwinsSetStatus().then((res) => {
          let result = res.data.data
          // 自动支付
          autoPayChecked.value = type || result.autoPay
          //自动推荐开关
          autoRecommendChecked.value = result.autoRecommend
        })
      }

      const handleCancel = () => {
        state.visible = false
        resetSet()
        //取消callBack
        state.openAutoPaySettingCallBack = null
      }

      const handleOk = () => {
        let params = {
          autoRecommend: autoRecommendChecked.value,
          autoPay: autoPayChecked.value,
        }

        postTwinsSetStatus(params).then((res) => {
          if (
            state.openAutoPaySettingCallBack &&
            typeof state.openAutoPaySettingCallBack === 'function'
          ) {
            state.openAutoPaySettingCallBack
          }
          handleCancel()
        })
      }

      const httpAddTalkList = (randomId) => {
        let listItem = {
          dialogueState: 'Processing',
          randomId: randomId,
        }

        state.listData.push(listItem)
        state.randomId++
      }

      const sleepFunc = (time = 800) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, time)
        })
      }

      const scrollBottom = (time = 200) => {
        let scrollNode = document.getElementsByClassName('talk-list-scroll')[0]
        setTimeout(() => {
          if (scrollNode) {
            scrollNode.scrollTop = scrollNode.scrollHeight
          }
        }, time || 200)
      }
      //此处要改
      const replaceProcessingTalk = (randomId, params) => {
        state.listData.forEach((item) => {
          if (item.randomId == randomId) {
            item.dialogueResult = {}
            item.docId = params.docId
            item.dialogueState = 'Finished'
            item.dialogueType = params.dialogueType
            item.decorateCode = params.decorate?.code
            item.sentId = params.sentId
            item.sentIndex = params.sentIndex
            item.id = params.dialogueId

            item.dialogueResult.result =
              params.targetWithTags || params.target || params.suggest
            item.dialogueResult.applyType = params.applyType
            item.dialogueResult.decorateDescription =
              params.decorate?.description
            item.dialogueResult.score = params.score
            item.source = params.source
          }
        })
        scrollBottom(0)
      }

      const deleteProcessingTalk = (randomId) => {
        for (let i = 0; i < state.listData.length; i++) {
          let item = state.listData[i]
          if (item.randomId == randomId) {
            state.listData.splice(i, 1)
            break
          }
        }
      }

      const payAiCost = async (type, level) => {
        let params = {
          docId: store.state.taskDetail.docId,
          sentId: checkedDetail.value.sentId,
        }
        let randomId = state.randomId
        httpAddTalkList(randomId)

        let Key = {
          Recommend: 'TranslationRecommend',
          Decorate: 'GptDecorate',
          Check: 'GptQualityCheck',
        }

        scrollBottom(100)

        await sleepFunc(100)

        let result = null
        try {
          if (type == 'Recommend') {
            params = {
              ...params,
              ...{ twinslatorId: store.state.taskDetail.mtId },
            }
            result = await getAiSmartResult(params)
          } else if (type == 'Decorate') {
            params = {
              ...params,
              ...{
                decorateId: level,
              },
            }
            result = await getAiDecorateResult(params)
          } else if (type == 'Check') {
            params = {
              ...params,
              ...{
                dialogueType: 'GptQualityCheck',
                currentTargetText: document.getElementById(
                  checkedDetail.value.targetDomId
                )?.innerHTML,
              },
            }
            result = await getQualityCheckResult(params)
          }
        } catch (e) {
          deleteProcessingTalk(randomId)
        } finally {
          // 代码块2
        }

        if (result) {
          result.dialogueType = Key[type]
          result = { ...result, ...result.data.data }
          replaceProcessingTalk(randomId, result)
        } else {
          deleteProcessingTalk(randomId)
          //处理此句话；
        }
      }

      let buttonTimer = true
      //控制Key
      const openSmartAi = (type, level) => {
        const needCheckArr = ['Decorate', 'Check']
        if (
          needCheckArr.indexOf(type) > -1 &&
          !filterStrings.getPureString(
            document.getElementById(checkedDetail.value.targetDomId).innerHTML
          )
        ) {
          //校验译文
          message.warning('当前句译文不可为空')
          return
        }
        //防抖
        // clearTimeout(buttonTimer);
        if (!buttonTimer) {
          message.warning('请不要频繁点击')
          return
        }
        if (buttonTimer) {
          buttonTimer = false
          let Key = {
            Recommend: 'TranslateRecommend',
            Decorate: 'GptDecorate',
            Check: 'GptQualityCheck',
          }
          let sentIds = [checkedSentId.value]
          // console.log(sentIds);
          mitt.emit('openCostModal', {
            costType: Key[type],
            sentIds: sentIds,
            ok: (e) => {
              payAiCost(type, level)
            },
          })
          setTimeout(() => {
            buttonTimer = true
          }, 1000)
        }
      }

      const sendMessage = async () => {
        if (!state.textareaValue) {
          return
        }

        if (state.closelsyy) {
          closelsyyChange(false)
        }

        let result = await getRestPower()
        let balance = result.data.data
        if (balance < 500) {
          Modal.confirm({
            content: `${
              store.state.taskDetail.enterpriseId == 'personal' ? '' : '企业内'
            }使用小译对话功能需账户至少有500译力哦`,
            okText: `${
              store.state.taskDetail.enterpriseId == 'personal'
                ? '前往充值'
                : '确定'
            }`,
            cancelText: '返回',
            title: '译力不足',
            icon: createVNode(ExclamationCircleOutlined),
            centered: true,
            onOk() {
              //个人版
              if (store.state.taskDetail.enterpriseId == 'personal') {
                store.commit('changeKey', {
                  rechargeVisible: true,
                })
              } else {
              }
            },
          })
          return
        }

        if (!autoPayChecked.value) {
          Modal.confirm({
            title: '提示',
            content: '勾选自动扣费才能使用，是否勾选。',
            okText: '确定',
            cancelText: '取消',
            centered: true,
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              autoPayChecked.value = true
              let params = {
                autoRecommend: autoRecommendChecked.value,
                autoPay: autoPayChecked.value,
              }

              postTwinsSetStatus(params)
              sendMessage()
              // state.visible = true;
            },
          })
          return
        }

        let randomId = state.randomId
        let listItem = {
          dialogueType: 'UserInstruction',
          dialogueState: 'Processing',
          randomId: randomId,
          sessionId: state.sessionId,
          dialogueResult: {
            question: state.textareaValue,
          },
        }

        state.listData.push(listItem)

        state.randomId++
        scrollBottom(0)

        postInstructionTalk({
          docId: store.state.taskDetail.docId,
          sentId: checkedSentId.value,
          sessionId: '',
          question: state.textareaValue,
        }).then((res) => {
          let result = res.data.data
          state.listData.find((item) => {
            if (randomId == item.randomId) {
              item.dialogueState = 'Finished'
              item.dialogueResult = { ...result }
              state.sessionId = result.sessionId
              scrollBottom(0)
              return true
            }
          })
        })
        state.textareaValue = ''
      }

      const onkeydown = (event) => {
        if (event.keyCode == 13) {
          let e = event
          e.preventDefault ? e.preventDefault() : (e.returnValue = false)
          sendMessage()
          return
        }
      }
      const onReSize = (value) => {
        state.paneSize = value[1].size
        localStorage.setItem('toolbarPaneSize', state.paneSize)
      }

      const clearHistoryTalk = () => {
        //双滚动条重置
        document.getElementsByClassName('rightBar-termCorpus')[0].scrollTop = 0
        document.getElementsByClassName('talk-list-scroll')[0].scrollTop = 0
        state.textareaValue = ''
        state.listData = []
        state.sessionId = ''
      }

      const addAiRecommend = (res) => {
        let result = res
        //有结果则添加
        if (result.recommendResult) {
          let params = { ...result.recommendResult }
          let item = {
            dialogueResult: {},
          }
          item.dialogueType = 'TranslationRecommend'
          item.freeRecommend = params.freeRecommend
          item.docId = params.docId
          item.dialogueState = 'Finished'
          item.sentId = params.sentId
          item.sentIndex = params.sentIndex
          item.dialogueResult.result = params.targetWithTags || params.target
          item.dialogueResult.applyType = params.applyType
          item.freeUsed = params.freeUsed
          item.freeTotal = params.freeTotal
          item.id = params.dialogueId
          state.listData.push(item)
        }
      }

      const loadHistoryTalk = async () => {
        state.workId++
        let localWorkId = state.workId
        //获取会话历史记录
        let p1 = getAiListLogs({
          docId: store.state.taskDetail.docId,
          sentId: checkedDetail.value.sentId,
        })

        //获取是否添加术语会话
        let p2 = getTermList({
          docId: store.state.taskDetail.docId,
          // sentIndex: checkedSentId.value
        })

        //获取是否智能推荐
        let p3 = getDialogueNotify({
          docId: store.state.taskDetail.docId,
          sentId: checkedDetail.value.sentId,
        })

        // let localRandomId = "";

        Promise.all([p1, p2, p3]).then(async (results) => {
          // console.log(results);
          //会话历史记录
          let result1 = results[0].data.data.list
          //添加术语会话
          let result2 = results[1].data.data.list
          //只能推荐会话
          let result3 = results[2].data.data

          if (state.isMount == 1 && !result1?.length) {
            //说明没有变化
            if (localWorkId == state.workId) {
              state.listData.push({
                dialogueType: 'commonTalk',
                text: '👋🏻 Hi，我是孪生译员“小译”，您的专属翻译Copilot～',
              })
              await sleepFunc()
            }

            if (localWorkId == state.workId) {
              state.listData.push({
                dialogueType: 'commonTalk',
                text:
                  '我可以自主学习，学习的结果我会在此处提示，您也可以随时对我下达指令',
              })
              await sleepFunc()
            }
          }

          result1.slice(0, 10).find((item) => {
            if (item.dialogueType == 'UserInstruction') {
              //存sessionId
              state.sessionId = item.dialogueResult.sessionId
              return true
            }
          })

          //添加历史记录会话
          if (result1?.length) {
            if (localWorkId == state.workId) {
              state.listData = result1.reverse()
              await sleepFunc()
            }
          }

          //开始添加推荐术语会话
          if (result2.length > 0) {
            if (localWorkId == state.workId) {
              let termList = [...result2]
              let item = {
                isAddTerm: true,
                termList: [...termList],
              }
              state.listData.push(item)
              await sleepFunc()
            }
          }

          if (localWorkId == state.workId) {
            //最后一步 添加智能推荐会话
            addAiRecommend(result3)
            // await sleepFunc();
            // item.freeUsed
          }

          if (localWorkId == state.workId) {
            scrollBottom(0)
          }
          state.isMount++
        })
      }

      // const observer = new MutationObserver(() => {
      //   checkedDetail.value.targetChange = true;
      //   let checkItem =
      //     store.state.excelProject.docData.sentenceDTOList[
      //       store.state.excelProject.ArrMap.get(checkedDetail.value.sentId)
      //     ];
      //   checkItem.targetEditChange = true;
      //   //记录当前选中句子的change
      // });
      // watch(checkedDetail, n => {
      //   //值有变化则触发更新监听当前句是否hasChange// 先取消
      //   // observer.disconnect();
      //   // if (n.targetDomId) {
      //   //   let node = document.getElementById(n.targetDomId);
      //   //   if (node) {
      //   //     observer.observe(document.getElementById(n.targetDomId), {
      //   //       characterData: true,
      //   //       childList: true,
      //   //       subtree: true
      //   //     });
      //   //   }
      //   // }
      //
      //   if (store.state.taskDetail.isOpenTwinslator && n.sentId) {
      //     //清空当前对话
      //
      //     clearHistoryTalk();
      //     loadHistoryTalk();
      //     //加载历史对话
      //   }
      //
      //
      //   store.dispatch("excelProject/twinslatorMatch");
      // });

      const initTip = () => {
        clearTimeout(initTipTimer.value)
        if (
          store.state.taskDetail.isOpenTwinslator &&
          checkedDetail.value.sentId
        ) {
          initTipTimer.value = setTimeout(() => {
            clearHistoryTalk()
            loadHistoryTalk()
          }, 1000)
        }
      }
      const textareaFocus = () => {
        //得到焦点
        store.commit('changeKey', {
          isDisabledEnter: true,
        })
      }

      const textareaBlur = () => {
        //失去焦点
        store.commit('changeKey', {
          isDisabledEnter: false,
        })
      }

      mitt.on('openAutoPaySetting', (event) => {
        state.visible = true
        state.openAutoPaySettingCallBack = event
      })

      //编辑润色弹窗
      const editRunseModal=(item)=>{
        runseModalRef.value.editInit(item,()=>{

        })
      }

      const delRunseModal=(item)=>{
        runseModalRef.value.delInit(item)
      }

      const openRunseModal=()=>{
        //数据传过来
        runseModalRef.value.editInit(
            {},
            ()=>{},
            ()=>{}
        )
      }

      watch(checkedDetail, (n) => {
        //值有变化则触发更新监听当前句是否hasChange// 先取消
        // observer.disconnect();
        // if (n.targetDomId) {
        //   let node = document.getElementById(n.targetDomId);
        //   if (node) {
        //     observer.observe(document.getElementById(n.targetDomId), {
        //       characterData: true,
        //       childList: true,
        //       subtree: true
        //     });
        //   }
        // }

        initTip()
        //加载历史对话
      })


      onBeforeMount(() => {
        if (localStorage.getItem('toolbarPaneSize')) {
          state.paneSize = localStorage.getItem('toolbarPaneSize')
        }
        resetSet()

        //计算关闭孪生译员的改动。
        if (!store.state.taskDetail.isOpenTwinslator) {
          let minSize = keepTwoDecimal(
            (64 / (document.body.clientHeight - 50 - 48)) * 100
          )
          state.closeMinSize = minSize
        }
      })

      onMounted(() => {
        if (localStorage.getItem('closelsyy')) {
          closelsyyChange(true)
        }
        initTip()
      })

      //增加定时器清理，防止报错
      onBeforeUnmount(() => {
        clearTimeout(initTipTimer.value)
      });

      return {
        delRunseModal,
        editRunseModal,
        customLevelObj,
        runseModalRef,
        openRunseModal,
        initTip,
        textareaFocus,
        textareaBlur,
        closelsyyChange,
        checkedDetail,
        autoRecommendChecked,
        autoPayChecked,
        sendMessage,
        openSetting,
        levelObj,
        handleCancel,
        handleOk,
        checkedSentId,
        openSmartAi,
        onkeydown,
        onReSize,
        disabled: computed(() => store.state.openEditSourceText),
        store,
        ...toRefs(state),
      }
    },
  }
</script>

<style lang="scss" scoped>
.runse-scrollBar{
  width: 100%;
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(16,102,158,0.08);
  max-height:330px;
  padding: 16px;
  overflow-x: auto;
  border-radius: 10px;
  //overflow-x: hidden;
}
.runse-options-title{
  font-weight: bold;
  color:#333;
  margin-bottom: 8px;
}
.runse-options {
  border-radius: 8px;
  font-size: 12px;
  li {
    cursor: pointer;
    background: #F5F6FA;
    border-radius: 6px;
    margin-bottom: 2px;
    padding: 0 10px;
    text-align: center;
    height: 24px;
    line-height: 24px;
    color:#666;
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 隐藏溢出的文本 */
    text-overflow: ellipsis;
    &:hover{
      background: #E7F2FD;
    }
  }
  .runse-options-item{
    position: relative;
    &:hover{
      .options-icon{
        //display: inline-block;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .options-icon{
      position: absolute;
      border-radius: 4px;
      display: none;
      height: 100%;
      top: 0;
      right: 4px;
      z-index: 3;
      ::v-deep(span){
        width:16px;
        height: 16px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }
}
  .modal-text {
    font-size: 14px;
    b {
      color: #333333;
      margin-left: 16px;
    }
    .tip {
      height: 22px;
      color: #999999;
      line-height: 22px;
      margin-left: 40px;
      margin-top: 8px;
    }
  }

  .isCloseTwinslator {
    display: flex;
    align-items: center;
    padding: 0 16px;
    overflow: hidden;
    height: 100%;
    .left {
      margin-right: 16px;
      img {
        width: 28px;
      }
    }
    .right {
      .title {
        height: 18px;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 4px;
        overflow: hidden;
      }
      .content {
        height: 18px;
        overflow: hidden;
        color: #86909c;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .lsyy-box {
    height: calc(100% - 48px);
    border-radius: 0px 8px 8px 8px;
    line-height: 1.38;
    ::v-deep(.splitpanes__splitter) {
      &::before {
        top: 1px !important;
        bottom: -1px !important;
      }
      background: var(--universal-color-right_toolbar-down);
    }
    &.disabled {
      ::v-deep(.splitpanes__splitter) {
        pointer-events: none; /* 禁止点击事件 */
      }
    }
    .drag-bottom-img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .drag-top-img {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
    }
    .top-room {
      position: relative;
      background: linear-gradient(
        180deg,
        var(--universal-color-right_toolbar-up) 0%,
        var(--universal-color-right_toolbar-down) 100%
      );
    }
    .bottom-room-bg {
      background: var(--universal-color-right_toolbar-down);
      height: 100%;
      width: 100%;
    }
    .bottom-room {
      width: 100%;
      height: 100%;
      position: relative;
      //padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        var(--universal-color-right_toolbar-up) 100%
      );
      box-shadow: inset 0px 1px 0px 0px #e6f1f5;
      border-radius: 24px 24px 0px 0px;
      &.closelsyybg {
        background: var(--universal-color-right_toolbar-up);
      }
      .bottom-room-top-icon {
        height: 30px;
        text-align: center;
      }
      .bottom-room-text {
        color: rgba(0, 0, 0, 0.3);
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .talk-list {
        flex-grow: 1;
        width: 100%;
      }
      .sleepLsyy {
        padding: 2px 12px;
        color: #86909c;
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: flex-start;
        height: 28px;
        line-height: 28px;
        position: relative;
        bottom: 10px;
        img {
          margin-right: 8px;
        }
        .icon {
          flex-shrink: 0;
          width: 28px;
          height: 28px;
          line-height: 28px;
          border-radius: 50%;
          color: #00befa;
          background: white;
          margin-left: 8px;
          box-shadow: 0px 2px 4px 0px rgba(5, 92, 156, 0.04);
          text-align: center;
        }
        //margin-bottom: 8px;
      }
      .text-input {
        flex-basis: 72px;
        width: 100%;
        height: 100%;
        display: flex;
        padding-left: 16px;
        justify-content: flex-start;
        img {
          margin-right: 8px;
        }
        .text-button {
          display: flex;
          align-items: center;
          justify-content: center;
          .setting-button {
            border-radius: 14px;
            height: 24px;
            box-shadow: 0px 2px 4px 0px rgba(5, 92, 156, 0.04);
            background: white;
            line-height: 24px;
            padding: 0 10px;
            color: #e5e6eb;
            ::v-deep(span) {
              cursor: pointer;
              color: #86909c;
            }
          }
          .button {
            padding: 0 10px;
            border-radius: 12px;
            cursor: pointer;
            background: white;
            height: 24px;
            font-size: 12px;
            margin-right: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 2px 4px 0px rgba(5, 92, 156, 0.04);
            &:hover {
              font-weight: bold;
              //border:2px #29dfff solid;
            }
            ::v-deep(span) {
              color: #00befa;
              margin-right: 3px;
            }
          }
        }
      }

      .text-textarea {
        //border-radius: 8px;
        padding: 10px 48px 10px 16px;
        background: #fff;
        position: relative;
        box-shadow: 0px 2px 14px 0px rgba(11, 149, 192, 0.2);
        ::v-deep(textarea) {
          font-size: 13px;
          resize: none;
          min-height: 22px;
          border-width: 0 !important;
        }
        ::v-deep(.ant-input-textarea-show-count::after) {
          font-size: 12px;
          color: #bbb;
        }
        ::v-deep(.ant-input) {
          min-height: 49px;
          padding: 0 0 8px 0;
        }
        ::v-deep(.ant-input:focus) {
          border-width: 0 !important;
          box-shadow: 0 0 0 0;
        }
        ::v-deep(.ant-input-textarea-show-count::after) {
          text-align: left;
        }
        .send-png {
          cursor: pointer;
          bottom: 12px;
          right: 12px;
          position: absolute;
          height: 28px;
          width: 28px;
          img {
            width: 100%;
            height: 100%;
            &:hover {
              transition-duration: 0.5s;
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
</style>
