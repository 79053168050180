<script setup>
import {computed, onBeforeMount, onBeforeUnmount, reactive, ref} from "vue";
import {getAgentListApi, postAgentQueryCostApi} from "@/http/api";
import {useStore} from "vuex";
import {message} from "ant-design-vue";

const store = useStore();
const checkedList = computed(()=> store.state.excelProject.checkedList);
const enterpriseGlobalObj = computed(()=> store.state.enterpriseGlobalObj);

const state = reactive({
  visible:false,
  searchKey:"",
  wordCount:"",
})

const checkedId = ref("");
const list = ref([]);
let data = ref({});
const costDetail = ref({});
//确认按钮回调
let okCbFunc=()=>{

}

// 取消按钮回调
let clCbFunc=()=>{

}

const getList =()=>{
  getAgentListApi({page:1,size:100,isOfficial:true,searchKey:state.searchKey,enterpriseId:store.state.taskDetail.enterpriseId}).then(res=>{
    list.value = [...res.data.data];
  })
}

const init =(params,okFunc)=>{
  state.wordCount = "";
  data.value = {...params};
  costDetail.value = {};
  state.searchKey = "";
  checkedId.value = "";
  getList();
  state.visible =true;
  if (okFunc) {
    okCbFunc = okFunc
  }
}

const checkItem=(id)=>{
  if (checkedId.value == id) {
    return
  }

  costDetail.value = {

  };
  checkedId.value = id;

  let sentIds = "";
  if (checkedList.value?.length) {
    sentIds =checkedList.value.map(item=>{
      return item.sentId
    }).join(",");
  } else {
    sentIds = store.state.checkedDetail.sentId;
  }
  // let docIds = props.selectedFilesList
  postAgentQueryCostApi({
    docId:store.state.taskDetail.docId,
    emkcWorkflowId:id,
    sentIds:sentIds,
  }).then(res=>{
    //赋值
    costDetail.value = res.data.data;
    console.log(res,costDetail.value)
    costDetail.value.emkcWorkflowId = id;

    state.wordCount = state.wordCount || costDetail.value.wordCount;
  })
}

const handleOk=()=>{
  if (!costDetail.value.emkcWorkflowId) {
    message.warning("请勾选智能体或者等待统计结果出来再支付");
    return
  }
  state.visible = false;
  okCbFunc(costDetail.value)
}

const handleCancel=()=>{
  state.visible = false;
}

//前往试用
const openIntelligentWeb=()=>{

  if (enterpriseGlobalObj.value.permission.includes("tc.tai.agent.test")) {
    window.open(`/go/pe_pc_login/pmApp/pm/intelligentAgent?enterpriseId=${store.state.taskDetail.enterpriseId}`);
  } else {
    message.warning("智能体内测中，暂无权限进入，敬请期待")
  }

}

const reset=()=>{
  state.searchKey = "";
  getList();
}

onBeforeMount(()=>{

})

onBeforeUnmount(()=>{

})

defineExpose({
  init,
});
</script>

<template>
<a-modal :visible="state.visible" :width="840" @cancel="handleCancel" centered>
  <template #title>
    <div class="display-flex justify-between align-center">
      <div class="display-flex align-center">
        <img src="./logo.png" width="20" class="margin-r-8">
        <div>选择智能体（{{data.language}}）</div>
      </div>
      <div class="display-flex align-center color-666 font-size-14 margin-r-20" style="font-weight: normal">
        可先在<span style="color: #00B4FA;text-decoration: underline;cursor: pointer" @click="openIntelligentWeb">智能体</span>中试用后，再批量处理哦
      </div>
    </div>
  </template>
  <div class="selectModal">
    <header>
      <div>
<!--        <div class="left">-->
          <!--        <div class="tabs active" @click="tabChange()">-->
          <!--          自建智能体-->
          <!--        </div>-->
          <!--        <div class="tabs active">-->
          <!--          发现智能体-->
          <!--        </div>-->
<!--        </div>-->
      </div>

      <a-space>
        <a-input placeholder="搜索智能体名称" v-model:value="state.searchKey">

        </a-input>
        <a-button type="primary" ghost @click="getList">
          查询
        </a-button>
        <a-button @click="reset">
          重置
        </a-button>
      </a-space>
    </header>

    <section>
      <div class="list">
        <div class="list-item" v-for="(item,index) in list" :class="{selected:checkedId==item.emkcWorkflowId}" @click="checkItem(item.emkcWorkflowId)">
          <div class="one">
            <img :src="item.imageUrl">
            <span>{{item.agentName}}</span>
          </div>
          <a-tooltip :title="item.agentDesc">
            <div class="two">
              {{item.agentDesc}}
            </div>
          </a-tooltip>
          <div class="three">
            <div>{{item.createName}}</div>
            <div>{{item.unitPrice}}译力/千字</div>
          </div>
          <img class="selected" src="./selected.png" v-show="checkedId==item.emkcWorkflowId">
        </div>
      </div>
    </section>
  </div>
  <template #footer>
    <div class="display-flex align-center justify-between">
      <div class="font-size-14 color-666">
        <span v-show="state.wordCount">总字数：{{state.wordCount}}</span>
      </div>
      <div>
        <a-space :size="12">
          <a-button @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" @click="handleOk">
            前往支付
          </a-button>
        </a-space>
      </div>
    </div>
  </template>
</a-modal>
</template>

<style scoped lang="less">

.selectModal{
  header{
    display: flex;
    justify-content: space-between;
    .left{
      background: #F2F3F5;
      border-radius: 16px;
      font-size: 14px;
      display: flex;
      height: 32px;
      padding:2px;
      align-items: center;
      .tabs{
        height: 28px;
        border-radius: 14px;
        padding:4px 16px;
        color:#999;
        cursor: pointer;
        &.active{
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);
          background: #FFFFFF;
          color:#333;
        }


      }
    }
  }
  section{
    margin-top: 16px;
    .list{
      display: flex;
      flex-wrap: wrap;
      .list-item{
        margin-bottom: 12px;
        cursor: pointer;
        border-radius: 10px;
        width: 240px;
        text-align: center;
        margin-right: 16px;
        position: relative;
        border: 1px solid #eeeeee;
        padding:12px 14px;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);
        &.selected{
          background: #F8FDFF;
          border-radius: 10px;
          border: 1px solid #00B4FA;
        }
        .one{
          display: flex;
          align-items: center;
          img{
            width: 28px;
            height: 28px;
          }
          span{
            font-weight: 600;
            font-size: 14px;
            color: #333333;
            margin-left: 10px;
          }
        }
        .two{
          color:#999;
          margin-top: 8px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-align: left;
        }
        .three{
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #BBBBBB;
        }
        .selected {
          position: absolute;
          bottom: -1px;
          right: 0;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>