<script setup>

import {useStore} from "vuex";

const props = defineProps(['tabActive']);
const store = useStore();
const emit = defineEmits(['ignoreClick']);
const tabChange=(val)=>{
  emit("update:tabActive",val);
}
</script>

<template>
  <div class="title-tab">
    <h4 @click="tabChange('2')" :class="{ select: props.tabActive == '2' }">
      我创建的
    </h4>
    <h4
        v-if="!store.state.taskDetail.isSelfWork"
        :class="{ select: props.tabActive == '4' }"
        @click="tabChange('4')"
    >
      企业协作
    </h4>
    <h4 @click="tabChange('1')" :class="{ select: tabActive == '1' }" v-if="store.state.taskDetail.isSelfWork">
      我收藏的
    </h4>
    <h4 @click="tabChange('3')" :class="{ select: tabActive == '3' }" v-if="store.state.taskDetail.isSelfWork">
      协作共建
    </h4>
  </div>
</template>

<style scoped lang="less">

</style>