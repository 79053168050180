import {assignmentSentenceInformation} from "@/plugins/comm";

const state = {
  video_instance:"",//video实例
  video_progress:"",//video进度
  word_setting:{
      one_level:15,
      two_level:20,
      three_level:25
  },
  wordSec_setting:{
      one_level:15,
      two_level:20,
      three_level:25
  },
  //字幕缓存数据，译文提交等一系列操作在此进行更新数据；
  captionData:[],
    //字幕缓存数据Map
  captionDataMap:{

  }
}
const getters = {}
const mutations = {
    changeKey(state, payload) {
        for (let i in payload) {
            state[i] = null
            state[i] = payload[i]
        }
    },
}
const actions = {
    updateCaptionData({ commit, rootState, dispatch, state }, params) {
        if (state.captionData[params.index]) {
            state.captionData[params.index].targetPlaceholder = params.targetPlaceholder || "";
            // console.log(state.captionData);
        }
    },
    updateCaptionDataTime({ commit, rootState, dispatch, state }, params) {
        let index = state.captionDataMap[params.sentId];
        if (index === 0 || index) {
            state.captionData[index].startTimeMs = params.startTimeMs;
            state.captionData[index].endTimeMs = params.endTimeMs;
            if (rootState.checkedDetail.sentId == params.sentId) {
                //更新
                rootState.checkedDetail.startTimeMs = params.startTimeMs;
                rootState.checkedDetail.endTimeMs = params.endTimeMs;
            }
        }

        console.log("触发更新值",rootState.excelProject.ArrMap.has(params.sentId),params.sentId)
        //同步更新句子信息；
        if (rootState.excelProject.ArrMap.has(params.sentId)) {
            let sIndex = rootState.excelProject.ArrMap.get(params.sentId);
            let item = rootState.excelProject.docData.sentenceDTOList[sIndex];
            item.startTimeMs = params.startTimeMs;
            item.endTimeMs = params.endTimeMs;
            console.log("触发赋值1")
            let nodeText = window.$getTargetDom(item.sentId).innerText;
            assignmentSentenceInformation(item,nodeText);
            console.log("触发更新值",item)
        }
    }
    //切换播放进度 (从实例控制);
}

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state,
}
