<template>
  <a-modal
      :visible="visible"
      :centered="true"
      :width="640"
      :maskClosable="false"
      @cancel="cancel"
  >
    <template #title>
      <div class="price-title">
        <div class="left">费用明细</div>
      </div>
    </template>

    <div class="price-content">
      <div class="price-box">
        <div class="price-box-left">
          <img src="./images/GPTPrice.png" />
          <div class="font-size-12">费用明细单</div>
        </div>
        <div class="price-box-right">
          <div class="price-box-item">
            <label>支付账户：</label>
            <div>{{ detail.accountName }}</div>
          </div>
          <div class="price-box-item">
            <label>智能体名称：</label>
            <div>{{detail.productName}}</div>
          </div>

          <div class="price-box-item">
            <label>单价：</label>
            <div>
              {{ detail.etokenPrice*1000 }}译力/千字
              <span class="font-size-12 color-999">
                （约合{{ (detail.etokenPrice * 10 ).toFixed(2) }}元/千字）
              </span>
            </div>
          </div>
          <div class="price-box-item">
            <label>处理总字数：</label>
            <div>
              {{ detail.wordCount }}
            </div>
          </div>
          <div class="price-box-item">
            <label>扣除译力：</label>
            <div>
              <span class="payPrice">{{ detail.payEtoken > 0 ? detail.payEtoken : '-' }}</span>
            </div>
          </div>
          <div v-if="!detail.enterpriseEnough" class="price-box-item font-size-13"  style="width: 160px;color:#F55C3F">
            译力余额不足，请充值
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="price-footer">
        <div class="left">
          <img src="./images/yili.png" />
          <span>当前译力余额：{{ detail.remainingEtoken }}</span>
        </div>

        <div class="right">
          <a-button @click="cancel">
            取消
          </a-button>
          <a-button
              v-if="detail.enterpriseEnough"
              type="primary"
              @click="confirm"
          >
            确定
          </a-button>
          <a-button v-else @click="cancel">我知道了</a-button>
        </div>
      </div>
    </template>
    <!--    </div>-->
  </a-modal>
</template>

<script>
import {QuestionCircleFilled, QuestionCircleOutlined,} from '@ant-design/icons-vue'
import {ref} from 'vue'
import {useStore} from "vuex";
// import { postTwinsCalculate } from '@/http/api'

export default {
  components: {
    QuestionCircleOutlined,
    QuestionCircleFilled,
  },
  props: [],
  setup(props, ctx) {
    const loading = ref(false)
    const visible = ref(false)
    const detail = ref({})
    const callback = ref(null)
    const store = useStore();


    const cancel = () => {
      visible.value = false
      callback.value = null
    }

    const callbackFunctionEmit = () => {
      if (callback.value && typeof callback.value === 'function') {
        callback.value()
      }
    }

    const confirm = (type) => {

      callbackFunctionEmit();
      // 支付方为企业
      cancel();
    }

    // 去往充值页面
    const toRecharge = () => {
      window.open(`/go/wealth_pc_login/recharge?tabs=2&openModal=1`, '_blank')
    }

    const init=(data,okCallback,cancelCallBack)=>{
      callback.value = null
      //自动支付开启
      console.log(data);

      detail.value = {...data};
      callback.value = okCallback;
      //data 智能体参数传过来了，计费接口
      //自动支付开启
      if (store.state.modal.autoPayChecked) {
        confirm();
      } else {
        visible.value = true;
      }
    }




    return {
      init,
      cancel,
      callback,
      detail,
      visible,
      loading,
      confirm,
      toRecharge,
    }
  },
}
</script>
<style lang="less" scoped>
.price-title {
  display: flex;
  justify-content: space-between;
  .left {
  }
  .right {
    height: 24px;
    padding: 0 12px;
    line-height: 24px;
    color: #00befa;
    font-size: 12px;
    background: #e0f7ff;
    cursor: pointer;
    margin-right: 26px;
    border-radius: 12px;
  }
}
.price-box {
  display: flex;
  .price-box-left {
    flex-shrink: 0;
    margin-right: 30px;
    text-align: center;
    color: #333333;
    img {
      margin-bottom: 8px;
      width: 48px;
    }
  }
  .price-box-right {
    flex-grow: 1;
    padding: 10px 20px;
    background: #f6f6f6;
    border-radius: 8px;
  }
  .price-box-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 0;
    label {
      color: #666666;
      flex-basis: 120px;
      //margin-right: 30px;
      flex-shrink: 0;
    }
    &:not(:last-child) {
      border-bottom: 1px dashed #dddddd;
    }
    & > div {
      color: #333333;
    }
    .payPrice {
      color: #00befa;
      margin-right: 20px;
    }
    .rate {
      font-size: 12px;
      color: #999999;
    }
    .black {
      color: #333;
    }
  }
}
.price-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
  span {
    color: #666;
    margin-right: 20px;
  }
}
</style>
