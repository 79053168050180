<template>
  <a-dropdown placement="bottomCenter">
    <a-button
      class="li"
      type="link"
      :class="{
        disabled: disabled || (checkedDetail.locked && checkedList.length == 0),
      }"
    >
      <icon-font class="font-size-16 icon-box" type="icon-runse1"></icon-font>
      <div class="li-txt">
        润色
      </div>
    </a-button>
    <template #overlay>
      <div style="width: 320px;">
        <div class="runse-scrollBar" id="runse-scrollBar">
          <div class="runse-options-title">
            润色要求:
          </div>
          <ul class="runse-options">
            <a-row :gutter="2">
              <a-col :span="12" v-for="(item, index) in levelObj">
                <li
                    @click="decorationClick(item)"
                    :key="index"
                >
                  {{ item.description }}
                </li>
              </a-col>
            </a-row>
          </ul>
          <div class="runse-options-title display-flex justify-between align-center margin-t-16">
            <span>自定义:</span>
            <span @click="openRunseModal" style="color:#00B4FA;font-weight: normal;cursor: pointer">+ 新增</span>
          </div>
          <ul class="runse-options">
            <a-row :gutter="2">
              <a-col :span="12" v-for="(item, index) in customLevelObj">
                <li class="runse-options-item" @click="decorationClick(item)" :key="index" :title="item.description">
                  {{item.description}}
                  <span class="options-icon" @click.stop>
                    <icon-font type="icon-bianji2" style="border-right: 1px solid #EEEEEE;border-radius: 4px 0px 0px 4px;" @click="editRunseModal(item)"></icon-font>
                    <icon-font type="icon-guanbi" style="border-radius: 0px 4px 4px 0px;" @click="delRunseModal(item)"></icon-font>
                  </span>
                </li>
              </a-col>
            </a-row>
          </ul>
        </div>
      </div>
    </template>
  </a-dropdown>

  <a-modal
    :visible="true"
    title="提示"
    okText="确认润色"
    cancelText="取消"
    centered
    @ok="confirmOk"
    @cancel="confirmCancel"
    v-if="confirmVisible"
  >
    <div class="modal">
      <div class="title">
        <img src="./images/runse.png" />
      </div>
      <div class="content">
        已勾选
        <span style="color:#00B4FA">{{ checkedList?.length }}</span>
        个句段，确认批量润色？
      </div>
      <div class="footer">
        按要求批量对译文进行智能润色，原有译文将被替换。
      </div>
      <saveSnapshotCheck
        v-model:saveSnapshotValue="saveSnapshotValue"
      ></saveSnapshotCheck>
    </div>
  </a-modal>

  <runseModal ref="runseModalRef">

  </runseModal>
</template>

<script>
import {CheckCircleFilled, CloseCircleOutlined, EditOutlined} from '@ant-design/icons-vue';
import {computed, getCurrentInstance, reactive, ref, toRefs} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {message} from 'ant-design-vue'
import {batchAiDecorate} from '@/http/api'
import mitt from '@/plugins/bus'
import saveSnapshotCheck from '@/components/saveSnapshotCheck/index.vue';
import runseModal from "@/components/dialog/runseModal/runseModal.vue"
import {ElScrollbar} from "element-plus";

export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      ElScrollbar,
      CloseCircleOutlined,
      EditOutlined,
      runseModal,
      saveSnapshotCheck,
      CheckCircleFilled,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail = computed(() => store.state.taskDetail)
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const levelObj = computed(() => {
        return store.state.decorationLevelObj
      })
      const customLevelObj = computed(()=>{
        return store.state.customDecorationObj
      })
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })

      const runseModalRef = ref(null);

      const state = reactive({
        type: '',
        confirmVisible: false,
        saveSnapshotValue: false,
      })


      //编辑润色弹窗
      const editRunseModal=(item)=>{
        runseModalRef.value.editInit(item,()=>{

        })
      }

      const startDecorate = (sentIdsArr, model = 'single') => {
        //获取存储快照的变量
        let saveSnapshot = false
        if (model == 'batch') {
          saveSnapshot = sessionStorage.getItem('saveSnapshotValue') || false
          if (saveSnapshot) {
            //更改快照信息
            store.commit('optionsBar/changeKey', {
              allowSaveSnapshot: true,
            })
          }
        }
        batchAiDecorate({
          docId: taskDetail.value.docId,
          sentIds: sentIdsArr,
          decorateId:state.type,
          saveSnapshot: saveSnapshot,
        })
      }

      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex]
      }

      const checkedList = computed(() => store.state.excelProject.checkedList)

      //Gpt批量润色 //默认为单句
      const GPTDecoration = async (model = 'single') => {
        // const { mtId, transMode } = store.state.taskDetail;
        const item = getTableActiveItem()
        let sentIds = item.sentId.toString()
        if (checkedList.value.length > 0) {
          sentIds = checkedList.value
            .map((citem) => {
              return citem.sentId
            })
            .toString()
        }
        if (!sentIds) {
          message.warning('请选中某一句或者勾选句子')
          return
        }

        let sentIdsArr = sentIds.split(',')
        //判断是否有译文 有的话 提示出来

        let sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        let index = sentIdsArr.findIndex((item) => {
          if (
            sentenceDTOList[store.state.excelProject.ArrMap.get(item)]
              .targetPlaceholder
          ) {
            return true
          }
        })
        if (index == -1) {
          message.warning('请选择有译文的句子')
          return
        }
        //
        mitt.emit('openCostModal', {
          costType: 'GptDecorate',
          sentIds: sentIdsArr,
          ok: (e) => {
            startDecorate(sentIdsArr, model)
          },
        })
      }

      const decorationClick = (item) => {
        state.type = item.id;
        if (checkedList.value.length > 0) {
          state.confirmVisible = true
        } else {
          GPTDecoration()
        }
      }

      const confirmOk = () => {
        state.confirmVisible = false
        // confirmMt();
        GPTDecoration('batch')
      }
      const confirmCancel = () => {
        state.confirmVisible = false
      }

      const delRunseModal=(item)=>{
        runseModalRef.value.delInit(item)
      }

      const openRunseModal=()=>{
        //数据传过来
        runseModalRef.value.editInit(
            {},
            ()=>{},
            ()=>{}
        )
      }

      return {
        delRunseModal,
        editRunseModal,
        customLevelObj,
        runseModalRef,
        openRunseModal,
        decorationClick,
        confirmOk,
        confirmCancel,
        checkedList,
        levelObj,
        GPTDecoration,
        ...toRefs(state),
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
</script>

<style scoped lang="less">
  .runse-scrollBar{
    width: 100%;
    background: white;
    box-shadow: 0px 4px 16px 0px rgba(16,102,158,0.08);
    max-height:330px;
    padding: 16px;
    overflow-x: auto;
    border-radius: 10px;
    //overflow-x: hidden;
  }
  .runse-options-title{
    font-weight: bold;
    color:#333;
    margin-bottom: 8px;
  }
  .runse-options {
    border-radius: 8px;
    font-size: 12px;
    li {
      cursor: pointer;
      background: #F5F6FA;
      border-radius: 6px;
      margin-bottom: 2px;
      padding: 0 10px;
      text-align: center;
      height: 24px;
      line-height: 24px;
      color:#666;
      white-space: nowrap; /* 防止文本换行 */
      overflow: hidden; /* 隐藏溢出的文本 */
      text-overflow: ellipsis;
      &:hover{
        background: #E7F2FD;
      }
    }
    .runse-options-item{
      position: relative;
      &:hover{
        .options-icon{
          //display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .options-icon{
        position: absolute;
        border-radius: 4px;
        display: none;
        height: 100%;
        top: 0;
        right: 4px;
        z-index: 3;
        ::v-deep(span){
          width:16px;
          height: 16px;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;

        }
      }
    }
  }

  .modal {
    text-align: center;
    .title {
      img {
        width: 56px;
      }
    }
    .content {
      color: #1c2129;
      line-height: 22px;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    .footer {
      color: #4e5969;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }
</style>
